<template>
  <div class="inputCart">
    <div class="minus" :class="{ disabled: isMinusDisabled }" @click="removeQuantity" />
    <input
      type="number"
      name="name"
      pattern="\d+"
      v-model="quantityValue"
      :disabled="disabled"
      :min="minimumQuantity"
      :max="maximumQuantity"
      @input="validateOnInput"
      @blur="validateOnBlur"
    />
    <div class="plus" :class="{ disabled: isPlusDisabled }" @click="addQuantity" />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    maximumQuantity: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    },
    minimumQuantity: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    addQuantity() {
      if (!this.disabled) {
        this.quantityValue++;
      }
    },
    getValidValue(value) {
      const quantity = parseInt(value);
      let validValue = 0;

      if (isNaN(quantity) || quantity < this.minimumQuantity) {
        validValue = this.minimumQuantity;
      } else if (quantity > this.maximumQuantity) {
        validValue = this.maximumQuantity;
      } else {
        validValue = quantity;
      }

      return validValue;
    },
    removeQuantity() {
      if (!this.disabled && this.quantityValue > this.minimumQuantity) {
        this.quantityValue--;
      }
    },
    validateOnBlur(input) {
      const target = input.target;

      if (target.value === '') {
        target.value = this.minimumQuantity;
      }
    },
    validateOnInput(input) {
      const target = input.target;
      if (!target.validity.valid) {
        target.value = this.getValidValue(target.value);
      }
    }
  },
  computed: {
    isMinusDisabled() {
      return this.disabled || this.quantityValue <= this.minimumQuantity;
    },
    isPlusDisabled() {
      return this.disabled || this.quantityValue >= this.maximumQuantity;
    },
    quantityValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', this.getValidValue(value));
      }
    }
  },
  mounted() {
    this.quantityValue = this.getValidValue(this.quantityValue);
  }
};
</script>