<template>
  <form class="accountForm validateAccountCreationForm" @submit.prevent="validateToken">
    <template v-if="!accountCreationHasSucceeded">
      <validation-provider class="formItem" rules="required" ref="tokenField" v-slot="{ errors }" tag="div">
        <label>{{ $t('accountValidationModal.text') }}</label>
        <input type="text" v-model="token" :class="{ invalid: errors.length }" />
        <div v-if="errors.length" class="error">{{ errors[0] }}</div>
        <div v-if="validationErrorMessage" class="error">
          {{ validationErrorMessage }}
        </div>
        <div v-if="errorType === 'ExpiredTokenException'" class="formItem">
          <working-button class="btnText" :isWorking="isResendingCode" type="button" @click="resendValidationCode" :label="$t('accountValidationModal.resendValidationCode')"></working-button>
          <div v-if="resendActivationTokenError" class="error">{{ resendActivationTokenError }}</div>
          <div class="actionResult" v-if="resendActivationTokenMessage">{{ resendActivationTokenMessage }}</div>
        </div>
      </validation-provider>
      
      <working-button class="btn" :isWorking="isValidatingAccount" :label="submitLabel" type="submit"></working-button>
    </template>

    <template v-else>
      <div class="resetPasswordForm-successPanel">
        <circle-check class="resetPasswordForm-successIcon"></circle-check>
        <div class="resetPasswordForm-successMessage">{{ $t('users.messages.success.accountCreation') }}</div>
        <a class="btn resetPasswordForm-successConnect" href="/connexion">{{ $t('users.actions.connectMe') }}</a>
      </div>
    </template>
  </form>
</template>

<script>
import WorkingButton from './WorkingButton.vue'
import { setInteractionMode, ValidationProvider } from 'vee-validate'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'
import CircleCheck from '../Svg/CircleCheck.vue'

setInteractionMode('eager')
export default {
  components: {
    CircleCheck,
    WorkingButton,
    ValidationProvider
   },
  props: {
    email: {
      type: String,
      required: true
    },
    firstName: {
      type: String,
      required: true
    },
    identifier: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      accountCreationHasSucceeded: false,
      errorType: undefined,
      token: undefined,
      isResendingCode: false,
      isValidatingAccount: false,
      resendActivationTokenError: undefined,
      resendActivationTokenMessage: undefined,
      validationErrorMessage: undefined
    }
  },
  methods: {
    handleResendActivationTokenSuccess() {
      this.resendActivationTokenMessage = this.$t('users.messages.activationCodeResent')
    },
    async resendValidationCode() {
      this.resendActivationTokenError = undefined
      this.resendActivationTokenMessage = undefined

      try{
        this.isResendingCode = true
        const request = {
          email: this.email
        }
        const response = await this.$axios.post('/Umbraco/Api/Authentication/ResendActivationToken', request)
        this.handleResendActivationTokenSuccess()
      }
      catch(error) {
        this.resendActivationTokenError = this.$t('users.messages.sendActivationCodeError')
      }
      finally {
        this.isResendingCode = false
      }
    },
    async validateToken(event) {
      event.preventDefault()

      await this.$refs.tokenField.validate()
      if(this.$refs.tokenField.errors.length) {
        setFocusOnFirstErrorField(this.$refs.tokenField.$el, 'input')
        return
      }
      
      try {
        this.errorType = undefined
        this.validationErrorMessage = undefined
        this.isValidatingAccount = true
        
        const model = {
          email: this.email,
          tokenNumber: this.token,
          tokenIdentifier: this.identifier,
          username: this.firstName
        }
        const response = await this.$axios.post('/Umbraco/Api/Authentication/ConfirmAccount', model)
        if (dataLayer) {
          dataLayer = dataLayer || []
          dataLayer.push({
            event: 'sign_in',
            method: 'email',
            user_id: response.data
          })
        }
        this.accountCreationHasSucceeded = true
      }
      catch (error) {
        this.errorType =
          error && error.response && error.response.data
            ? error.response.data
            : 'ConfirmAccountException'
        
        this.validationErrorMessage = this.$t(`accountValidationModal.${this.errorType}`)
      }
      finally {
        this.isValidatingAccount = false
      }
    }
  },
  computed: {
    submitLabel() {
      if(this.isValidatingAccount) {
        return this.$t('accountValidationModal.confirmingAccountCreation')
      }

      return this.$t('accountValidationModal.confirmAccountCreation')
    }
  }
}
</script>