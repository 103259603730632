<template>
  <div class="favorite" >
    <a @click.cancel="toggle" :class="['addToCart', { like: isInCart }]" v-if="!isUnavailable(product)">
      <slot />
    </a>
    <add-to-favorites v-if="member" :class="isFavorite === true ? 'hidden' : ''" :product="product" :member-id="member"  class="addFavorite" />
    <delete-from-favorites v-if="member" :class="isFavorite === false ? 'hidden' : ''" :product="product" :member-id="member"/>
  </div>
</template>

<script>
import productMixins from '@/productMixins';
import { mapGetters } from 'vuex';
import AddToFavorites from '@/components/Product/AddToFavorites.vue';
import DeleteFromFavorites from '@/components/Product/DeleteFromFavorites.vue';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    isFavorite: {
      type: Boolean,
      default: null
    },
    member: {
      type: Number,
      default: null
    }
  },
  components: {
    AddToFavorites,
    DeleteFromFavorites
  },
  methods: {
    toggle() {
      if (!this.isInCart) {
        const selectedStore = this.$root.getStore();
        if (selectedStore === null) {
          this.$tostini({ message: this.$t('selectStoreToast'), duration: 3000 });
          return;
        }
        this.$store.dispatch('cartModule/addQuantity', {
          productId: this.product.identifier,
          quantity: 1
        });
        if (this.$root.getFeatureFlag('ecommerce')) {
          this.$tostini({ message: this.$t('productAddedToast'), duration: 3000, html: true });
        } else {
          this.$tostini({ message: this.$t('productAddedListToast'), duration: 3000, html: true });
        }
        this.sendAddToCartGaEvent();
      } else {
        this.$store.dispatch('cartModule/removeProduct', this.product.identifier);
        this.sendRemoveFromCartGaEvent();
      }
    },
    sendAddToCartGaEvent() {
      try {
        if (dataLayer && this.product) {
          dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              items: [{
                item_id: this.product.sku,
                item_name: this.product.name,
                price: this.product.price,
                quantity: this.product.quantity
              }]
            }
          });
        }
      } catch { /*Fait rien*/ }
    },
    sendRemoveFromCartGaEvent() {
      try {
        if (dataLayer) {
          dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
            items: [
                {
                  item_id: this.product.sku,
                  item_name: this.product.name,
                  price: this.product.price
                }
              ]
            }
          });
        }
      } catch { /*Fait rien*/ }
    }
  },
  computed: {
    ...mapGetters('cartModule', ['cart']),
    isInCart() {
      return this.cart[this.product.identifier] && this.cart[this.product.identifier] > 0;
    }
  },
  mixins: [productMixins]
};
</script>
