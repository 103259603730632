<template>
  <div id="orderPayment" class="orderPayment box" v-if="orderStep >= OrderStep.Payment">
    <div class="orderTitle">
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
      </svg>
      {{ $t('order.payment.title') }}
    </div>
    <form @submit.prevent="submit">
      <order-credit-card
        v-if="!isAccountPaymentAllowed || user == null || uniquePayment === true"
        :addressCompleteSettings="addressCompleteSettings"
        :recaptchaEnabled="false"
        :recaptchaSiteKey="recaptchaSiteKey" @submit="bamboraSubmit"
      />
      <order-credit-card-selection
        v-else
        :bambora-configuration="bamboraConfiguration"
        :is-processing-order="isProcessingOrder"
        :payment="selectedUserPayment"
        :user="user"
        @process="bamboraSubmit"
        @success:updatePaymentInfo="handleUpdatePaymentMethodsSuccess"
        @success:deletePayment="handleDeletePayment"
        @uniquePayment="uniquePayment = true"
      />
      <button type="submit" class="btn" v-show="paymentFormValid" :disabled="!valid || isProcessingOrder">{{ $t('order.payment.submit') }}<img src="/images/loading.gif" v-if="isProcessingOrder" class="loading" /></button>
    </form>
  </div>
</template>

<script>
  import errorMixins from '@/errorMixins';
  import orderMixins from '@/orderMixins';
  import OrderAccount from './OrderAccount.vue';
  import OrderCreditCard from './OrderCreditCard.vue';
  import OrderCreditCardSelection from './OrderCreditCardSelection.vue';
  import OrderStep from '@/models/cart/OrderStep';
  import PaymentMode from '@/models/cart/PaymentMode';
  import shippingMethods from '@/models/store/ShippingMethod';
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    components: {
      OrderAccount,
      OrderCreditCard,
      OrderCreditCardSelection
    },
    props: {
      addressCompleteSettings: {
        type: Object,
        required: true
      },
      bamboraConfiguration: {
        type: Object,
        required: true
      },
      confirmationUrl: {
        type: String,
        required: true
      },
      recaptchaSiteKey: {
        type: String,
        required: true
      },
      validate: {
        type: Function,
        required: true
      },
      user: {
        type: Object,
        default: null
      },
      userPaymentIsEnabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        OrderStep,
        PaymentMode,
        selectedMode: PaymentMode.CreditCard,
        selectedUserPayment: null,
        shippingMethods,
        uniquePayment: false
      };
    },
    computed: {
      ...mapGetters('cartModule', ['isPickup']),
      ...mapState('cartModule',
        [
          'acceptedTermsConditions',
          'addShippingExtra',
          'account',
          'billingAddress',
          'callCustomer',
          'cart',
          'captchaToken',
          'customer',
          'deliveryDate',
          'isProcessingOrder',
          'globalPaymentInfo',
          'orderStep',
          'paymentMode',
          'pickupContact',
          'pickupOptions',
          'shippingAddress',
          'shippingError', 
          'shippingExtraPrice', 
          'shippingMethod',
          'shippingPrice'
        ]
      ),
      isAccountPaymentAllowed() {
        return this.$root.getFeatureFlag('bambora') && this.userPaymentIsEnabled;
      },
      isShippingDisabled() {
        return this.shippingMethod === shippingMethods.Delivery && (this.shippingError || this.shippingPrice == null);
      },
      paymentFormValid() {
        var isValid = !!(this.globalPaymentInfo.orderID
          && this.globalPaymentInfo.payerReference
          && this.globalPaymentInfo.paymentReference);

        if (isValid) {
          this.sendAddPaymentInfoGaEvent();
        }

        return isValid;
      },
      valid() {
        if (!this.billingAddress
          || this.billingAddress.address && this.billingAddress.city && this.billingAddress.postalCode) {
          let isValid = false;
          switch (this.selectedMode) {
            case PaymentMode.Account:
              isValid = this.account.number && this.account.number.length === 8 && this.account.type;
              break;
            case PaymentMode.CreditCard:
              isValid = this.paymentFormValid;
              break;
          }

          return isValid && !this.isShippingDisabled;
        }
        return false;
      }
    },
    methods: {
      ...mapActions('cartModule', ['clear', 'setIsProcessingOrder', 'setPaymentMode']),
      ...mapActions('globalModule', ['setErrorModal']),
      handleUpdatePaymentMethodsSuccess(payment) {
        this.$emit('updateUserPayment', payment);
        this.selectedUserPayment = payment;
      },
      handleDeletePayment() {
        this.$emit('deletePayment');
        this.selectedUserPayment = null;
      },
      isKnownException(error) {
        return [
          'CalculateShippingCostException',
          'IncompleteOrderException',
          'InvalidDeliveryDateException',
          'OrderDeliveryDateDetailsException',
          'OrderPaymentException',
          'ProductQuantityException',
          'ProductStatusException',
          'InvalidProductException'
        ].includes(error);
      },
      async submit() {
        const isFormValid = await this.validate();

        if (!isFormValid) {
          this.scrollToFirstError('invalid');
          return;
        }

        if (!this.valid) {
          return;
        }

        this.setIsProcessingOrder(true);

        const products = Object.keys(this.cart).map(id => ({
          productID: id,
          quantity: this.cart[id]
        }));
        const payment = {
          ...this.billingAddress,
          account: this.selectedMode === PaymentMode.Account ? this.account : null,
          creditCard: this.selectedMode === PaymentMode.CreditCard ? this.globalPaymentInfo : null
        };
        const shipping = {
          ...this.shippingAddress,
          callCustomer: this.callCustomer,
          deliveryDate: this.callCustomer ? null : this.deliveryDate,
          extraFee: this.addShippingExtra === true ? this.shippingExtraPrice : 0,
          pickupContactEmail: this.pickupContact.email,
          pickupContactFirstName: this.pickupContact.firstName,
          pickupContactLastName: this.pickupContact.lastName,
          shippingMethod: this.shippingMethod
        };
        const order = {
          agreeConditions: this.acceptedTermsConditions,
          branch: this.$root.getStore(),
          contact: this.customer,
          culture: this.$i18n.locale,
          payment,
          paymentMethod: this.paymentMode,
          products,
          shipping,
          userID: this.user && this.user.contact ? this.user.contact.userID : null
        };

        try {
          const response = await this.$axios.post('/Umbraco/Api/Order/Process', order);
          await this.setIsProcessingOrder(false);

          if (response && response.data) {
            this.clear();
            window.location.replace(`${this.confirmationUrl}?orderid=${response.data}`);
          } else {
            throw new Error('Unknown error occurred');
          }
        } catch (error) {
          await this.setIsProcessingOrder(false);
          const errorType = error && error.response && error.response.data ? error.response.data : null;
          this.setErrorModal(this.isKnownException(errorType) ? `order.errors.${errorType}` : true);
        }
      },
      async bamboraSubmit(data) {
        const isFormValid = await this.validate();
        if (!isFormValid) {
          this.scrollToFirstError('invalid');
          return;
        }
        this.setIsProcessingOrder(true);
        const products = Object.keys(this.cart).map(id => ({
          productID: id,
          quantity: this.cart[id]
        }));
        const bamboraPayment = {};

        if (data != null && data.result.token) {
          bamboraPayment.bamboraToken = data.result.token;
          bamboraPayment.bamboraCardholderName = data.cardholderName;
          bamboraPayment.bamboraCardExpiration = `${data.result.expiryMonth}/${data.result.expiryYear}`;
        } else if (this.isAccountPaymentAllowed && this.user != null) {
          bamboraPayment.bamboraCardID = this.selectedUserPayment.bamboraCardID;
          bamboraPayment.bamboraCardExpiration = this.selectedUserPayment.creditCardExpiration;
          bamboraPayment.bamboraCardholderName = this.selectedUserPayment.cardholderName;
          bamboraPayment.bamboraCustomerCode = this.user.bamboraCustomerCode;
        }

        const payment = {
          ...this.billingAddress,
          account: this.selectedMode === PaymentMode.Account ? this.account : null,
          creditCard: this.selectedMode === PaymentMode.CreditCard ? this.globalPaymentInfo : null
        };
        const shipping = {
          ...this.shippingAddress,
          callCustomer: this.callCustomer,
          deliveryDate: this.callCustomer ? null : this.deliveryDate,
          extraFee: this.addShippingExtra === true ? this.shippingExtraPrice : 0,
          pickupContactEmail: this.pickupContact.email,
          pickupContactFirstName: this.pickupContact.firstName,
          pickupContactLastName: this.pickupContact.lastName,
          shippingMethod: this.shippingMethod
        };
        const order = {
          agreeConditions: this.acceptedTermsConditions,
          bamboraPayment,
          branch: this.$root.getStore(),
          contact: this.customer,
          culture: this.$i18n.locale,
          payment,
          paymentMethod: this.paymentMode,
          products,
          shipping,
          userID: this.user && this.user.contact ? this.user.contact.userID : null
        };
        try {
          const response = await this.$axios.post('/Umbraco/Api/Order/ProcessBambora', order);
          await this.setIsProcessingOrder(false);
          
          if (response && response.data) {
            this.clear();
            window.location.replace(`${this.confirmationUrl}?orderid=${response.data}`);
          } else {
            throw new Error('Unknown error occurred');
          }
        } catch (error) {
          await this.setIsProcessingOrder(false);
          const errorType = error && error.response && error.response.data ? error.response.data : null;
          this.setErrorModal(this.isKnownException(errorType) ? `order.errors.${errorType}` : true);
        }
      },
      sendAddPaymentInfoGaEvent() {
        try {
          if (dataLayer) {
            dataLayer = dataLayer || [];
            dataLayer.push({
              event: 'add_payment_info', 
              ecommerce: {
                payment_type: 'Carte de crédit', 
                items: this.products.map(item => ({
                  item_name: item.name,
                  item_id: item.sku,
                  item_category: item.majorCategory,
                  item_category2: item.intermediateCategory,
                  item_category3: item.minorCategory
                }))
              }
            });
          }
        } catch { /*Fait rien*/ }
      }
    },
    created() {
      this.selectedMode = this.paymentMode;
    },
    mounted() {
      if (this.user != null && this.isAccountPaymentAllowed) {
        this.selectedUserPayment = this.user.payment;
      }
    },
    watch: {
      selectedMode(value) {
        this.setPaymentMode(value);
      }
    },
    mixins: [errorMixins, orderMixins]
  };
</script>
