'use strict'

import axios from 'axios'
import Vue from 'vue'

let config = {
  baseURL: ''
}

const currentCulture = document.documentElement.lang.toLocaleLowerCase()
const loginUrlMapping = new Map()
loginUrlMapping.set('fr-ca', '/connexion')
loginUrlMapping.set('en-ca', '/login')
const _axios = axios.create(config)

_axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    return response
  },
  error => {
    const loginPath = loginUrlMapping.has(currentCulture) ? loginUrlMapping.get(currentCulture) : '/connexion'

    if(error.response && error.response.status === 401) {
      window.location.assign(loginPath)
      return
    }

    return Promise.reject(error)
  }
)

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
