var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "8",
        height: "15",
        viewBox: "0 0 8 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.4375 14.0225L0.71875 8.05371C0.5625 7.86621 0.5 7.67871 0.5 7.49121C0.5 7.33496 0.5625 7.14746 0.6875 6.99121L6.40625 1.02246C6.6875 0.709961 7.1875 0.709961 7.46875 0.991211C7.78125 1.27246 7.78125 1.74121 7.5 2.05371L2.28125 7.49121L7.53125 12.9912C7.8125 13.2725 7.8125 13.7725 7.5 14.0537C7.21875 14.335 6.71875 14.335 6.4375 14.0225Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }