var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderProducts box" }, [
    _c("div", { staticClass: "toggle" }, [
      _c("div", { staticClass: "title flex center" }, [
        _c("div", { ref: "toggleTitle", on: { click: _vm.toggle } }, [
          _c("div", { staticClass: "orderTitle" }, [_vm._v(_vm._s(_vm.title))]),
        ]),
        _c("button", { on: { click: _vm.toggle } }, [
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hidden,
                expression: "hidden",
              },
            ],
            staticClass: "down",
          }),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hidden,
                expression: "!hidden",
              },
            ],
            staticClass: "up",
          }),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hidden,
              expression: "!hidden",
            },
          ],
          staticClass: "orderList toggleContent text",
        },
        _vm._l(_vm.products, function (product) {
          return _c(
            "div",
            { key: product.identifier, staticClass: "wrapProduct" },
            [
              _c("product-info", {
                attrs: {
                  product: product,
                  isAvailable: !_vm.isUnavailable(product),
                  isInactive: _vm.isInactive(product),
                  isInStock: _vm.isInStock(product),
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }