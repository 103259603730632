<!-- eslint-disable -->
<template>
  <div class="dropdownSort">
    <label class="facetTitle">{{ header }}</label>
    <div class="divSelect dropdownStyle">
      <select v-model="selectedFacet">
        <option v-for="(value, index) in joinedValues"
                :key="index"
                :value="`${value.value}:${value.isDesc ? 'true' : 'false'}`">
          {{ value.name }}
        </option>
      </select>
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>
import hashMixins from '@/hashMixins';
import { Sort } from '@/sortingEnum';

  export default {
    props: {
      itemsContainer: {
        type: Object,
        required: true
      },
      values: {
        type: Object,
        required: true
      },
      header: {
        type: String,
        required: true
      },
      ascendingTextName: {
        type: String,
        default: 'asc'
      },
      ascendingTextPrice: {
        type: String,
        default: 'asc'
      },
      ascendingTextDate: {
        type: String,
        default: 'asc'
      },
      descendingTextName: {
        type: String,
        default: 'desc'
      },
      descendingTextPrice: {
        type: String,
        default: 'desc'
      },
      descendingTextDate: {
        type: String,
        default: 'desc'
      },
      textBefore: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selectedFacet: null,
        isDesc: true
      };
    },
    created() {
      const hashParams = this.getHashParams();
      if (hashParams.isDesc) {
        this.isDesc = hashParams.isDesc;
      } else {
        this.isDesc = 'true';
      }

      if (Object.values(Sort).includes(parseInt(hashParams.sort))) {
        this.selectedFacet = `${hashParams.sort}:${this.isDesc}`; 
      } else {
        this.selectedFacet = `${Sort.Score}:${this.isDesc}`;
      }

      
    },
    computed: {
      joinedValues() {
        return Object.entries(this.values).sort(function(a, b) { return a[1].order < b[1].order ? -1 : 1; }).reduce((acc, [ value, entity ]) => {
          if (entity.requireSelectedStore && !this.$root.hasStoreSelected) {
            return [...acc];
          }

          if (!entity.exclusive) {
            if (entity.name === 'Pertinence') {
              return [
              ...acc,
              { name: this.joinValues(entity.name, ''), value, isDesc: true }
            ];
            } else if (entity.name === 'Nom') {
              return [
                ...acc,
                { name: this.joinValues(entity.name, this.ascendingTextName, this.textBefore), value, isDesc: false },
                { name: this.joinValues(entity.name, this.descendingTextName, this.textBefore), value, isDesc: true }
              ];
            } else if (entity.name === 'Prix') {
              return [
                ...acc,
                { name: this.joinValues(entity.name, this.ascendingTextPrice, this.textBefore), value, isDesc: false },
                { name: this.joinValues(entity.name, this.descendingTextPrice, this.textBefore), value, isDesc: true }
              ];
            } else if (entity.name === 'Date') {
              return [
                ...acc,
                { name: this.joinValues(entity.name, this.ascendingTextDate, this.textBefore), value, isDesc: false },
                { name: this.joinValues(entity.name, this.descendingTextDate, this.textBefore), value, isDesc: true }
              ];
            }
          } else {
            return [
              ...acc, 
              { name: entity.name, value, isDesc: entity.isDesc || false }
            ];
          }
        }, []);
      }
    },
    methods: {
      joinValues(value, orderText, textBefore) {
        return textBefore
          ? `${orderText} ${value}`
          : `${value} ${orderText}`;
      },
      selectFacet(facet) {
        this.selectedFacet = facet;
      },
      refreshSelectedFacets() {
        if (this.joinedValues != null) {
          var splittedSelectedFacets = this.selectedFacet.split(':');        
          const joined = this.joinedValues.find(item => item.value === splittedSelectedFacets[0] && item.isDesc === (splittedSelectedFacets[1] === 'true'));
          this.itemsContainer.selectedFacets = {
            ...this.itemsContainer.selectedFacets,
            sort: joined ? joined.value : null,
            isDesc: joined ? joined.isDesc : null
          };
        }
      }
    },
    watch: {
      selectedFacet() {
        this.refreshSelectedFacets();
        this.$root.$emit('scroll');
      }
    },
    mixins: [hashMixins]
  };
</script>
