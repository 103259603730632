export default Object.freeze({
  None: 0,
  Regular: 1,
  ExclusiveItemToBranch: 2,
  NewItemFirstYear: 3,
  Discontinued: 4,
  DiscontinuedNotInInventory: 5,
  Promotional: 6,
  OneShotDeal: 7,
  OffSeasonItem: 8,
  StoppedForBilling: 9
});
