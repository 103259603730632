<!-- eslint-disable -->
<template>
  <div class="dropdownFacet" v-if="!isHidden">
    <label v-if="header" class="facetTitle">{{ header }}</label>
    <div class="divSelect dropdownStyle">
      <select v-model="selectedFacet">
        <option :value="null">
          {{ allText }}
        </option>
        <option v-for="(facet, index) in facets"
                :key="index"
                :value="facet.value">
          {{ facet.value }}
        </option>
      </select>
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>
import hashMixins from '@/hashMixins';

  export default {
    props: {
      hideIfFilterCountLessThan: {
        type: [Boolean,Number],
        default: false
      },
      itemsContainer: {
        type: Object,
        required: true
      },
      facetProperty: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: false
      },
      allText: {
        type: String,
        default: 'All'
      }
    },
    data() {
      return {
        facets: [],
        selectedFacet: null
      };
    },
    computed: {
      isHidden() {
        return Number.isInteger(this.hideIfFilterCountLessThan)
          ? this.facets.length < this.hideIfFilterCountLessThan && this.selectedFacet === null
          : !!this.hideIfFilterCountLessThan;
      }
    },
    created() {
      this.refreshFacets();
    },
    methods: {
      refreshFacets() {
      const hashParams = this.getHashParams();
        if (hashParams[this.facetProperty]) {
          this.selectedFacet = hashParams[this.facetProperty];
          this.refreshSelectedFacets();
        }
        if (this.selectedFacet) {
          this.facets = [
            {
              name: this.facetProperty.charAt(0).toUpperCase() + this.facetProperty.slice(1),
              value: this.selectedFacet,
              count: this.itemsContainer.total
            }
          ];
        } else {
          this.facets = this.itemsContainer.facets
            .filter(x => x.name === this.facetProperty.charAt(0).toUpperCase() + this.facetProperty.slice(1))
            .map(x => {
              const { value, count } = x;
              return { value, count };
            });
        }
      },
      refreshSelectedFacets() {
        if (this.selectedFacet) {
          this.itemsContainer.selectedFacets = {
            ...this.itemsContainer.selectedFacets,
            [this.facetProperty]: this.selectedFacet
          };
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { [this.facetProperty]: facetProperty, ...cleanedFacets } = this.itemsContainer.selectedFacets;
          this.itemsContainer.selectedFacets = cleanedFacets;
        }
      }
    },
    watch: {
      'itemsContainer.items'() {
        this.refreshFacets();
        this.$root.$emit('scroll');
      },
      'itemsContainer.selectedFacets'(facets) {
        this.selectedFacet = facets[this.facetProperty] || null;
      },
      selectedFacet() {
        this.refreshSelectedFacets();
      }
    },
    mixins: [hashMixins]
  };
</script>
