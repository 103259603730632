var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c("modal", {
        attrs: { isPopin: true },
        on: {
          onclose: function ($event) {
            return _vm.close()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [_c("h3", [_vm._v(_vm._s(_vm.$t("errorModal.title")))])]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [_c("p", { domProps: { innerHTML: _vm._s(_vm.text) } })]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn big",
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("errorModal.close")))]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1258343758
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }