<template>
  <div class="passwordContainer">
    <input :type="passwordIsVisible ? 'text' : 'password'" v-model="password" :class="{ invalid: errors.length }" :required="required" @blur="$emit('blur')"/>
    <button type="button" class="showPassword">
      <img class="password-eye" :src="require(`@/assets/svg/${passwordIsVisible ? 'eye-slash' : 'eye'}.svg`)" @click="toggleShowPassword()">
    </button>
  </div>
</template>

<script>
export default {
  props: {
     value: {
      type: String
    },
    errors: {
      type: Array,
      default: []
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      passwordIsVisible: false
    }    
  },
  methods: {
    toggleShowPassword() {
      this.passwordIsVisible = !this.passwordIsVisible
    }
  },
  computed: {
    password: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>