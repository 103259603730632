<template>

  <div class="dropdownStyle">
    <select v-model="selectedOption" v-bind="$attrs">
      <option v-for="option in options" :key="option.label" :value="option.value">{{ option.label }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      required: true
    } 
  },
  computed: {
    selectedOption: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>