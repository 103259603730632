<template>
  <div class="selectedStoreContent">
    <slot :name="selectedStore" />
  </div>
</template>

<script>
import stores from '@/models/store/Store';

export default {
  data() {
    return {
      stores: stores
    };
  },
  computed: {
    selectedStore () {
      const storeCodes = Object.keys(this.stores);
      const selectedStore = storeCodes.find(key => this.stores[key] === this.$root.getStore());
      return selectedStore ? selectedStore.toLowerCase() : null;
    }
  }
};
</script>

