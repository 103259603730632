var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "favoritesSection wrapper wrapper_small" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loadingProduct" }, [
            _c("img", { attrs: { src: "/images/triangle-loading.gif" } }),
          ])
        : !_vm.user
        ? [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("users.messages.getUserError")),
              },
            }),
          ]
        : _c("div", [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("myFavorites.promotionItemsLabel"))),
            ]),
            _vm.promotionProductsFromFavorites.length === 0
              ? _c("span", [_vm._v(_vm._s(_vm.$t("myFavorites.noResults")))])
              : _vm._e(),
            _c(
              "ul",
              { staticClass: "deals" },
              _vm._l(
                _vm.promotionProductsFromFavorites,
                function (product, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      _c("FavoriteProduct", {
                        attrs: { memberId: _vm.memberId, product: product },
                        on: { "success:removeFavorite": _vm.removeFavorite },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            ),
            _c("h2", [_vm._v(_vm._s(_vm.$t("myFavorites.allItemsLabel")))]),
            _vm.regularProductsFromFavorites.length === 0
              ? _c("span", [_vm._v(_vm._s(_vm.$t("myFavorites.noResults")))])
              : _vm._e(),
            _c(
              "ul",
              _vm._l(
                _vm.regularProductsFromFavorites,
                function (product, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      _c("FavoriteProduct", {
                        attrs: { memberId: _vm.memberId, product: product },
                        on: { "success:removeFavorite": _vm.removeFavorite },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }