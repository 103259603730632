export const setErrorModal = (state, value) => {
  state.error = value;
};

export const setStoreChangeWarningModal = (state, data) => {
  state.storeChangeWarningModal = data;
};

export const setSelectedStoreInformation = (state, data) => {
  state.selectedStoreInformation = data;
};
