<!-- eslint-disable -->
<template>
  <div class="radioButtonFacet" v-if="!isHidden">
    <label class="facetTitle">{{ header }}</label>
    <div>
      <div :class="['facetItem', 'all', { selected: selectedFacet === null }]">
        <radio-button v-model="selectedFacet" :checkedValue="null" />
        <span @click="selectFacet(null)">{{ allText }}</span>
      </div>
      <div :class="['facetItem', { selected: selectedFacet === facet.value }]"
           v-for="(facet, index) in facets"
           :key="index"
           v-show="autoHide === false || selectedFacet === facet.value || facet.count">
        <radio-button v-model="selectedFacet" :checkedValue="facet.value" />
        <div class="choiceFacet">
          <span @click="selectFacet(facet.value)">{{ getFacetText(facet.value) }}</span>
          <span v-if="showCount">{{ facet.count }}</span>
        </div>

      </div>
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>
  import RadioButton from '@/components/Facets/RadioButton';

  export default {
    components: {
      RadioButton
    },
    props: {
      hideIfFilterCountLessThan: {
        type: [Boolean,Number],
        default: false
      },
      itemsContainer: {
        type: Object,
        required: true
      },
      facetProperty: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: true
      },
      showCount: {
        type: Boolean,
        default: false
      },
      autoHide: {
        type: Boolean,
        default: false
      },
      allText: {
        type: String,
        default: 'All'
      }
    },
    data() {
      return {
        facets: [],
        selectedFacet: null
      };
    },
    computed: {
      isHidden() {
        return Number.isInteger(this.hideIfFilterCountLessThan)
          ? this.facets.length < this.hideIfFilterCountLessThan && this.selectedFacet === null
          : !!this.hideIfFilterCountLessThan;
      }
    },
    created() {
      this.refreshFacets();
    },
    methods: {
      getFacetText(facetText) {
        if (!facetText) {
          return this.$i18n.t('facets.others');
        }

        return facetText;
      },
      selectFacet(facet) {
        this.selectedFacet = facet;
      },
      refreshFacets() {
        if (this.selectedFacet) {
          this.facets = [
            {
              name: this.facetProperty,
              value: this.selectedFacet,
              count: this.itemsContainer.total
            }
          ];
        } else {
          this.facets = this.itemsContainer.facets
            .filter(x => x.name === this.facetProperty)
            .map(x => {
              const { value, count } = x;
              return { value, count };
            });
        }
      },
      refreshSelectedFacets() {
        if (this.selectedFacet) {
          this.itemsContainer.selectedFacets = {
            ...this.itemsContainer.selectedFacets,
            [this.facetProperty]: this.selectedFacet
          };
        } else {
          /* eslint-disable-next-line no-unused-vars */
          const { [this.facetProperty]: facetProperty, ...cleanedFacets } = this.itemsContainer.selectedFacets;
          this.itemsContainer.selectedFacets = cleanedFacets;
        }
      }
    },
    watch: {
      'itemsContainer.items'() {
        this.refreshFacets();
        this.$root.$emit('scroll');
      },
      'itemsContainer.selectedFacets'(facets) {
        this.selectedFacet = facets[this.facetProperty] || null;
      },
      selectedFacet() {
        this.refreshSelectedFacets();
      }
    }
  };
</script>
