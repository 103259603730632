<template>
  <article class="productItem">
    <div class="productItem-image">
      <img :src="imageUrl"
            @error="setFallbackProductImage">
    </div>
    <div class="productItem-data">
      <header class="productItem-name">
        <span v-if="!product.productUrl" class="productItem-productLink">{{ name }}</span>
        <a v-else :href="product.productUrl" class="productItem-productLink cardLink">{{ name }}</a>
        <div v-if="specialStatus" class="productItem-status">{{ specialStatus }}</div>
      </header>
      <div class="productItem-numbers">
        <div class="productItem-quantity">{{ $t('bill.quantity') }} {{ product.quantity }}</div>
        <div class="productItem-unitPrice">{{ unitPrice }}</div>
      </div>
    </div>
    <angle-right v-if="product.productUrl" class="chevron" />
  </article>
</template>

<script>
import { getFormattedCanadianDollars } from '../../helpers/stringHelper'
import AngleRight from '../Svg/AngleRight.vue'

export default {
  components: {
    AngleRight
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  inject: ['fallbackProductImageUrl'],
  computed: {
    imageUrl() {
      if(!this.product.imageUrl) {
        return this.fallbackProductImageUrl
      }

      return this.product.imageUrl
    },
    name() {
      return this.$i18n.locale === 'fr' ? this.product.descriptionFr : this.product.descriptionEn
    },
    specialStatus() {
      if(this.product.deleted || this.product.isDiscontinuedOrOffSeason || !this.product.productUrl) {
        return this.$t('product.availability.unavailable')
      }
    },
    unitPrice() {
      return getFormattedCanadianDollars(this.product.unitPrice, this.$i18n.locale)
    }
  },
  methods: {
    setFallbackProductImage(event) {
      event.target.src = this.fallbackProductImageUrl
    }
  }
}
</script>