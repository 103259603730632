var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderSummariesSection-content" }, [
    _c("div", { staticClass: "filteringSection" }, [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t("account.searchPlaceholder"),
            },
            domProps: { value: _vm.searchTerm },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
            },
          }),
          _c("icon-search"),
        ],
        1
      ),
      _c("div", { staticClass: "dropDowns" }, [
        _c("div", { staticClass: "statusSelector" }, [
          _c("label", { attrs: { for: "status" } }, [
            _vm._v(_vm._s(_vm.$t("account.dropdowns.statusDropdownLable"))),
          ]),
          _c(
            "div",
            { staticClass: "selectField" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.status,
                      expression: "status",
                    },
                  ],
                  staticClass: "statusFilter",
                  attrs: { id: "status" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.status = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("account.dropdowns.statusValueAll"))),
                  ]),
                  _c("option", { domProps: { value: 1 } }, [
                    _vm._v(
                      _vm._s(_vm.$t("account.dropdowns.statusValueDelivered"))
                    ),
                  ]),
                  _c("option", { domProps: { value: 2 } }, [
                    _vm._v(
                      _vm._s(_vm.$t("account.dropdowns.statusValueProcessing"))
                    ),
                  ]),
                  _c("option", { domProps: { value: 3 } }, [
                    _vm._v(
                      _vm._s(_vm.$t("account.dropdowns.statusValuePickup"))
                    ),
                  ]),
                ]
              ),
              _c("angle-down"),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "periodSelector" }, [
          _c("label", { attrs: { for: "period" } }, [
            _vm._v(_vm._s(_vm.$t("account.dropdowns.periodDropdownLabel"))),
          ]),
          _c(
            "div",
            { staticClass: "selectField" },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.period,
                      expression: "period",
                    },
                  ],
                  staticClass: "dateFilter",
                  attrs: { id: "period" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.period = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: 0 } }, [
                    _vm._v(
                      _vm._s(_vm.$t("account.dropdowns.periodValueLastMonth"))
                    ),
                  ]),
                  _c("option", { domProps: { value: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("account.dropdowns.periodValueAll"))),
                  ]),
                ]
              ),
              _c("angle-down"),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { ref: "orderSummaries", staticClass: "orderSummaries" }, [
      _vm.filteredPurchases && _vm.filteredPurchases.length
        ? _c(
            "ul",
            { staticClass: "orderSummaryList" },
            _vm._l(_vm.filteredPurchases, function (summary, index) {
              return _c(
                "li",
                { key: index },
                [_c("OrderSummary", { attrs: { summary: summary } })],
                1
              )
            }),
            0
          )
        : _c("div", { staticClass: "text" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("account.noResults")))]),
          ]),
      _c("div", { staticClass: "orderSummaries-bottom" }, [
        _c(
          "div",
          { staticClass: "left pager" },
          [
            _c("Pagination", {
              attrs: {
                numberOfItems: _vm.totalItems,
                itemsPerPage: _vm.perPage,
              },
              model: {
                value: _vm.page,
                callback: function ($$v) {
                  _vm.page = $$v
                },
                expression: "page",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "right results" }, [
          _vm._v(
            " " +
              _vm._s(_vm.totalItems) +
              " " +
              _vm._s(_vm.$tc("account.result", _vm.totalItems))
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }