<template>
  <div>
    <div v-if="payment != null && payment.bamboraCardID > 0" class="paymentInfos">
      <div class="paymentInfos-upperSection">
        <div class="paymentInfos-cardDigits">
          <img v-if="payment.creditCardType === 'VI'" src="@/assets/svg/Visa-logo.svg" class="vi-card-logo">
          <img v-else-if="payment.creditCardType === 'MC'" src="@/assets/svg/MasterCard-logo.svg" class="mc-card-logo">
          <strong>{{ $t('order.payment.endsWith') }} {{ payment.creditCardLastDigits }}</strong>
        </div>
        <div class="paymentInfos-delete">
          <button class="btnText infoPanel-action" type="button" @click="handleDeletePayment()">{{ $t("users.actions.delete") }}</button>
          <img src="/images/loading.gif" v-if="isDeleting" class="loading" />
        </div>
      </div>
      
      <div class="paymentInfos-lowerSection">
        <div class="paymentInfos-cardholder">{{ $t('paymentInformations.cardholderName') }}{{ payment.cardholderName }}</div>
        <div class="paymentInfos-exp" v-if="!isCardExpired">{{ $t('paymentInformations.expirationLabel') }} {{ payment.creditCardExpiration }}</div>
        <div class="expired" v-else>{{ $t('paymentInformations.expiredCard') }} {{ payment.creditCardExpiration }}</div>
      </div>
    </div>
    <BamboraForm
      v-else
      :button-text="$t('users.actions.save')"
      :error-as-events="true"
      @submit="handleAddPayment"
    ></BamboraForm>

    <working-button 
      v-if="payment != null && payment.bamboraCardID > 0"
      class="btn"
      :isWorking="paymentInfoIsSaving"
      :label="$t('users.actions.save')"
      :handler="() => $emit('close')"
    ></working-button>
    <div class="formItem">
      <div v-if="savePaymentInfoError" class="formItem-errorMessage error">{{ savePaymentInfoError }}</div>
      <div v-if="savePaymentInfoMessage" class="actionResult">{{ savePaymentInfoMessage }}</div>
      <div v-if="deletePaymentErrorMessage != null" class="formItem-errorMessage error" v-html="deletePaymentErrorMessage"></div>
    </div>
  </div>
</template>

<script>
import BamboraForm from '../Order/BamboraForm'
import WorkingButton from './WorkingButton.vue'

export default {
  components: {
    BamboraForm,
    WorkingButton
  },
  props: {
    bamboraConfiguration: {
      type: Object,
      required: true
    },
    payment: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      bamboraCardID: 1, //TODO à changer si on supporte le multi carte,
      errorType: null,
      isDeleting: false,
      paymentInfoIsSaving: false,
      savePaymentInfoError: undefined,
      savePaymentInfoMessage: undefined,
      deletePaymentErrorMessage: null
    }
  },
  computed: {
    isCardExpired() {
      return this.$root.isCardExpired(this.payment.creditCardExpiration)
    }
  },
  methods: {
    async handleAddCardToProfile(tokenResult, customerCode, cardholderName) {
      try {
        const request = {
          token: {
            name: cardholderName,
            code: tokenResult.token
          },
          validate: true
        }

        const response = await this.$axios.post(`${this.bamboraConfiguration.apiUrl}/profiles/${customerCode}/cards`, request, { 
          headers: {
            'Authorization': `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`,
          }
        });

        if (response && response.data && response.data.validation && response.data.validation.approved === '1') {
          await this.handleSaveUserPayment(tokenResult, customerCode, response.data, cardholderName);
        } else {
          await this.handleDeleteProfile(customerCode);
          this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
        }
      }
      catch(error) {
        await this.handleDeleteProfile(customerCode);
        this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
      }
    },
    async handleAddPayment(bamboraResult) {
      if (this.user.bamboraCustomerCode) {
        await this.handleAddCardToProfile(bamboraResult.result, this.user.bamboraCustomerCode, bamboraResult.cardholderName);
      } else {
        await this.handleCreateProfile(bamboraResult);
      }
    },
    async handleCreateProfile(result) {
      if (this.paymentInfoIsSaving) {
        return;
      }

      this.savePaymentInfoError = null;

      try {
        this.paymentInfoIsSaving = true

        const createProfileRequest = {
          token: result.result.token,
          validate: true
        }

        const createProfileResponse = await this.$axios.post('https://api.na.bambora.com/v1/profiles', createProfileRequest, { 
          headers: {
            'Authorization': `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`,
          }
        });

        if (createProfileResponse && createProfileResponse.data) {
          await this.handleAddCardToProfile(result.result, createProfileResponse.data.customer_code, result.cardholderName);
        } else {
          this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
        }
      } catch (error) {
        this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
      } finally {
        this.paymentInfoIsSaving = false
      }
    },
    async handleDeletePayment() {
      try {
        this.isDeleting = true
        const model = {
          bamboraCardID: this.payment.bamboraCardID,
          creditCardLastDigits: this.payment.creditCardLastDigits,
          customerCode: this.payment.bamboraCustomerCode,
          memberID: this.user.contact.memberID      
        };
        const response = await this.$axios.post('/Umbraco/Api/User/DeleteUserPayment', model)
        if (response != null && response.data != null) {
          this.$emit('success:deletePayment')
          this.isDeleting = false
        } else {
          this.deletePaymentErrorMessage = this.$t('users.messages.deletePaymentError') // Pour couvrir axios
          this.isDeleting = false
        }
      } catch (error) {
        // TODO
        this.errorType = error && error.response && error.response.data ? error.response.data : null
        this.deletePaymentErrorMessage = this.errorType != null ? this.$t(`users.messages.${this.errorType}`) : this.$t('users.messages.deletePaymentError')
        this.isDeleting = false
      }
    },
    async handleDeleteProfile(customerCode) {
      try {
        this.paymentInfoIsSaving = true

        const response = await this.$axios.delete(`https://api.na.bambora.com/v1/profiles/${customerCode}`, {
          headers: {
            Authorization: `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`
          },
          data: {
          }
        });

        if (!response || !response.data) {
          this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
        }
      } catch (error) {
        this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
      } finally {
        this.paymentInfoIsSaving = false
      }
    },
    async handleSaveUserPayment(tokenResult, customerCode, cardData, cardholderName) {
      const response = await this.$axios.get(`https://api.na.bambora.com/v1/profiles/${customerCode}/cards/${this.bamboraCardID}`, {
          headers: {
            Authorization: `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`
          }
        });
      const request = {
        bamboraCardID: this.bamboraCardID,
        bamboraCustomerCode: customerCode,
        memberID: this.user.contact.memberID,
        cardholderName,
        creditCardholderName: cardholderName,
        creditCardLastDigits: tokenResult.last4,
        creditCardExpiration: `${tokenResult.expiryMonth}/${tokenResult.expiryYear}`,
        creditCardType: response && response.data && response.data.card[0] && response.data.card[0].card_type ? response.data.card[0].card_type : null
      }
      const result = await this.$axios.post('/Umbraco/Api/User/SaveUserPayment', request)

      if (result && result.data) {
        this.savePaymentInfoMessage = this.$t('users.messages.success.editPaymentInfo')
        this.$emit('success:updatePaymentInfo', result.data)
      }
    }
  }
}
</script>