var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    _vm._b(
      {
        staticClass: "btn_loading",
        attrs: { type: _vm.type, disabled: _vm.isWorking },
        on: { click: () => _vm.handler && _vm.handler() },
      },
      "button",
      _vm.$attrs,
      false
    ),
    [
      _vm._v(_vm._s(_vm.label)),
      _vm.isWorking
        ? _c("img", {
            staticClass: "loading",
            attrs: { src: "/images/loading.gif" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }