<template>
  <div class="searchBar">
    <div class="divInput">
      <input type="text" :placeholder="$t('searchBar.placeholder')" v-model="searchText"
             @keydown.enter="refreshSearchText" class="search" />
    </div>
    <button v-show="searchText.length > 0" class="reset" @click="resetSearch"></button>
    <button @click="refreshSearchText">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8125 11.5391L8.67188 8.39844C9.35156 7.57812 9.72656 6.52344 9.72656 5.375C9.72656 2.70312 7.52344 0.5 4.85156 0.5C2.15625 0.5 0 2.70312 0 5.375C0 8.07031 2.17969 10.25 4.85156 10.25C5.97656 10.25 7.03125 9.875 7.875 9.19531L11.0156 12.3359C11.1328 12.4531 11.2734 12.5 11.4375 12.5C11.5781 12.5 11.7188 12.4531 11.8125 12.3359C12.0469 12.125 12.0469 11.7734 11.8125 11.5391ZM1.125 5.375C1.125 3.3125 2.78906 1.625 4.875 1.625C6.9375 1.625 8.625 3.3125 8.625 5.375C8.625 7.46094 6.9375 9.125 4.875 9.125C2.78906 9.125 1.125 7.46094 1.125 5.375Z"
              fill="#2A2A2A" />
      </svg>
    </button>
  </div>
</template>

<script>
  import hashMixins from '@/hashMixins';

  export default {
    props: {
      defaultValue: {
        type: String
      },
      itemsContainer: {
        type: Object,
        required: true
      },
      facetProperty: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        searchText: this.defaultValue || ''
      };
    },
    created() {
      const hashParams = this.getHashParams();
      if (hashParams[this.facetProperty]) {
        this.searchText = hashParams[this.facetProperty];
      }
      this.refreshSearchText();
    },
    methods: {
      refreshSearchText() {
        this.itemsContainer.selectedFacets = {
          ...this.itemsContainer.selectedFacets,
          [this.facetProperty]: this.searchText
        };
      },
      resetSearch() {
        this.searchText = '';
        this.refreshSearchText();
      }
    },
    watch: {
      'itemsContainer.items'() {
        this.refreshSearchText();
        this.$root.$emit('scroll');
      }
    },
    mixins: [hashMixins]
  };
</script>
