var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paymentInfos" }, [
    _c("div", { staticClass: "paymentInfos-upperSection" }, [
      _c("div", { staticClass: "paymentInfos-cardDigits" }, [
        _vm.receipt.creditCardCompany === "VI"
          ? _c("img", {
              staticClass: "vi-card-logo",
              attrs: { src: require("@/assets/svg/Visa-logo.svg") },
            })
          : _vm.receipt.creditCardCompany === "MC"
          ? _c("img", {
              staticClass: "mc-card-logo",
              attrs: { src: require("@/assets/svg/MasterCard-logo.svg") },
            })
          : _vm._e(),
        _vm._v(
          " " +
            _vm._s(_vm.$t("order.payment.endsWith")) +
            " " +
            _vm._s(_vm.receipt.creditCardLastDigits)
        ),
      ]),
      !_vm.isCardExpired
        ? _c("div", { staticClass: "paymentInfos-exp" }, [
            _vm._v(
              _vm._s(_vm.$t("paymentInformations.expirationLabel")) +
                " " +
                _vm._s(_vm.receipt.bamboraCardExpiration)
            ),
          ])
        : _c("div", [
            _vm._v(
              _vm._s(_vm.$t("paymentInformations.expiredCard")) +
                " " +
                _vm._s(_vm.receipt.bamboraCardExpiration)
            ),
          ]),
    ]),
    _c("div", { staticClass: "paymentInfos-lowerSection" }, [
      _c("div", { staticClass: "paymentInfos-addressLabel" }, [
        _vm._v(_vm._s(_vm.$t("paymentInformations.billingAddressLabel"))),
      ]),
      _c("div", { staticClass: "paymentInfos-address" }, [
        _vm._v(
          _vm._s(_vm.billingAddress.address) +
            " " +
            _vm._s(_vm.billingAddress.city) +
            " " +
            _vm._s(_vm.billingAddress.postalCode)
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }