var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.branch
    ? _c(
        "address",
        [
          !_vm.hideName
            ? [_c("b", [_vm._v(_vm._s(_vm.branch.name))]), _c("br")]
            : _vm._e(),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.branch.address) } }),
          _c("a", { attrs: { href: _vm.getPhoneUrl } }, [
            _vm._v(_vm._s(_vm.getFormattedPhoneNumber)),
          ]),
          _c("br"),
          _vm.getTollFreePhoneUrl
            ? [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("users.accountText.tollFree")) + " : "),
                  _c("a", { attrs: { href: _vm.getTollFreePhoneUrl } }, [
                    _vm._v(_vm._s(_vm.getFormattedTollFreePhoneNumber)),
                  ]),
                ]),
                _c("br"),
              ]
            : _vm._e(),
          _c("a", { attrs: { href: _vm.getEmailUrl } }, [
            _vm._v(_vm._s(_vm.branch.email)),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }