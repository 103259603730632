var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isHidden
    ? _c("div", { staticClass: "dropdownFacet" }, [
        _vm.header
          ? _c("label", { staticClass: "facetTitle" }, [
              _vm._v(_vm._s(_vm.header)),
            ])
          : _vm._e(),
        _c("div", { staticClass: "divSelect dropdownStyle" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedFacet,
                  expression: "selectedFacet",
                },
              ],
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedFacet = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(" " + _vm._s(_vm.allText) + " "),
              ]),
              _vm._l(_vm.facets, function (facet, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: facet.value } },
                  [_vm._v(" " + _vm._s(facet.value) + " ")]
                )
              }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }