<template>
  <a class="btn branch" @click="$root.onChangeStore(store)" v-if="!isSelectedStore">{{ $t('chooseThisStore') }}</a>
  <a :class="{selected : isSelectedStore}" class="btn branch" @click="$root.onChangeStore(store)" v-else>{{ $t('myStore') }}</a>
</template>

<script>
import store from '@/models/store/Store';

export default {
  props: {
    store: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      stores: store
    };
  },
  computed: {
    isSelectedStore() {
      const storeCookie = this.$cookies.get('store');

      if (!storeCookie) {
        return false;
      }

      const storeCode = Object.keys(this.stores).find(key => 
        this.stores[key].toString() === storeCookie);

      return this.stores[storeCode] === this.store;
    }
  }
};
</script>
