<template>
  <div class="deliveryDate">
    <label>{{ $t('order.delivery.label') }}</label>
    <div class="radioOptions">
      <div class="checkboxContainer" @click="selectDeliveryDate(date)" v-for="date in deliveryDates" :key="date">
        <input v-model="deliveryDate" type="radio" :value="date">
        <span class="radio"></span>
        <label>{{ date | formatDate }}</label>
      </div>
      <div class="checkboxContainer" @click="selectDeliveryDate('call')">
        <input v-model="deliveryDate" type="radio" :value="'call'">
        <span class="radio"></span>
        <label>{{ $t('cart.callMe') }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('cartModule', ['deliveryDates', 'deliveryDate'])

  },
  filters: {
    formatDate: date =>
      Intl.DateTimeFormat('fr-CA', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(new Date(date))
  },
  methods: {
    ...mapActions('cartModule', ['getDeliveryDates', 'setCallCustomerOption', 'setDeliveryDate']),
    selectDeliveryDate(date) {
      this.setCallCustomerOption(date === 'call');
      this.setDeliveryDate(date);
    }
  },
  async created() {
    await this.getDeliveryDates();
    if (!this.deliveryDate) {
      this.setDeliveryDate(Array.isArray(this.deliveryDates) && this.deliveryDates.length ? this.deliveryDates[0] : null);
    }
  }
};
</script>
