var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "staticDropDownFacet" }, [
    _vm.header
      ? _c("label", { staticClass: "facetTitle" }, [_vm._v(_vm._s(_vm.header))])
      : _vm._e(),
    _c("div", { staticClass: "divSelect dropdownStyle" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedFacet,
              expression: "selectedFacet",
            },
          ],
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedFacet = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.facets, function (value) {
          return _c(
            "option",
            {
              key: value[_vm.displayNameProperty],
              domProps: { value: value[_vm.valueProperty] },
            },
            [_vm._v(" " + _vm._s(value[_vm.displayNameProperty]) + " ")]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }