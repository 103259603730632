var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.payment != null && _vm.payment.bamboraCardID > 0
        ? _c("div", { staticClass: "paymentInfos" }, [
            _c("div", { staticClass: "paymentInfos-upperSection" }, [
              _c("div", { staticClass: "paymentInfos-cardDigits" }, [
                _vm.payment.creditCardType === "VI"
                  ? _c("img", {
                      staticClass: "vi-card-logo",
                      attrs: { src: require("@/assets/svg/Visa-logo.svg") },
                    })
                  : _vm.payment.creditCardType === "MC"
                  ? _c("img", {
                      staticClass: "mc-card-logo",
                      attrs: {
                        src: require("@/assets/svg/MasterCard-logo.svg"),
                      },
                    })
                  : _vm._e(),
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t("order.payment.endsWith")) +
                      " " +
                      _vm._s(_vm.payment.creditCardLastDigits)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "paymentInfos-delete" }, [
                _c(
                  "button",
                  {
                    staticClass: "btnText infoPanel-action",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.handleDeletePayment()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.delete")))]
                ),
                _vm.isDeleting
                  ? _c("img", {
                      staticClass: "loading",
                      attrs: { src: "/images/loading.gif" },
                    })
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "paymentInfos-lowerSection" }, [
              _c("div", { staticClass: "paymentInfos-cardholder" }, [
                _vm._v(
                  _vm._s(_vm.$t("paymentInformations.cardholderName")) +
                    _vm._s(_vm.payment.cardholderName)
                ),
              ]),
              !_vm.isCardExpired
                ? _c("div", { staticClass: "paymentInfos-exp" }, [
                    _vm._v(
                      _vm._s(_vm.$t("paymentInformations.expirationLabel")) +
                        " " +
                        _vm._s(_vm.payment.creditCardExpiration)
                    ),
                  ])
                : _c("div", { staticClass: "expired" }, [
                    _vm._v(
                      _vm._s(_vm.$t("paymentInformations.expiredCard")) +
                        " " +
                        _vm._s(_vm.payment.creditCardExpiration)
                    ),
                  ]),
            ]),
          ])
        : _c("BamboraForm", {
            attrs: {
              "button-text": _vm.$t("users.actions.save"),
              "error-as-events": true,
            },
            on: { submit: _vm.handleAddPayment },
          }),
      _vm.payment != null && _vm.payment.bamboraCardID > 0
        ? _c("working-button", {
            staticClass: "btn",
            attrs: {
              isWorking: _vm.paymentInfoIsSaving,
              label: _vm.$t("users.actions.save"),
              handler: () => _vm.$emit("close"),
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "formItem" }, [
        _vm.savePaymentInfoError
          ? _c("div", { staticClass: "formItem-errorMessage error" }, [
              _vm._v(_vm._s(_vm.savePaymentInfoError)),
            ])
          : _vm._e(),
        _vm.savePaymentInfoMessage
          ? _c("div", { staticClass: "actionResult" }, [
              _vm._v(_vm._s(_vm.savePaymentInfoMessage)),
            ])
          : _vm._e(),
        _vm.deletePaymentErrorMessage != null
          ? _c("div", {
              staticClass: "formItem-errorMessage error",
              domProps: { innerHTML: _vm._s(_vm.deletePaymentErrorMessage) },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }