const canadianPostalCodeRegex = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;

export default {
  getMessage() {
    return 'Invalid canadian postal code';
  },
  validate(value) {
    if (value === null) {
      return true;
    }
    return canadianPostalCodeRegex.test(value);
  }
};