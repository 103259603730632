var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pagination" }, [
    _c(
      "div",
      [
        _c(
          "button",
          {
            staticClass: "firstPage",
            attrs: { disabled: _vm.currentPage <= 1 },
            on: { click: _vm.firstPage },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "10",
                  height: "11",
                  viewBox: "0 0 10 11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                    fill: "#E31F28",
                  },
                }),
              ]
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "previousPage",
            attrs: { disabled: _vm.currentPage <= 1 },
            on: { click: _vm.previousPage },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "10",
                  height: "11",
                  viewBox: "0 0 10 11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                    fill: "#E31F28",
                  },
                }),
              ]
            ),
            _vm._v(" " + _vm._s(_vm.$t("pagination.previous")) + " "),
          ]
        ),
        _vm._l(_vm.pageRange, function (i) {
          return _c(
            "button",
            {
              key: i,
              class: { selected: _vm.currentPage === i },
              on: {
                click: function ($event) {
                  return _vm.goToPage(i)
                },
              },
            },
            [_vm._v(_vm._s(i))]
          )
        }),
        _c(
          "button",
          {
            staticClass: "nextPage",
            attrs: { disabled: _vm.currentPage >= _vm.pageCount },
            on: { click: _vm.nextPage },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("pagination.next")) + " "),
            _c(
              "svg",
              {
                attrs: {
                  width: "10",
                  height: "11",
                  viewBox: "0 0 10 11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                    fill: "#E31F28",
                  },
                }),
              ]
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "lastPage",
            attrs: { disabled: _vm.currentPage >= _vm.pageCount },
            on: { click: _vm.lastPage },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "10",
                  height: "11",
                  viewBox: "0 0 10 11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                    fill: "#E31F28",
                  },
                }),
              ]
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }