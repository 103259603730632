var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    {
      ref: "editDefaultBranchInfoForm",
      staticClass: "editDefaultBranchInfoForm accountForm",
      attrs: { tag: "form", novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSaveDefaultBranchInfo.apply(null, arguments)
        },
      },
    },
    [
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "branchId", rules: "required" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c(
                  "label",
                  { attrs: { for: "editDefaultBranchInfoForm-branchSelect" } },
                  [_vm._v(_vm._s(_vm.$t("users.fields.defaultBranch")) + "*")]
                ),
                _c("custom-select", {
                  attrs: { options: _vm.branchOptions },
                  model: {
                    value: _vm.selectedBranchId,
                    callback: function ($$v) {
                      _vm.selectedBranchId = $$v
                    },
                    expression: "selectedBranchId",
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("branch-address", {
        attrs: { branch: _vm.selectedBranch, hideName: true },
      }),
      _c(
        "div",
        { staticClass: "formItem" },
        [
          _c("working-button", {
            staticClass: "btn",
            attrs: {
              isWorking: _vm.defaultBranchInfoIsSaving,
              label: _vm.$t("users.actions.save"),
              type: "submit",
            },
          }),
          _vm.saveDefaultBranchInfoError
            ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                _vm._v(_vm._s(_vm.saveDefaultBranchInfoError)),
              ])
            : _vm._e(),
          _vm.saveDefaultBranchInfoMessage
            ? _c("div", { staticClass: "actionResult" }, [
                _vm._v(_vm._s(_vm.saveDefaultBranchInfoMessage)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }