<template>
  <div>
    <form id="checkout-form">
      <div class="firstRow">
        <label for="card-number">{{ $t('bambora.label.cardNumber') }}</label>
        <div id="card-number" ref="cardNumberParentElement"><span v-if="isErrorCardNumber == true" class="cardError">{{ $t(`bambora.errorTypes.${cardNumberError}`) }}</span></div>
      </div>
      <div class="seccondRow">
        <div class="expiry">
          <label for="card-expiry">{{ $t('bambora.label.expiry') }}</label>
          <div id="card-expiry" ref="expiryParentElement"><span v-if="isErrorExpiry == true" class="cardError">{{ $t(`bambora.errorTypes.${expiryError}`) }}</span></div>
        </div>
        <div class="cvv">
          <label for="card-cvv">{{ $t('bambora.label.cvv') }}</label>
          <div id="card-cvv" ref="cvvParentElement"><span v-if="isErrorCVV == true" class="cardError">{{ $t(`bambora.errorTypes.${CVVError}`) }}</span></div>
        </div>
      </div>
      <div class="thirdRow">
        <div>
          <label for="card-holder">{{ $t('bambora.label.cardholderName') }}</label>
          <div >
            <input id="card-holder" v-model="cardholderName" type="text">
            <span v-if="isErrorCardholder" class="cardError">{{ $t(`bambora.errorTypes.cardholder`) }}</span>
          </div>
        </div>
      </div>
    </form>
    <button class="btn" :disabled="isPaymentInfoSaving || isProcessingOrder" @click="submit">{{ buttonText || $t('bambora.submitButtonText') }}<img src="/images/loading.gif" v-if="isPaymentInfoSaving || isProcessingOrder" class="loading" /></button>
    <modal v-if="isInvalidOrIncomplete && !errorAsEvents" @onclose="close()" :isPopin="true" :click-outside-enabled="true">
      <template v-slot:title>
        <h3>{{ $t('bambora.modal.title') }}</h3>
      </template>
      <template v-slot:body>
        <p>{{ $t('bambora.modal.body') }}</p>
        <button class="btn" @click="close">{{ $t('bambora.modal.close') }}</button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '../UI/Modal.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Modal
  },
  props: {
    buttonText: {
      type: String,
      required: false
    },
    errorAsEvents: {
      type: Boolean,
      default: false
    },
    isPaymentInfoSaving: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cardCVV: null,
      cardExpiry: null,
      cardholderName: null,
      cardNumber: null,
      cardNumberError: '',
      CVVError: '',
      customCheckout: null,
      expiryError: '',
      isErrorCardholder: false,
      isErrorCardNumber: false,
      isErrorCVV: false,
      isErrorExpiry: false,
      isInvalidOrIncomplete: false
    }
  },
  mounted() {
    // Styles to be applied to the text rendered in the iframe
    var style = {
      error: {
        color: 'red',
        ':focus': {
          fontStyle: 'italic',
        },
      },
    };

    // Classes to be applied to the element wrapping the iframe
    var classes = {
      error: 'my-error-class',        
    };

    var optionsNumber = {
      placeholder: this.$t('bambora.placeholder.cardNumber'),
      style: style,
      classes: classes,
      brands: ['visa', 'mastercard']
    };
    var optionsCVV = {
      placeholder: this.$t('bambora.placeholder.cvv'),
      style: style,
      classes: classes
    };
    var optionsExpiry = {
      placeholder: this.$t('bambora.placeholder.expiry'),
      style: style,
      classes: classes
    };

    this.customCheckout = customcheckout();

    this.cardNumber = this.customCheckout.create('card-number', optionsNumber)
    this.cardNumber.mount('#card-number');
    this.$refs.cardNumberParentElement.appendChild(this.$refs.cardNumberParentElement.firstChild); //permet de mettre l'erreur après le input

    this.cardCVV = this.customCheckout.create('cvv', optionsCVV)
    this.cardCVV.mount('#card-cvv');
    this.$refs.cvvParentElement.appendChild(this.$refs.cvvParentElement.firstChild);

    this.cardExpiry = this.customCheckout.create('expiry', optionsExpiry)
    this.cardExpiry.mount('#card-expiry');
    this.$refs.expiryParentElement.appendChild(this.$refs.expiryParentElement.firstChild);

    this.setCustomCheckoutEvents();
  },
  computed: {
    ...mapState('cartModule', ['isProcessingOrder'])
  },
  methods: {
    setCustomCheckoutEvents() {
      const self = this;
      this.customCheckout.on('error', function(event) {
        if (event.field === 'card-number') {
          self.cardNumberError = event.type;
          self.isErrorCardNumber = true;
        } else if (event.field === 'cvv') {
          self.CVVError = event.type;
          self.isErrorCVV = true;
        } else if (event.field === 'expiry') {
          self.expiryError = event.type;
          self.isErrorExpiry = true;
        }
      });

      this.customCheckout.on('complete', function(event) {
        if (event.field === 'card-number') {
          self.isErrorCardNumber = false;
        } else if (event.field === 'cvv') {
          self.isErrorCVV = false;
        } else if (event.field === 'expiry') {
          self.isErrorExpiry = false;
        }
      });
    },
    submit() {
      const self = this;
      this.isErrorCardholder = false;

      if (!this.cardholderName) {
        this.isErrorCardholder = true;
        return;
      }

      this.customCheckout.createToken(function (result) {
        if (result.error) {
          // display error message
          self.isInvalidOrIncomplete = true

          if (this.errorAsEvents) {
            self.$emit('error', result.error)
          }
        } else {
          // process token using our Payments API
          self.$emit('submit', { result, cardholderName: self.cardholderName })
        }
      });
    },
    close() {
      this.isInvalidOrIncomplete = false;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>