var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "account-defaultBranchInfo infoPanel" },
    [
      _c("div", { staticClass: "infoPanel-title" }, [
        _c("h3", { staticClass: "infoPanel-heading" }, [
          _vm._v(_vm._s(_vm.$t("users.titles.defaultBranchInfo"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "infoPanel-data" },
        [
          _vm.defaultBranch
            ? _c("branch-address", { attrs: { branch: _vm.defaultBranch } })
            : _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("users.accountText.noSelectedFavoriteBranch"))
                ),
              ]),
        ],
        1
      ),
      _c("div", { staticClass: "infoPanel-actions" }, [
        _c(
          "button",
          {
            staticClass: "infoPanel-action btnText",
            attrs: { type: "button" },
            on: { click: _vm.handleShowEditDefaultBranchInfoDialog },
          },
          [_vm._v(_vm._s(_vm.$t("users.actions.edit")))]
        ),
      ]),
      _c(
        "teleport",
        { attrs: { to: "body" } },
        [
          _c(
            "dialog-modal",
            {
              attrs: {
                isOpen: _vm.editDefaultBranchInfoDialogIsOpen,
                title: _vm.$t("users.titles.defaultBranchInfoEdition"),
              },
              on: {
                "close:dialog": _vm.handleCloseEditDefaultBranchInfoDialog,
              },
            },
            [
              _c("edit-default-branch-info", {
                ref: "editDefaultBranchInfoForm",
                attrs: {
                  branches: _vm.branches,
                  branchId: _vm.branchId !== 0 ? _vm.branchId : 1,
                  user: _vm.user,
                },
                on: {
                  "success:updateDefaultBranch":
                    _vm.handleUpdateDefaultBranchSuccess,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }