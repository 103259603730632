var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "favorite" },
    [
      !_vm.isUnavailable(_vm.product)
        ? _c(
            "a",
            {
              class: ["addToCart", { like: _vm.isInCart }],
              on: {
                click: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "cancel",
                      undefined,
                      $event.key,
                      undefined
                    )
                  )
                    return null
                  return _vm.toggle.apply(null, arguments)
                },
              },
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.member
        ? _c("add-to-favorites", {
            staticClass: "addFavorite",
            class: _vm.isFavorite === true ? "hidden" : "",
            attrs: { product: _vm.product, "member-id": _vm.member },
          })
        : _vm._e(),
      _vm.member
        ? _c("delete-from-favorites", {
            class: _vm.isFavorite === false ? "hidden" : "",
            attrs: { product: _vm.product, "member-id": _vm.member },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }