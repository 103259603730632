var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addToCart" },
    [
      _c("quantity-selector", {
        attrs: {
          disabled: _vm.unavailable,
          "maximum-quantity": _vm.maximumQuantity,
        },
        model: {
          value: _vm.quantity,
          callback: function ($$v) {
            _vm.quantity = $$v
          },
          expression: "quantity",
        },
      }),
      _c("add-to-cart-button", {
        attrs: {
          disabled: _vm.unavailable,
          maximumQuantity: _vm.maximumQuantity,
          productId: _vm.productId,
          product: _vm.product,
          quantity: _vm.quantity,
        },
      }),
      _vm.memberId
        ? _c("add-to-favorites", {
            class: _vm.isUserFavorite === true ? "hidden" : "",
            attrs: { product: _vm.product, "member-id": _vm.memberId },
          })
        : _vm._e(),
      _vm.memberId
        ? _c("delete-from-favorites", {
            class: _vm.isUserFavorite === false ? "hidden" : "",
            attrs: { product: _vm.product, "member-id": _vm.memberId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }