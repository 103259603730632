import { render, staticRenderFns } from "./XMark.vue?vue&type=template&id=508a32da"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('508a32da')) {
      api.createRecord('508a32da', component.options)
    } else {
      api.reload('508a32da', component.options)
    }
    module.hot.accept("./XMark.vue?vue&type=template&id=508a32da", function () {
      api.rerender('508a32da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Svg/XMark.vue"
export default component.exports