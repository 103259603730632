var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dialog",
    { ref: "dialogElement", staticClass: "dialogModal wrapper" },
    [
      _c("div", { ref: "dialogInner", staticClass: "dialogModal-inner" }, [
        _c(
          "header",
          { staticClass: "dialogModal-header" },
          [
            _vm._t("header", function () {
              return [
                _c("h2", { staticClass: "dialogModal-heading" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]
            }),
            _c("div", { staticClass: "closeDialog-gridItem" }, [
              _c(
                "button",
                {
                  staticClass: "dialogModal-closeDialog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_c("x-mark")],
                1
              ),
            ]),
          ],
          2
        ),
        _c("hr"),
        _c(
          "div",
          { staticClass: "dialogModal-content" },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }