<template>
  <address v-if="branch">
    <template v-if="!hideName">
      <b>{{ branch.name }}</b><br />
    </template>
    <div v-html="branch.address"></div>
    <a :href="getPhoneUrl">{{ getFormattedPhoneNumber }}</a><br />
    <template v-if="getTollFreePhoneUrl">
      <span>{{ $t('users.accountText.tollFree') }}&nbsp;:&nbsp;<a :href="getTollFreePhoneUrl">{{ getFormattedTollFreePhoneNumber }}</a></span><br />
    </template>
    <a :href="getEmailUrl">{{ branch.email }}</a>
  </address>
</template>

<script>
import { formatPhoneNumber, getPhoneUrlFromFormattedPhoneString } from '../../helpers/stringHelper'

export default {
  props: {
    branch: {
      type: Object
    },
    hideName: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getEmailUrl() {
      if(!this.branch.email) {
        return undefined
      }

      return `mailto:${this.branch.email}`
    },
    getFormattedPhoneNumber() {
      if(!this.branch.phone) {
        return undefined
      }
      return formatPhoneNumber(this.branch.phone)
    },
    getFormattedTollFreePhoneNumber() {
      if(!this.branch.tollFreePhone) {
        return undefined
      }
      return formatPhoneNumber(this.branch.tollFreePhone)
    },
    getPhoneUrl() {
      if(!this.branch.phone) {
        return undefined
      }
      return getPhoneUrlFromFormattedPhoneString(this.branch.phone)
    },
    getTollFreePhoneUrl() {
      if(!this.branch.tollFreePhone) {
        return undefined
      }
      return getPhoneUrlFromFormattedPhoneString(this.branch.tollFreePhone)
    }
  }
}
</script>