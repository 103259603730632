<template>
  <div>
    <slot :defaults="defaultConfigurations" :itemsContainer.sync="itemsContainer" />
  </div>
</template>

<script>
export default {
  props: {
    defaults: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      itemsContainer: {
        isDesc: true,
        items: [],
        facets: [],
        selectedFacets: {},
        total: 0
      }
    };
  },
  computed: {
    defaultConfigurations() {
      return { 
        count: 8,
        store: this.$root.getStore(), 
        ...this.defaults 
      };
    }
  }
};
</script>
