<template>
  <article class="favoriteProduct">    
    <div class="favoriteProduct-container">
      <div class="imageSection">
        <img class="favoriteProduct-image"
              @error="setFallbackProductImage"
              :src="product.image && product.image.url ? product.image.url : fallbackProductImageUrl"
              alt="" />
      </div>

      <div class="dataSection">
        <h3 class="productName">
          <a :href="product.url" class="favoriteProduct-productLink">{{ product.name }}</a>
        </h3>

        <div class="productInfos">
          <div class="majorCategory">
            <span>{{ product.majorCategory }}</span>
            <p v-if="!isAvailable" class="warning">{{ $t('myFavorites.notAvailable') }}</p>
          </div>

          <div class="prices">
            <div class="promotionPrices">
              <span v-if="price" :class="{stroke: product.hasPromotion}">{{ price }}</span>
              <span v-else>{{ $t('product.availability.priceUnavailable') }}</span>
              
              <span v-if="product.hasPromotion" class="redPrice">{{ bestPrice }}</span>
            </div>
          </div>
        </div>
        <div class="productInfos">
          <div class="productInfosStore">
            <div v-if="!isUnavailable(product)" class="inStock">
              {{
                $t("product.availability.inStockWithValue", {
                  value: getQuantity(product).value
                })
              }}
            </div>
            <div v-else-if="!isInactive(product) && !isInStock(product)" class="notInStock">
              {{ $t("product.availability.notInStock") }}
            </div>
            <div v-else-if="isPromotional(product)" class="unavailable">
              {{ $t("product.availability.promotional") }}
            </div>
            <div v-else-if="isRecall(product)" class="unavailable">
              {{ $t("product.availability.recall") }}
            </div>
            <div v-else class="unavailable">
              {{ $t("product.availability.unavailable") }}
            </div>
          </div>
          <div class="addCartSection" :class="$root.getFeatureFlag('ecommerce').toString().toLowerCase()">
            <add-to-cart-button
              v-if="!isUnavailable(product)"
              :disabled="isUnavailable(product)"
              :maximumQuantity="getQuantityValue(product)"
              :productId="product.identifier"
              :product="product"
              :quantity="1"
            />
          </div>
        </div>

        <div>
          <button class="deleteButton" type="button" :disabled="isRemovingFavorite" @click="removeFavorite">
            <img v-if="isRemovingFavorite" src="/images/triangle-loading.gif">
            <trash-can v-else />
            <span>{{ $t('users.actions.removeFromFavorites') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="favoriteProduct-actions">
      
    </div>
  </article>
</template>

<script>
import { getFormattedCanadianDollars } from '../../helpers/stringHelper'
import AngleRight from '../Svg/AngleRight.vue'
import AddToCartButton from '../Product/AddToCartButton.vue'
import TrashCan from '../Svg/TrashCan.vue'
import productMixins from '@/productMixins'


export default {
  components: { AngleRight, TrashCan, AddToCartButton },
  props: {
    memberId: {
      type: Number,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isRemovingFavorite: false  
    }
  },
  inject: ['fallbackProductImageUrl'],
  computed: {
    bestPrice() {
      return getFormattedCanadianDollars(this.product.bestPrice)
    },
    isAvailable() {
      return !this.product.deleted || !this.product.isItemDiscontinuedOrOffSeason
    },
    price() {
      return getFormattedCanadianDollars(this.product.price)
    }
  },
  methods: {
    async removeFavorite() {
      const model = {
        memberID: this.memberId,
        productID: this.product.identifier
      }
      try {
        this.isRemovingFavorite = true
        await this.$axios.post('/Umbraco/Api/Favorites/DeleteFavorite', model)
        this.$emit('success:removeFavorite', this.product)
      }
      catch (error) {
        /*Fait rien*/
      }
      finally {
        this.isRemovingFavorite = false
      }
    },
    setFallbackProductImage(event) {
      event.target.src = this.fallbackProductImageUrl
    }
  },
  mixins: [productMixins]
}
</script>

<style lang="scss" scoped>

</style>