import { getProductItemFacets } from '@/helpers/searchHelper';

export const resetProducts = (state) => {
  state.products = [];
};

export const setProducts = (state, { products, selectedStore }) => {
  state.products = getProductItemFacets(products, selectedStore);
};

