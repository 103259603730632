var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selectedStore" }, [
    _c("div", { staticClass: "ctaBranches", attrs: { id: "branchesToggle" } }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$root.hasStoreSelected,
              expression: "!$root.hasStoreSelected",
            },
          ],
          attrs: { onclick: "openSelector()" },
        },
        [
          _vm._v(" " + _vm._s(_vm.noStoreSelectedText) + " "),
          _c(
            "svg",
            {
              attrs: {
                width: "8",
                height: "9",
                viewBox: "0 0 8 9",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z",
                  fill: "#E02028",
                },
              }),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "footerInfos" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "branchName" }, [
            _vm._v(_vm._s(_vm.storeInformation.branchName)),
          ]),
          _c("div", {
            staticClass: "branchAddress",
            domProps: { innerHTML: _vm._s(_vm.storeInformation.address) },
          }),
          _c("div", { staticClass: "branchInfosGlobal" }, [
            _c("div", { staticClass: "phone" }, [
              _vm._v(" " + _vm._s(_vm.storeInformation.phone) + " "),
            ]),
            _c("div", { staticClass: "mail" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: `mailto:${_vm.storeInformation.email}`,
                    onclick:
                      "gtag('event', 'Courriel', {'event_category': 'Prise de contact','event_label': 'Drummondville'});",
                  },
                },
                [_vm._v(_vm._s(_vm.storeInformation.email) + " ")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "labelHours" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("branchHours"))),
            ]),
            _c("div", { staticClass: "branchSchedule" }, [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.storeInformation.businessHours),
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$root.hasStoreSelected,
              expression: "$root.hasStoreSelected",
            },
          ],
          staticClass: "changeBranchLink",
        },
        [
          _c(
            "a",
            {
              staticClass: "btn txt",
              attrs: { href: "#app", onclick: "openSelector()" },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("changeStore")) + " "),
              _c(
                "svg",
                {
                  attrs: {
                    width: "8",
                    height: "9",
                    viewBox: "0 0 8 9",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z",
                      fill: "#E02028",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }