var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "17",
        height: "17",
        viewBox: "0 0 17 17",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15.75 15.21C16.0625 15.5225 16.0625 15.9912 15.75 16.2725C15.625 16.4287 15.4375 16.4912 15.25 16.4912C15.0312 16.4912 14.8438 16.4287 14.6875 16.2725L10.5 12.085C9.375 12.9912 7.96875 13.4912 6.46875 13.4912C2.90625 13.4912 0 10.585 0 6.99121C0 3.42871 2.875 0.491211 6.46875 0.491211C10.0312 0.491211 12.9688 3.42871 12.9688 6.99121C12.9688 8.52246 12.4688 9.92871 11.5625 11.0225L15.75 15.21ZM1.5 6.99121C1.5 9.77246 3.71875 11.9912 6.5 11.9912C9.25 11.9912 11.5 9.77246 11.5 6.99121C11.5 4.24121 9.25 1.99121 6.5 1.99121C3.71875 1.99121 1.5 4.24121 1.5 6.99121Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }