var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.itemsContainer && _vm.itemsContainer.items.length,
          expression: "itemsContainer && itemsContainer.items.length",
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "productsCarrouselHeader" },
        [
          _vm._t("header"),
          _c("div", { staticClass: "nav" }, [
            _c("div", { ref: "previousButton", staticClass: "previousButton" }),
            _c("div", { ref: "nextButton", staticClass: "nextButton" }),
          ]),
        ],
        2
      ),
      _c("facet-items-viewer", {
        ref: "itemsContainer",
        staticClass: "wrapperProductResult",
        attrs: {
          "items-container": _vm.itemsContainer,
          defaults: _vm.defaults,
          addParamsToUrl: _vm.addParamsToUrl,
          "member-id": _vm.memberId,
        },
        on: {
          "update:itemsContainer": function ($event) {
            _vm.itemsContainer = $event
          },
          "update:items-container": function ($event) {
            _vm.itemsContainer = $event
          },
          dataChanged: _vm.initializeSlider,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (obj) {
                return [
                  _vm._t("content", null, {
                    item: obj.item,
                    member: obj.member,
                    isFavorite: obj.isFavorite,
                  }),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }