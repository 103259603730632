var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "8",
        viewBox: "0 0 14 8",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.5 2.05371L7.53125 7.77246C7.34375 7.92871 7.15625 7.99121 7 7.99121C6.8125 7.99121 6.625 7.92871 6.46875 7.80371L0.46875 2.05371C0.15625 1.77246 0.15625 1.27246 0.4375 0.991211C0.71875 0.678711 1.21875 0.678711 1.5 0.959961L7 6.20996L12.4688 0.959961C12.75 0.678711 13.25 0.678711 13.5312 0.991211C13.8125 1.27246 13.8125 1.77246 13.5 2.05371Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }