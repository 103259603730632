<template>
  <section class="favoritesSection wrapper wrapper_small">
    <div v-if="isLoading" class="loadingProduct">
      <img src="/images/triangle-loading.gif" />
    </div>
    <template v-else-if="!user">
      <span v-html="$t('users.messages.getUserError')"></span>
    </template>
    <div v-else>
      <h2>{{ $t('myFavorites.promotionItemsLabel') }}</h2>
      <span v-if="promotionProductsFromFavorites.length === 0">{{ $t('myFavorites.noResults')}}</span>
      <ul class="deals">
        <li v-for="(product, index) in promotionProductsFromFavorites" :key="index">
          <FavoriteProduct @success:removeFavorite="removeFavorite" :memberId="memberId" :product="product"></FavoriteProduct>
        </li>
      </ul>
      <h2>{{ $t('myFavorites.allItemsLabel') }}</h2>
      <span v-if="regularProductsFromFavorites.length === 0">{{ $t('myFavorites.noResults')}}</span>
      <ul>
        <li v-for="(product, index) in regularProductsFromFavorites" :key="index">
          <FavoriteProduct @success:removeFavorite="removeFavorite" :memberId="memberId" :product="product"></FavoriteProduct>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import FavoriteProduct from '../Product/FavoriteProduct.vue'
import { getSummarizedProductsItemFacets } from '../../helpers/searchHelper'

export default {
  props: {
    memberId: {
      required: true
    }
  },
  components: {
    FavoriteProduct
  },
  data() {
    return {
      favorites: null,
      isLoading: true,
      promotionProductsFromFavorites: [],
      regularProductsFromFavorites: [],
      user: null
    }   
  },
  async mounted() {
    this.isLoading = true
    await this.getUser()
    if (this.user) {
      await this.getUserFavorites()
      await this.getProductsFromElastic()
    }
    this.isLoading = false
  },
  methods: {
    async getUser() {
      if (!this.memberId) {
        return null
      }

      try {
        this.isLoadingUser = true

        const response = await this.$axios.get(`/Umbraco/Api/User/GetUserProfile?memberId=${this.memberId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        if (response.status === 200) {
          this.user = response.data
        }
      }
      catch(e) {
        console.log(e)
      }
      finally {
        this.isLoadingUser = false
      }
    },
    async getUserFavorites() {
      try {
        const response = await this.$axios.get(`/Umbraco/Api/Favorites/GetFavorites?memberid=${this.memberId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })
        this.favorites = response.data
      } catch(e) {
        console.log(e)
      }
    },
    async getProductsFromElastic() {
      const skus = this.favorites.map(x => {
        return x.sku
      })
      let filters = [
        {
          field: 'sku',
          value: skus || [],
          type: 'any'
        },
        {
          field: 'store',
          value: this.$root.getStore(),
          type: 'any'
        }
      ]

      const allFilters = [...filters || []].map(function (filter) {
        const isArrayValue = Array.isArray(filter.value)
        const values = isArrayValue ? filter.value : [filter.value]

        return {
          [filter.type]: {
            [filter.field]: values
          }
        }
      })
   
      const response = await this.$root.sendAppSearchRequest({
        filters: {
          all: allFilters
        },
        query: '',
        page: {
          size: 1000
        }
      })

      const cleanedProducts = getSummarizedProductsItemFacets(response.results, this.$root.getStore())
      this.promotionProductsFromFavorites = cleanedProducts.filter((x) => x.hasPromotion === true)
      this.regularProductsFromFavorites = cleanedProducts.filter((x) => x.hasPromotion === false)
    },
    removeFavorite(product) {
      if (this.promotionProductsFromFavorites.includes(product)) {
        this.promotionProductsFromFavorites.splice(this.promotionProductsFromFavorites.indexOf(product), 1)
        return
      }
     
      this.regularProductsFromFavorites.splice(this.regularProductsFromFavorites.indexOf(product), 1)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
