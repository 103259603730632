<template>
  <div class="orderConfirmationProducts">
    <div class="cartProducts">
      <div class="cartProductsHeader">
          <div class="label product">{{ $t('orderConfirmation.items') }}</div>
          <div class="label unitPrice">{{ $t('orderConfirmation.unitPrice') }}</div>
          <div class="label totalPrice">{{ $t('cart.totalPrice') }}</div>
        </div>
      <div class="cartProductsContent">
          <div class="cartProduct" v-for="product in products" v-bind:key="product.identifier">
            <div class="productInfosImg">
              <product-info
                :product="product"
                :quantity="product.quantity"
                :isAvailable="true"
                :isInactive="false"
                :isInStock="true"
                :showPrice="false"
              />
            </div>
            <div class="price regular">
              <label class="m">{{ $t('orderConfirmation.unitPrice') }}</label>
              <span>{{ product.price }}</span>
            </div>
            <div class="priceTotal">
              <label class="m">{{ $t('cart.totalPrice') }}</label>
              <span v-if="total !== null">{{ product.total }}</span>
              <span v-else>N/D</span>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ProductInfo from '@/components/Product/ProductInfo.vue';
import productStatus from '@/models/product/Status';

export default {
  components: {
    ProductInfo
  },
  props: {
    summary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hidden: true,
      productStatus
    };
  },
  computed: {
    products() {
      return this.summary.items.map(x => {
        return {
          identifier: x.productID,
          images: [{
            url: x.url,
            alt: this.$i18n.locale === 'fr' ? x.descriptionFr : x.descriptionEn
          }],
          name: this.$i18n.locale === 'fr' ? x.descriptionFr : x.descriptionEn,
          price: this.$root.formatPrice(x.unitPrice),
          quantity: x.quantity,
          sku: x.sku,
          total: this.$root.formatPrice(x.unitPrice * x.quantity)
        };
      });
    }
  },
  methods: {
    price(product) {
      return product.unitPrice;
    },
    total(product) {
      if (!this.price(product)) {
        return null;
      }

      const basePrice = this.price(product);
      return basePrice * product.quantity || 0;
    }
  }
};
</script>
