<template>
<div>
  <div v-if="isLoading" class="loading">
    <img src="/images/triangle-loading.gif" />
  </div>  
  <div v-else v-for="summary in summaries" class="orderconfirmationItem">
    <div class="left">
      <div class="summary">      
        <div v-if="summary">
          <h2>{{ $t('orderConfirmation.confirmed') }}</h2>
          <div class="row clientRow">
            <div class="col">
              <strong>{{ $t('orderConfirmation.client') }}</strong>
              <span>{{ summary.clientName }}</span>
            </div>
            <div class="col">
              <strong>{{ $t('orderConfirmation.orderNumber') }}</strong>
              <span>#{{ orderNumber(summary) }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h3>{{ $t(`orderConfirmation.shipping.${selectedShippingMethod(summary)}.title`) }}</h3>
            </div>
            <div class="col">
              {{ $t(`orderConfirmation.shipping.${selectedShippingMethod(summary)}.text`) }}
            </div>
          </div>
          <order-confirmation-products  v-if="summary" :summary="summary" />
        </div>
      </div>
    </div>
    
    <div v-if="summary" class="right">
      <div class="storeInfo">
        <h4>{{ $t('orderConfirmation.questions') }}</h4>
        <span>{{ $t('orderConfirmation.contactUs') }}</span>
        <b>{{ $t('cart.storeFormat', { store }) }}</b>
        <div class="row">
          <span><a class="mailLink" href="mailto:info@laferté.com">info@laferté.com</a></span>
          <span class="phoneLink">819 477-8950</span>
        </div>
      </div>
      <order-details
        :gst="summary.taxes.gst"
        :qst="summary.taxes.qst"
        :shipping="summary.shippingMethod === 0 ? summary.shippingAmount : null"
        :shippingExtra="summary.shipping.extraFee !== undefined && summary.shipping.extraFee > 0 ? summary.shipping.extraFee : null"
        :sub-total="summary.subTotal"
        :total="summary.total"
      >
        <template v-slot:legalnotices>
          <slot name="legalnotices" />
        </template>
      </order-details>
      <slot />
    </div>
    <hr v-if="summaries.length > 1" class="orderConfirmationSeparator">
  </div>
</div>
</template>

<script>
  import OrderConfirmationProducts from './OrderConfirmationProducts.vue';
  import OrderDetails from '@/components/Cart/OrderDetails';
  import ShippingMethod from '@/models/store/ShippingMethod';
  import Store from '@/models/store/Store';

  import { mapState } from 'vuex';

  export default {
    components: {
      OrderConfirmationProducts,
      OrderDetails
    },
    props: {
    },
    data() {
      return {
        gaOrderConfirmationLocalStorageKey: 'gaOrderConfirmationOrderIds',
        isLoading: true,
        shippingMethods: ShippingMethod,
        summaries: []
      };
    },
    computed: {
      ...mapState('cartModule', ['products']),
      orderIDs() {        
        return new URL(window.location.href).searchParams.get('orderid')
      },
      price(product) {
        return product.unitPrice;
      },
      total(product) {
        if (!this.price(product)) {
          return null;
        }

        const basePrice = this.price(product);
        return basePrice * product.quantity || 0;
      },
      store() {
        const store = this.$root.getStore();
        for (const [key, value] of Object.entries(Store)) {
          if (value === store) {
            return this.$i18n.t(`stores.${key}`);
          }
        }
        return null;
      }
    },
    async mounted() {
      await this.getOrderSummary();
      this.sendOrderSummaryGaEvent();
    },
    methods: {
      orderNumber(summary) {
        return String(summary.id).padStart(8, '0');
      },
      selectedShippingMethod(summary) {
        if (!summary) {
          return null;
        }

        return summary.shippingMethod === this.shippingMethods.Delivery ? 'delivery' : 'pickup';
      },
      async getOrderSummary() {
        if (!this.orderIDs) {
          return;
        }

        try {
          const response = await this.$axios.get(`/Umbraco/Api/OrderSummary/GetOrderSummaries?ids=${this.orderIDs}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json; charset=UTF-8'
            }
          });
          this.summaries = response.data
          this.isLoading = false;
        } catch(e) {
          this.isLoading = false;
        }
      },
      sendOrderSummaryGaEvent() {
        try {
          // Vérifie qu'on n'a pas déjà envoyé un événement Google Analytics d'achat pour ce OrderID
          let orderIds = (localStorage.getItem(this.gaOrderConfirmationLocalStorageKey) || '').split(';');
          for (const summary of this.summaries) {
            
            let eventAlreadySent = orderIds.includes(this.orderNumber(summary));

            if (dataLayer && !eventAlreadySent) {
              dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
              dataLayer.push({
                event: 'purchase',
                ecommerce: {
                  transaction_id: this.orderNumber(summary),
                  value: summary.total,
                  tax: summary.taxes.gst + summary.taxes.qst,
                  shipping: summary.shippingMethod === 0 ? summary.shippingAmount : 0,
                  currency: 'CAD',
                  items: summary.items.map((item, index) => {
                    return {
                      item_id: item.sku,
                      item_name: this.$i18n.locale === 'fr' ? item.descriptionFr : item.descriptionEn,
                      index: index,
                      price: item.unitPrice,
                      quantity: item.quantity
                    };
                  })
                }
              });
              
              localStorage.setItem(this.gaOrderConfirmationLocalStorageKey, [...orderIds, this.orderNumber(summary)].join(';'));
            }
          }
        } catch { /* Fait rien */ }
      }
    }
  };
</script>
