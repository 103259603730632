<template>
  <div class="productsExplorer">
    <div id="filter" class="left productsNavigation">
      <div class="wrapper">
        <div class="title">{{ $t('searchResults.filter') }}</div>
        <a id="closeFilter" class="close"></a>
        <div class="sorting">
          <dropdown-count :items-container.sync="itemsContainer"
                          :header="$t('searchResults.productsByPage')"
                          :values="[ 32, 64 ]"></dropdown-count>
          <dropdown-sort :items-container.sync="itemsContainer"
                          :header="$t('searchResults.filterBy')"
                          ascending-text="ascendant"
                          descending-text="descendant"
                          :values="{
                               1: { name: 'Nom', order: 2 },
                               2: { name: 'Prix', requireSelectedStore: true, order: 3 },
                               3: { name: 'Date', requireSelectedStore: true, order: 4 },
                               5: { name: 'Pertinence', order: 1 }
                            }"></dropdown-sort>
        </div>
        <multiple-selection-facet show-count
                        :hide-if-filter-count-less-than="1"
                        :items-container.sync="itemsContainer"
                        header="Départements"
                        facet-property="MajorCategory"
                        :all-text="$t('facets.allDepartments')"
                        request-facet-property="majorCategory"></multiple-selection-facet>
        <multiple-selection-facet show-count
                        :hide-if-filter-count-less-than="1"
                        :items-container.sync="itemsContainer"
                        header="Catégories"
                        facet-property="IntermediateCategory"
                        :all-text="$t('facets.allCategories')"
                         request-facet-property="intermediateCategory"></multiple-selection-facet>
        <label class="facetTitle">{{ $t('searchBar.title') }}</label>
        <search-filter :defaultValue="defaultSearchText" :items-container.sync="itemsContainer" facet-property="search"></search-filter>
        <div class="btnSection">
          <a id="btnClose" class="btn">{{ $t('searchResults.close') }}</a>
        </div>
      </div>
    </div>
    <div class="right">
      <slot />
    </div>
  </div>
</template>

<script>
import DropdownCount from '@/components/Facets/DropdownCount.vue';
import DropdownFacet from '@/components/Facets/DropdownFacet.vue';
import DropdownSort from '@/components/Facets/DropdownSort.vue';
import MultipleSelectionFacet from '@/components/Facets/MultipleSelectionFacet.vue';
import SearchFilter from '@/components/Search/SearchFilter.vue';
import StaticDropdownFacet from '@/components/Facets/StaticDropdownFacet.vue';
import { mapGetters } from 'vuex';

  export default {
    components: {
      DropdownCount,
      DropdownFacet,
      DropdownSort,
      SearchFilter,
      StaticDropdownFacet,
      MultipleSelectionFacet
    },
    props: {
      itemsContainer: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        allCategory: {
          displayName: this.$t('majorCategories.all'),
          key: 'all',
          value: null
        },
        defaultFacetKey: null,
        defaultSearchText: null,
        selectedMajorCategory: null
      };
    },
    created() {
      this.defaultSearchText = this.getFragmentVariable('q') || '';
      this.selectedMajorCategory = this.getFragmentVariable('category') || null;
  },
  computed: {
    ...mapGetters('searchModule', ['products', 'total']),
  },
  methods: {
    getFragmentVariable(variable) {
      const hash = location.hash.substring(1);
      const variables = hash.split('&');

        if (variables) {
          for (var i = 0; i < variables.length; i++) {
            const pair = variables[i].split('=');
            if (pair[0] === variable) {
              return decodeURIComponent(pair[1]);
            }
          }
        }

        return null;
      }
    }
  };
</script>
