var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cartProducts" }, [
    _c("div", { staticClass: "wrapper" }, [
      _c("p", [
        _vm._v(_vm._s(_vm.$t("cart.orderAtTheStore")) + " "),
        _c("b", [_vm._v(_vm._s(_vm.$root.selectedStoreName))]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "changeStoreLink",
            attrs: { onclick: "openSelector()" },
          },
          [_vm._v(_vm._s(_vm.$t("changeStore")))]
        ),
      ]),
      _vm.hasRegularAndBarcodeItems
        ? _c("div", { staticClass: "billSplitMentionning" }, [
            _c("img", { attrs: { src: "/images/info-icon.png" } }),
            _c("p", { staticClass: "firstLine" }, [
              _vm._v(_vm._s(_vm.$t("product.billSplitMention"))),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "cartProductsHeader" }, [
        _c("div", { staticClass: "label product" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "10",
                height: "11",
                viewBox: "0 0 10 11",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                  fill: "#E31F28",
                },
              }),
            ]
          ),
          _vm._v(
            " " +
              _vm._s(_vm.$t("cart.product")) +
              " (" +
              _vm._s(_vm.count) +
              ") "
          ),
        ]),
        _c("div", { staticClass: "label unityPrice" }, [
          _vm._v(_vm._s(_vm.$t("cart.priceUnity"))),
        ]),
        _c("div", { staticClass: "label quantity" }, [
          _vm._v(_vm._s(_vm.$t("cart.quantity"))),
        ]),
        _c("div", { staticClass: "label totalPrice" }, [
          _vm._v(_vm._s(_vm.$t("cart.price"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "cartProductsContent" },
        _vm._l(_vm.products, function (product) {
          return _c(
            "cart-product",
            {
              key: product.Identifier,
              attrs: {
                "disable-cart-product": _vm.disableCartProduct,
                getProductQuantity: _vm.getProductQuantity,
                product: product,
              },
              on: {
                deleteProduct: _vm.deleteProduct,
                quantityChanged: _vm.onQuantityChanged,
              },
            },
            [_vm._t("default", null, { product: product })],
            2
          )
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }