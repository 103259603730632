var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "account-userInfo infoPanel" },
    [
      _c("div", { staticClass: "infoPanel-title" }, [
        _c("h3", { staticClass: "infoPanel-heading" }, [
          _vm._v(_vm._s(_vm.$t("users.titles.userInfo"))),
        ]),
      ]),
      _c("div", { staticClass: "infoPanel-data" }, [
        _c("address", [
          _vm._v(" " + _vm._s(_vm.getUserFullName)),
          _c("br"),
          _vm.userContact.phone
            ? _c("div", [_vm._v(_vm._s(_vm.formattedPhoneNumber))])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "infoPanel-actions" }, [
        _c(
          "button",
          {
            staticClass: "infoPanel-action btnText",
            attrs: { type: "button" },
            on: { click: _vm.handleShowEditUserInfoDialog },
          },
          [_vm._v(_vm._s(_vm.$t("users.actions.edit")))]
        ),
      ]),
      _c(
        "teleport",
        { attrs: { to: "body" } },
        [
          _c(
            "dialog-modal",
            {
              attrs: {
                isOpen: _vm.editUserInfoDialogIsOpen,
                title: _vm.$t("users.titles.userInfoEdition"),
              },
              on: { "close:dialog": _vm.handleCloseEditUserInfoDialog },
            },
            [
              _c("edit-user-info", {
                ref: "editUserInfoForm",
                attrs: { userContact: _vm.userContact },
                on: { "success:updateContact": _vm.handleUpdateContactSuccess },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }