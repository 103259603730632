var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selectedStore" }, [
    _c("div", { staticClass: "ctaBranches", attrs: { id: "branchesToggle" } }, [
      _vm.$root.getStore() == null
        ? _c("a", { attrs: { onclick: "openSelector()" } }, [
            _vm._v(" " + _vm._s(_vm.noStoreSelectedText) + " "),
            _c(
              "svg",
              {
                attrs: {
                  width: "8",
                  height: "9",
                  viewBox: "0 0 8 9",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z",
                    fill: "#E02028",
                  },
                }),
              ]
            ),
          ])
        : _c(
            "a",
            {
              staticClass: "selectedStore",
              attrs: { onclick: "openSelector()" },
            },
            [
              _c("span", [
                _c("span", { staticClass: "selectedStoreName" }, [
                  _vm._v(" " + _vm._s(_vm.selectedStoreName) + " "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "8",
                        height: "9",
                        viewBox: "0 0 8 9",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z",
                          fill: "#E02028",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm.selectedStoreInformation.hours
                  ? _c("span", { staticClass: "businessHours" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("todayBusinessHours", {
                            hours: _vm.selectedStoreInformation.hours,
                          })
                        )
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }