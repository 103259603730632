var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "div",
        { staticClass: "login-emailLogin" },
        [
          _c(
            "validation-observer",
            {
              ref: "loginForm",
              staticClass: "login-form accountForm",
              attrs: { tag: "form", novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("validation-provider", {
                attrs: {
                  name: "email",
                  rules: "required|email|max:255",
                  slim: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("div", { staticClass: "formItem" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("users.fields.email"))),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email",
                              },
                            ],
                            class: { invalid: errors.length },
                            attrs: { type: "email", autocomplete: "email" },
                            domProps: { value: _vm.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.email = $event.target.value
                              },
                            },
                          }),
                          errors.length
                            ? _c(
                                "div",
                                { staticClass: "formItem-errorMessage error" },
                                [_vm._v(_vm._s(errors[0]))]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("validation-provider", {
                attrs: { name: "password", rules: "required", slim: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "formItem" },
                          [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("users.fields.password"))),
                            ]),
                            _c("password-container", {
                              attrs: { errors: errors },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            errors.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "formItem-errorMessage error",
                                  },
                                  [_vm._v(_vm._s(errors[0]))]
                                )
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                staticClass: "btnText resetPassword",
                                attrs: { type: "button" },
                                on: { click: _vm.handleResetPasswordClick },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("users.links.forgotPassword"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "formItem" },
                [
                  _c("working-button", {
                    staticClass: "btn",
                    attrs: {
                      type: "submit",
                      isWorking: _vm.isAuthenticating,
                      label: _vm.submitLabel,
                    },
                  }),
                  !!_vm.loginErrorMessage
                    ? _c(
                        "div",
                        {
                          staticClass: "error",
                          attrs: { "aria-live": "polite" },
                        },
                        [_vm._v(_vm._s(_vm.loginErrorMessage))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "login-socialLogins" }, [_vm._t("default")], 2),
      _c(
        "teleport",
        { attrs: { to: "body" } },
        [
          _c(
            "dialog-modal",
            {
              staticClass: "wrapper_modal",
              attrs: {
                isOpen: _vm.resetDialogIsOpen,
                title: _vm.$t("dialogs.titles.resetPassword"),
              },
              on: { "close:dialog": _vm.handleCloseResetDialog },
            },
            [
              !_vm.passwordResetCodeIsValidated
                ? _c("send-password-reset-code", {
                    ref: "sendPasswordResetCodeComponent",
                    on: {
                      "success:validateCode":
                        _vm.handleValidateResetCodeSuccess,
                    },
                  })
                : _c("reset-password", {
                    attrs: {
                      "validated-email": _vm.validatedEmail,
                      "validated-activation-code": _vm.validatedActivationCode,
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }