var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addCartBtn", class: { disabled: _vm.disabled } },
    [
      _c(
        "a",
        {
          staticClass: "btn addCart",
          class: { disabled: _vm.disabled, productAdded: _vm.productAdded },
          on: { click: _vm.handleClick },
        },
        [
          _vm._v(
            _vm._s(
              !_vm.productAdded
                ? _vm.$root.getFeatureFlag("ecommerce")
                  ? _vm.$t("addToCart")
                  : _vm.$t("addToMyList")
                : _vm.$t("productAdded")
            )
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }