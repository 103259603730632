<template>
  <div class="paymentInfos">
    <div class="paymentInfos-upperSection">
      <div class="paymentInfos-cardDigits">
        <img v-if="receipt.creditCardCompany === 'VI'" src="@/assets/svg/Visa-logo.svg" class="vi-card-logo">
        <img v-else-if="receipt.creditCardCompany === 'MC'" src="@/assets/svg/MasterCard-logo.svg" class="mc-card-logo">   
        {{ $t('order.payment.endsWith') }} {{ receipt.creditCardLastDigits }}</div>
      <div class="paymentInfos-exp" v-if="!isCardExpired">{{ $t('paymentInformations.expirationLabel') }} {{ receipt.bamboraCardExpiration }}</div>
      <div v-else>{{ $t('paymentInformations.expiredCard') }} {{ receipt.bamboraCardExpiration }}</div>
    </div>
    <div class="paymentInfos-lowerSection">
      <div class="paymentInfos-addressLabel">{{ $t('paymentInformations.billingAddressLabel') }}</div>
      <div class="paymentInfos-address">{{ billingAddress.address }} {{ billingAddress.city }} {{ billingAddress.postalCode }}</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    billingAddress : {
      type: Object,
      required: true
    },
    receipt: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCardExpired() {
      return this.$root.isCardExpired(this.receipt.bamboraCardExpiration)
    }
  }
};
</script>
