var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accountForm-container" },
    [
      _c(
        "validation-observer",
        {
          ref: "sendCodeForm",
          staticClass: "accountForm sendCodeForm",
          attrs: { tag: "form", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.sendActivationCode.apply(null, arguments)
            },
          },
        },
        [
          _c("validation-provider", {
            ref: "resetPasswordEmailRef",
            staticClass: "formItem",
            attrs: {
              tag: "div",
              name: "email",
              rules: "required|email|max:255",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [_vm._v(_vm._s(_vm.$t("users.fields.email")))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.resetPasswordEmail,
                          expression: "resetPasswordEmail",
                        },
                      ],
                      class: { invalid: errors.length },
                      attrs: {
                        type: "email",
                        autocomplete: "email",
                        maxlength: "255",
                      },
                      domProps: { value: _vm.resetPasswordEmail },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.resetPasswordEmail = $event.target.value
                        },
                      },
                    }),
                    errors.length
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(_vm._s(errors[0])),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "formItem" },
            [
              _c("working-button", {
                staticClass: "btn",
                attrs: {
                  isWorking: _vm.isSendingActivationCode,
                  label: _vm.sendCodeLabel,
                  type: "submit",
                },
              }),
              _vm.isActivationCodeSent
                ? _c("div", { staticClass: "actionResult" }, [
                    _vm._v(_vm._s(_vm.$t("users.messages.activationCodeSent"))),
                  ])
                : _vm._e(),
              _vm.sendCodeError
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(_vm._s(_vm.sendCodeError)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "validation-observer",
        {
          ref: "validateCodeForm",
          staticClass: "accountForm validateCodeForm",
          attrs: { tag: "form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.validateActivationCode.apply(null, arguments)
            },
          },
        },
        [
          _c("validation-provider", {
            staticClass: "formItem",
            attrs: {
              tag: "div",
              name: "resetPasswordEmail",
              rules: "required|max:6",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("users.fields.activationCode"))),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.resetPasswordActivationCode,
                          expression: "resetPasswordActivationCode",
                        },
                      ],
                      class: { invalid: errors.length },
                      attrs: { type: "text" },
                      domProps: { value: _vm.resetPasswordActivationCode },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.resetPasswordActivationCode = $event.target.value
                        },
                      },
                    }),
                    errors.length
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(_vm._s(errors[0])),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "formItem" },
            [
              _c("working-button", {
                staticClass: "btn",
                attrs: {
                  isWorking: _vm.isValidatingCode,
                  label: _vm.validateCodeLabel,
                  type: "submit",
                },
              }),
              _vm.validateCodeErrorType
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`users.messages.${_vm.validateCodeErrorType}`)
                      )
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }