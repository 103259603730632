<template>
  <div class="cartProductsContent">
    <div v-if="count <= 0" class="hasNotProducts">
      <div class="wrapper">
        <div class="content">
          <h4>{{ $root.getFeatureFlag('ecommerce') ? $t('cart.empty') : $t('cart.emptyList') }}</h4>
        </div>
      </div>
    </div>
    <div v-else>
      <slot name="cta-cart" />
      <div class="productList">
        <div>
          <div v-if="isLoading" class="loading">
            <img src="/images/triangle-loading.gif" />
          </div>
          <div v-else>
            <div v-if="products.some(x => x.isBarcodeItem) && products.some(x => !x.isBarcodeItem)" class="billSplitMentionning">
              <img src="/images/info-icon.png">
              <p class="firstLine">{{ $t('product.billSplitMention') }}</p>
            </div>
            <cart-product
              v-for="product in products"
              :disable-cart-product="isUnavailable"
              :getProductQuantity="getProductQuantity"
              :key="product.Identifier"
              :product="product"
              @deleteProduct="deleteProduct"
              @quantityChanged="onQuantityChanged"
            >
              <template v-slot="props" name="product">
                <div class="productInfosStore">
                  <div v-if="isBarcodeItem(props.product)" class="inStock">
                    {{ $t("product.availability.inStock") }}
                  </div>
                  <div v-else-if="!isUnavailable(props.product)" class="inStock">
                    {{
                      $t("product.availability.inStockWithValue", {
                        value: getQuantity(props.product).value
                      })
                    }}
                  </div>
                  <div v-else-if="!isInactive(props.product) && !isInStock(props.product)" class="notInStock">
                    {{ $t("product.availability.notInStock") }}
                  </div>
                  <div v-else-if="isPromotional(props.product)" class="unavailable">
                    {{ $t("product.availability.promotional") }}
                  </div>
                  <div v-else-if="isRecall(props.product)" class="unavailable">
                    {{ $t("product.availability.recall") }}
                  </div>
                  <div v-else class="unavailable">
                    {{ $t("product.availability.unavailable") }}
                  </div>
                </div>
              </template>
            </cart-product>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import orderMixins from '@/orderMixins';
import productMixins from '@/productMixins';
import CartProduct from '@/components/Cart/CartProduct.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    CartProduct
  },
  data() {
    return {
      isLoading: false,
      hasProductsBeenRemovedFromCart: false
    }
  },
  mixins: [orderMixins, productMixins],
  computed: {
    ...mapGetters('cartModule', ['cart', 'products', 'count'])
  },
  async mounted() {
    const productIds = Object.keys(this.cart);
    this.isLoading = true
    await this.$store.dispatch('cartModule/setProducts', productIds);
    for (const product of productIds) {
      if (!this.products.some(x => x.identifier.includes(product))) {
        this.deleteProduct(product);
        this.hasProductsBeenRemovedFromCart = true;
      }
    }
    if (this.hasProductsBeenRemovedFromCart === true) {
      this.$tostini({ message: this.$t('product.availability.removedFromCart'), duration: 5000 });
      this.hasProductsBeenRemovedFromCart = false;
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions('cartModule', ['removeProduct']),
    deleteProduct(productId) {
      this.removeProduct(productId);
    },
    getProductQuantity(product) {
      return this.isUnavailable(product) ? 0 : this.cart[product.identifier];
    },
    async onQuantityChanged(value, productId) {
      await this.$store.dispatch('cartModule/setQuantity', {
        productId: productId,
        quantity: value
      });
    }
  },
  watch: {
    cart: async function(value, oldValue) {
      const productIds = Object.keys(value);
      const oldProductIds = Object.keys(oldValue);

      if (JSON.stringify(productIds) !== JSON.stringify(oldProductIds)) {
        this.isLoading = true
        await this.$store.dispatch('cartModule/setProducts', productIds);
        this.isLoading = false
      }
    }
  }
};
</script>
