<template>
  <div class="priceNpromo" v-if="productContext.showPrices">
    <div class="priceSection">
      <div class="regularSection" v-if="isPromotionalProduct">
        <div class="price">
          {{ $t("product.availability.promotional") }}
        </div>
      </div>
      <div class="regularSection" v-else-if="!productContext.hasDiscountedPrice">
        <div class="price" v-if="productContext.basePrice !== null">
          {{ $root.getPriceDollars(productContext.basePrice) }},{{
              $root.getPriceCents(productContext.basePrice)
          }}
        </div>
      </div>
      <div class="saleSection" v-else>
        <div class="salePrice" v-if="productContext.discountedPrice">
          {{ $root.getPriceDollars(productContext.discountedPrice) }},{{
              $root.getPriceCents(productContext.discountedPrice)
          }}
        </div>
        <div class="currentPrice" v-if="productContext.basePrice">
          {{ $root.formatPrice(productContext.basePrice) }}
        </div>
        <promotion-end-date :product="productContext.product"></promotion-end-date>
      </div>
    </div>
  </div>
  <div v-else-if="productContext.isPriceInStoreOnly">
    {{ $t("product.availability.priceInStoreOnly") }}
  </div>
</template>
<script>
import PromotionEndDate from '@/components/Product/PromotionEndDate.vue';
import { mapGetters } from 'vuex';
import internalStatus from '@/models/product/InternalStatus';

export default {
  components: { PromotionEndDate },
  data() {
    return {
      internalStatus
    };
  },
  props: {
    productContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('productModule', ['product']),
    getCurrentInternalStatus() {
      if (this.product && this.product.internalStatuses !== null) {
        return this.product.internalStatuses.find(
          x => x.store === this.$root.getStore()
        ).value;
      }

      return null;
    },
    isPromotionalProduct() {
      return this.getCurrentInternalStatus === this.internalStatus.Promotional;
    }
  }
};
</script>
