var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasDefaultSlot
    ? _c(
        "label",
        {
          class: _vm.classes + " fakeCheckbox",
          attrs: { disabled: _vm.disabled },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked",
              },
            ],
            ref: "checkbox",
            attrs: { type: "checkbox" },
            domProps: {
              value: _vm.checkedValue,
              checked: Array.isArray(_vm.checked)
                ? _vm._i(_vm.checked, _vm.checkedValue) > -1
                : _vm.checked,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
              change: [
                function ($event) {
                  var $$a = _vm.checked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.checkedValue,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.checked = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.checked = $$c
                  }
                },
                _vm.onChange,
              ],
            },
          }),
          _c("span", { staticClass: "icon" }),
          _c("span", { staticClass: "label" }, [_vm._t("default")], 2),
        ]
      )
    : _c(
        "label",
        {
          class: _vm.classes + " fakeCheckbox noMargin",
          attrs: { disabled: _vm.disabled },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked",
              },
            ],
            ref: "checkbox",
            attrs: { type: "checkbox" },
            domProps: {
              value: _vm.checkedValue,
              checked: Array.isArray(_vm.checked)
                ? _vm._i(_vm.checked, _vm.checkedValue) > -1
                : _vm.checked,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
              change: [
                function ($event) {
                  var $$a = _vm.checked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.checkedValue,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.checked = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.checked = $$c
                  }
                },
                _vm.onChange,
              ],
            },
          }),
          _c("span", { staticClass: "icon" }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }