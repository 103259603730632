const regex = /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*\d)(?=.*[\W])[\p{L}\d\W]{10,}$/u

export default {
  getMessage() {
    return 'Invalid password'
  },
  validate(value) {
    if (value === null) {
      return true
    }
    return regex.test(value)
  }
}