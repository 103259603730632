var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdownStyle" }, [
    _c(
      "select",
      _vm._b(
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedOption,
              expression: "selectedOption",
            },
          ],
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedOption = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        "select",
        _vm.$attrs,
        false
      ),
      _vm._l(_vm.options, function (option) {
        return _c(
          "option",
          { key: option.label, domProps: { value: option.value } },
          [_vm._v(_vm._s(option.label))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }