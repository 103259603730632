var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "overlay",
      staticClass: "overlay open",
      on: { click: _vm.clickOutside },
    },
    [
      _c("div", { class: _vm.isPopin ? "popin" : "modal" }, [
        _c("div", { staticClass: "modalHead" }, [
          _c("div", [_vm._t("title")], 2),
          _c("button", {
            class: _vm.isPopin ? "closePopin" : "closeModal",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          }),
        ]),
        _c("div", { staticClass: "modalBody" }, [_vm._t("body")], 2),
        _c("div", { staticClass: "modalFooter" }, [_vm._t("actions")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }