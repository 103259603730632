<template>
  <section class="account-shippingInfo infoPanel">
    <div class="infoPanel-title">
      <h3 class="infoPanel-heading">{{ $t('users.titles.shippingInfo') }}</h3>
    </div>

    <div class="infoPanel-data">
      <shipping-address-summary v-if="shippingAddress" :address="shippingAddress"></shipping-address-summary>
      <template v-else>
        <span>{{ $t("users.accountText.noShippingAddress") }}</span>
        <button class="infoPanel-action btnText" type="button" @click="handleShowEditShippingInfoDialog">{{ $t("users.actions.addShippingAddress") }}</button>
      </template>
    </div>

    <div class="infoPanel-actions">
      <button v-if="shippingAddress" type="button" class="infoPanel-action btnText" @click="handleShowEditShippingInfoDialog">{{ $t('users.actions.edit') }}</button>
    </div>

    <teleport to='body'>
      <dialog-modal :isOpen="editShippingInfoDialogIsOpen" @close:dialog="handleCloseEditShippingInfoDialog" :title="$t('users.titles.shippingInfoEdition')">
        <edit-shipping-info :user="user" :addressInfo="shippingAddress" ref="editShippingInfoForm" @success:updateShippingInfo="handleUpdateShippingInfoSuccess"></edit-shipping-info>
      </dialog-modal>
    </teleport>
  </section>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import Teleport from 'vue2-teleport'
import EditShippingInfo from '../Forms/EditShippingInfo.vue'
import BranchAddress from './BranchAddress.vue'
import ShippingAddressSummary from './ShippingAddressSummary.vue'

export default {
  components: {
    DialogModal,
    Teleport,
    EditShippingInfo,
    BranchAddress,
    ShippingAddressSummary
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shippingAddress: this.getDefaultShippingAddress(),
      editShippingInfoDialogIsOpen: false
    }
  },
  methods: {
    getDefaultShippingAddress() {
      if(!this.user.defaultShippingAddressID) {
        return undefined
      }

      return structuredClone(this.user.addresses.find(address => address.addressID === this.user.defaultShippingAddressID))
    },
    handleCloseEditShippingInfoDialog() {
      this.editShippingInfoDialogIsOpen = false
      this.$refs.editShippingInfoForm.resetForm()
    },
    handleShowEditShippingInfoDialog() {
      this.editShippingInfoDialogIsOpen = true
    },
    handleUpdateShippingInfoSuccess(newAddress) {
      this.shippingAddress = {...newAddress}
    }
  },
  computed: {
    
  }
}
</script>