<template>
  <div v-if="user" class="orderCreditCardSelection">
    <div v-if="payment != null && payment.bamboraCardID > 0">
      <div class="paymentInfos">
        <div class="paymentInfos-cardDigits">
          <img v-if="payment.creditCardType === 'VI'" src="@/assets/svg/Visa-logo.svg" class="vi-card-logo">
          <img v-else-if="payment.creditCardType === 'MC'" src="@/assets/svg/MasterCard-logo.svg" class="mc-card-logo">
          <strong>{{ $t('order.payment.endsWith') }} {{ payment.creditCardLastDigits }}</strong>
        </div>
        <div class="expired" v-if="isCardExpired">{{ $t('paymentInformations.expiredCard') }} {{ payment.creditCardExpiration }}</div>
      </div>
      <button class="btnText infoPanel-action" type="button" @click="handleEditPayment">{{ $t("users.actions.edit") }}</button>
    </div>
    <div v-if="payment != null && payment.bamboraCardID > 0">
      <button type="button" class="btn" :disabled="isProcessingOrder || isCardExpired" @click="$emit('process')">{{ $t('order.payment.submit') }}</button>
    </div>
    <div v-else>
      <span>{{ $t('users.accountText.noPaymentMethods') }}</span>
      <button class="btnText infoPanel-action" type="button" @click="handleEditPayment">{{ $t("users.actions.addAPaymentMethod") }}</button>
      <span>{{ $t('users.accountText.or') }}</span>
      <button class="btnText infoPanel-action" type="button" @click="handleUniquePayment">{{ $t("users.actions.uniquePayment") }}</button>
    </div>
    <teleport to='body'>
      <dialog-modal :isOpen="editOrderPaymentMethodDialogIsOpen" @close:dialog="handleCloseOrderPaymentMethodDialog" :title="$t('users.titles.editPaymentMethod')">
        <edit-order-payment-method
          v-if="editOrderPaymentMethodDialogIsOpen"
          :bambora-configuration="bamboraConfiguration"
          :payment="payment"
          :user="user"
          @close="handleCloseOrderPaymentMethodDialog"
          @success:updatePaymentInfo="handleUpdatePaymentMethodsSuccess"
          @success:deletePayment="handleDeletePaymentSuccess">
        </edit-order-payment-method>
      </dialog-modal>
    </teleport>
  </div>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import EditOrderPaymentMethod from '../Forms/EditOrderPaymentMethod.vue'
import Teleport from 'vue2-teleport'

export default {
  components: {
    DialogModal,
    EditOrderPaymentMethod,
    Teleport,
  },
  props: {
    bamboraConfiguration: {
      type: Object,
      required: true
    },
    isProcessingOrder: {
      type: Boolean,
      required: true
    },
    payment: {
      type: Object,
      default: null
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      editOrderPaymentMethodDialogIsOpen: false
    }
  },
  computed: {
    isCardExpired() {
      return this.$root.isCardExpired(this.payment.creditCardExpiration)
    }
  },
  methods: {
    handleCloseOrderPaymentMethodDialog() {
      this.editOrderPaymentMethodDialogIsOpen = false;
    },
    handleEditPayment() {
      this.editOrderPaymentMethodDialogIsOpen = true;
    },
    handleUniquePayment() {
      this.$emit('uniquePayment')
    },
    handleUpdatePaymentMethodsSuccess(payment) {
      this.handleCloseOrderPaymentMethodDialog();
      this.$emit('success:updatePaymentInfo', payment);
    },
    handleDeletePaymentSuccess() {
      this.handleCloseOrderPaymentMethodDialog();
      this.$emit('success:deletePayment');
    }
  }
};
</script>
