<template>
  <div :class="{ cartProduct: true, isCartProductDisabled }">
    <div class="productInfosImg">
      <div class="img">
        <a :href="product.url">
          <img v-if="product.images && product.images.length"
               onerror="this.src='/images/image-default.png';"
               :src="product.images[0].url != null ? product.images[0].url : '/images/image-default.png'"
               :alt="product.images[0].alt"
               loading="lazy" />
          <img v-else
               src="/images/image-default.png"
               alt="Image par d�faut"
               loading="lazy" />
        </a>
      </div>
      <div class="productInfos">
        <div class="name"><a :href="url">{{ product.name }}</a></div>

        <slot :product="product" />
        <div v-if="!deleting">
          <a class="delete" @click="handleDelete">{{ $t('actions.remove') }}</a>
        </div>

      </div>
    </div>
    <div class="unityPrice">
      <template v-if="!this.$root.getFeatureFlag('hidePrices')">
        <div v-if="price !== null && price.discounted" class="price discounted">
          <div class="wDiscount">{{ $root.formatPrice(price.discounted) }}</div>
          <div class="original">{{ $root.formatPrice(price.value) }}</div>
        </div>
        <div v-else-if="price !== null" class="price regular">
          <span>{{ $root.formatPrice(price.value) }}</span>
        </div>
        <div v-else class="price regular">
          <span>N/D</span>
        </div>
      </template>
    </div>
    <div class="quantityContent">
      <div v-if="!deleting">
        <quantity-selector :disabled="isCartProductDisabled" v-model="quantity" :maximum-quantity="maximumQuantity" />
      </div>
    </div>
    <div class="priceTotal">
      <template v-if="!this.$root.getFeatureFlag('hidePrices')">
        <span v-if="total !== null">{{ $root.formatPrice(total) }}</span>
        <span v-else>N/D</span>
      </template>
    </div>
  </div>
</template>

<script>
  import productMixins from '@/productMixins';
  import QuantitySelector from '@/components/Product/QuantitySelector.vue';

  export default {
    components: {
      QuantitySelector
    },
    props: {
      disableCartProduct: {
        type: Function,
        required: true
      },
      product: {
        type: Object,
        required: true
      },
      getProductQuantity: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        deleting: false
      };
    },
    methods: {
      handleDelete() {
        this.sendRemoveFromCartGaEvent();
        this.$emit('deleteProduct', this.product.identifier);
      },
      sendRemoveFromCartGaEvent() {
        try {
          if (dataLayer) {
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
              event: 'remove_from_cart',
              ecommerce: {
              items: [
                  {
                    item_id: this.product.sku,
                    item_name: this.product.name,
                    item_brand: this.product.brand,
                    item_category: this.product.majorCategory,
                    item_category2: this.product.intermediateCategory,
                    item_category3: this.product.minorCategory
                  }
                ]
              }
            });
          }
        } catch { /*Fait rien*/ }
      }
    },
    computed: {
      isCartProductDisabled() {
        return this.disableCartProduct(this.product);
      },
      maximumQuantity() {
        if (!this.product) {
          return null;
        }

        const store = this.$root.getStore();
        const quantity = this.product.quantities.find(x => x.store === store);
        //2147483647 = plus gros int signed pour pas géré la quantité pour produits a barrcode
        return this.isBarcodeItem(this.product) ? 2147483647 : quantity !== null && quantity !== undefined ? quantity.value : null;
      },
      price() {
        if (!this.product.price) {
          return { value: 0 };
        }

        return this.product.price;
      },
      quantity: {
        get() {
          return this.getProductQuantity(this.product);
        },
        set(value) {
          this.$emit('quantityChanged', value, this.product.identifier);
        }
      },
      total() {
        if (!this.price) {
          return null;
        }

        const basePrice = this.price.discounted !== null ? this.price.discounted : this.price.value;
        return basePrice * this.quantity || 0;
      },
      url() {
        return `/${process.env.VUE_APP_PRODUCTS_URL_PREFIX}${this.product.url}`;
      }
    },
    mixins: [productMixins]
  };
</script>
