var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "passwordContainer" }, [
    (_vm.passwordIsVisible ? "text" : "password") === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          class: { invalid: _vm.errors.length },
          attrs: { required: _vm.required, type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.password)
              ? _vm._i(_vm.password, null) > -1
              : _vm.password,
          },
          on: {
            blur: function ($event) {
              return _vm.$emit("blur")
            },
            change: function ($event) {
              var $$a = _vm.password,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.password = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.password = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.password = $$c
              }
            },
          },
        })
      : (_vm.passwordIsVisible ? "text" : "password") === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          class: { invalid: _vm.errors.length },
          attrs: { required: _vm.required, type: "radio" },
          domProps: { checked: _vm._q(_vm.password, null) },
          on: {
            blur: function ($event) {
              return _vm.$emit("blur")
            },
            change: function ($event) {
              _vm.password = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          class: { invalid: _vm.errors.length },
          attrs: {
            required: _vm.required,
            type: _vm.passwordIsVisible ? "text" : "password",
          },
          domProps: { value: _vm.password },
          on: {
            blur: function ($event) {
              return _vm.$emit("blur")
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.password = $event.target.value
            },
          },
        }),
    _c("button", { staticClass: "showPassword", attrs: { type: "button" } }, [
      _c("img", {
        staticClass: "password-eye",
        attrs: {
          src: require(`@/assets/svg/${
            _vm.passwordIsVisible ? "eye-slash" : "eye"
          }.svg`),
        },
        on: {
          click: function ($event) {
            return _vm.toggleShowPassword()
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }