<template>
  <article class="orderSummary">
    <div class="date-gridItem">
      <a :href="orderDetailsUrl" class="orderSummary-dateLink cardLink"><span>{{ formatDate(summary.created) }}</span></a>
    </div>

    <div class="total-gridItem">
      <span class="totalText">{{ $t('account.summary.total') }} {{ getOrderTotal }} <span class="numArticle">({{ summary.products.length }}&nbsp;{{ $tc('account.summary.item', summary.products.length) }})</span></span>
    </div>

    <div class="products-gridItem">
      <div class="orderSummary-productListContainer" ref="productListContainer">
        <ul class="orderSummary-productList strippedList">
          <li v-for="(product, index) in displayedProducts" :key="index" class="orderSummary-productItem" >
            <img class="orderSummary-productImage" :src="product.url" @error="setFallbackProductImage">
          </li>
          <li v-if="hasOverflowingDisplayedProducts" class="orderSummary-productItem">
            <span>+{{ overflowDisplayedProductCount }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="status-gridItem">
      <span>{{ getOrderStatus }}</span>
    </div>

    <div class="icon-gridItem">
      <angle-right class="chevron"></angle-right>
    </div>
  </article>
</template>

<script>
import shippingMethods from '@/models/store/ShippingMethod'
import AngleRight from '../Svg/AngleRight.vue'

export default {
  components: { AngleRight },
  props: {
    summary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      maxDisplayedProducts: 5
    }
  },
  inject: ['fallbackProductImageUrl'],
  mounted() {
    this.observeProductListResize()
  },
  computed: {
    getOrderStatus() {
      return this.summary.shipping.shippingMethod === shippingMethods.Pickup 
        ? this.$t('account.summary.pickup') 
        : this.isDelivered 
          ? this.$t('account.summary.deliveredStatus') 
          : this.$t('account.summary.processingStatus')
    },
    getOrderTotal() {
      const deliveryPrice = this.summary.shipping.price != undefined ? this.summary.shipping.price : 0
      const deliveryExtraPrice = this.summary.shipping.extraFee != undefined ? this.summary.shipping.extraFee : 0
      const rawTotal = this.summary.products.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.unitPrice * currentValue.quantity
      }, 0) + this.summary.taxes.gst + this.summary.taxes.qst + deliveryPrice + deliveryExtraPrice

      const currencyFormatOptions = { 
        style: 'currency',
        currency: 'CAD',
        currencyDisplay: 'narrowSymbol'
      }

      const formattedTotal = new Intl.NumberFormat(this.$i18n.locale, currencyFormatOptions)
        .format(rawTotal)

      return formattedTotal
    },
    displayedProducts() {
      return this.summary.products.slice(0, this.maxDisplayedProducts)
    },
    hasOverflowingDisplayedProducts() {
      return this.overflowDisplayedProductCount > 0
    },
    isDelivered() {
      return new Date(this.summary.shipping.deliveryDate) <= new Date()
    },    
    orderDetailsUrl() {
      return `/zone-securisee/detail-dachat?orderid=${this.summary.guid}`
    },
    overflowDisplayedProductCount() {
      return this.summary.products.length - this.maxDisplayedProducts
    }
  },
  methods: {
    formatDate(date) {
      const dateObj =  new Date(date)
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }

      return dateObj.toLocaleDateString(this.$i18n.locale, options)
    },
    navigateToPurchaseDetails() {
      location.href = `/zone-securisee/detail-dachat?orderid=${this.summary.guid}`
    },
    observeProductListResize() {
      const observer = new ResizeObserver((entries) => {
        for(const entry of entries) {
          if(entry.contentRect.width >= 340) {
            this.maxDisplayedProducts = 5
            return
          }

          if(entry.contentRect.width < 282) {
            this.maxDisplayedProducts = 3
            return
          }

          if(entry.contentRect.width < 340) {
            this.maxDisplayedProducts = 4
            return
          }
        }
      })

      observer.observe(this.$refs.productListContainer)
    },
    setFallbackProductImage(event) {
      event.target.src = this.fallbackProductImageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
