var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.orderStep >= _vm.OrderStep.Payment
    ? _c(
        "div",
        { staticClass: "orderPayment box", attrs: { id: "orderPayment" } },
        [
          _c("div", { staticClass: "orderTitle" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "10",
                  height: "11",
                  viewBox: "0 0 10 11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                    fill: "#E31F28",
                  },
                }),
              ]
            ),
            _vm._v(" " + _vm._s(_vm.$t("order.payment.title")) + " "),
          ]),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              !_vm.isAccountPaymentAllowed ||
              _vm.user == null ||
              _vm.uniquePayment === true
                ? _c("order-credit-card", {
                    attrs: {
                      addressCompleteSettings: _vm.addressCompleteSettings,
                      recaptchaEnabled: false,
                      recaptchaSiteKey: _vm.recaptchaSiteKey,
                    },
                    on: { submit: _vm.bamboraSubmit },
                  })
                : _c("order-credit-card-selection", {
                    attrs: {
                      "bambora-configuration": _vm.bamboraConfiguration,
                      "is-processing-order": _vm.isProcessingOrder,
                      payment: _vm.selectedUserPayment,
                      user: _vm.user,
                    },
                    on: {
                      process: _vm.bamboraSubmit,
                      "success:updatePaymentInfo":
                        _vm.handleUpdatePaymentMethodsSuccess,
                      "success:deletePayment": _vm.handleDeletePayment,
                      uniquePayment: function ($event) {
                        _vm.uniquePayment = true
                      },
                    },
                  }),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paymentFormValid,
                      expression: "paymentFormValid",
                    },
                  ],
                  staticClass: "btn",
                  attrs: {
                    type: "submit",
                    disabled: !_vm.valid || _vm.isProcessingOrder,
                  },
                },
                [
                  _vm._v(_vm._s(_vm.$t("order.payment.submit"))),
                  _vm.isProcessingOrder
                    ? _c("img", {
                        staticClass: "loading",
                        attrs: { src: "/images/loading.gif" },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }