<!-- eslint-disable -->
<template>
  <div class="pagination">
    <button :disabled="page === 1" @click="firstPage" class="firstPage">
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" />
      </svg>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" />
      </svg>
    </button>
    <button :disabled="page === 1" @click="previousPage" class="previousPage">
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z"  />
      </svg>
      <span>{{ $t('pagination.previous') }}</span>
    </button>
    <div class="goToButtons">
      <button v-for="i in pageRange" :key="i" @click="goToPage(i)" class="goToPageButton" :class="{selected: page === i}" :disabled="page === i">{{i}}</button>
    </div>
    <button :disabled="page === pageCount" @click="nextPage" class="nextPage">
      <span>{{ $t('pagination.next') }}</span>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z"  />
      </svg>
    </button>
    <button :disabled="page === pageCount" @click="lastPage" class="lastPage">
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z"  />
      </svg>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z"  />
      </svg>
    </button>
  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  props: {
    itemsPerPage: {
      type: Number,
      default: 5
    },
    maxNumberOfPages: {
      type: Number,
      default: 3
    },
    numberOfItems: {
      type: Number,
      required: true
    },
    scrollTargetElement: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    pageCount() {
      return !this.numberOfItems ? 1 : Math.ceil(this.numberOfItems / this.itemsPerPage)
    },
    index() {
      return (this.page - 1) * this.itemsPerPage
    },
    page: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    pageRange() {
      let start = this.page - Math.floor((this.maxNumberOfPages - 1) / 2)
      let end = Math.min(this.pageCount, this.page + Math.ceil((this.maxNumberOfPages - 1) / 2))

      start = Math.max(1, Math.min(end - this.maxNumberOfPages + 1, start))
      end = Math.min(this.pageCount, Math.max(this.maxNumberOfPages - start + 1, end))

      return Array.from({ length: end - start + 1 }, (_, i) => i + start)
    }
  },
  methods: {
    goToPage(page, sendScrollEvent = true, count = 0, index = 0) {
      this.page = Math.floor(page)
      this.scrollToElement()
    },
    nextPage() {
      this.goToPage(Math.min(this.pageCount, this.page + 1))
    },
    previousPage() {
      this.goToPage(Math.max(1, this.page - 1))
    },
    firstPage() {
      this.goToPage(1)
    },
    lastPage() {
      this.goToPage(this.pageCount)
    },
    scrollToElement() {
      if(!this.scrollTargetElement){
        return
      }
      this.scrollTargetElement.scrollIntoView()
    }
  }
}
</script>
