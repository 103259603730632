import Vue from 'vue';

export async function addQuantity({ commit }, product) {
  return commit('addQuantity', product);
}

export function clearShippingPrice({ commit }) {
  return commit('setShippingPrice', 0);
}

export function getBusinessHours({ commit }, name) {
  let xhr = new XMLHttpRequest();
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      const response = xhr.response;
      commit('setBusinessAddress', response.addressParts);
      commit('setBusinessHours', response.businessHoursParts);
    }
  };
  xhr.open('GET', `/Umbraco/Api/BusinessHours/GetBusinessHours?name=${name}`);
  xhr.responseType = 'json';
  xhr.send();
}

export async function getDeliveryDates({commit}) {
  const response = (await Vue.axios.get('/Umbraco/Api/Order/GetDeliveryDates')).data;
  return commit('setDeliveryDates', response);
}

export async function getShippingCost({ commit, getters }, store) {
  if (store === null || !getters.shippingAddress) {
    return;
  }
  const response = await Vue.axios.get(`/Umbraco/Api/shipping/calculateShippingCost?shippingAddress=${getters.shippingAddress}&store=${store}`);

  if(response.data.errorCode === 'SHIPPING_DISTANCE_TOO_HIGH') {
    return commit('setShippingPrice', null);
  }

  return commit('setShippingPrice', response.data.price);
}

export async function removeProduct({ commit }, productId) {
  return commit('removeProduct', productId);
}

export async function resetShippingError({ commit }) {
  return commit('resetShippingError');
}

export async function clear({ commit }) {
  await commit('setProducts', []);
  await commit('clearOrder');
  return commit('clearCart');
}

export function setAcceptedTermsConditions({ commit }, accepted) {
  return commit('setAcceptedTermsConditions', accepted);
}

export function setAddShippingExtra({ commit }, added) {
  return commit('setAddShippingExtra', added);
}


export function setAccount({ commit }, { number, type }) {
  return commit('setAccount', { number, type });
}

export function setBillingAddress({ commit }, billingAddress) {
  return commit('setBillingAddress', billingAddress ? {
    address: billingAddress.address,
    city: billingAddress.city,
    postalCode: billingAddress.postalCode
  } : null);
}

export function setCallCustomerOption({commit}, callCustomer) {
  return commit('setCallCustomerOption', callCustomer);
}

export function setCaptchaToken({ commit }, token) {
  return commit('setCaptchaToken', token);
}

export function setCustomer({ commit }, customer) {
  return commit('setCustomer', customer);
}

export function setDeliveryDate({ commit }, deliveryDate ) {
  return commit('setDeliveryDate', deliveryDate);
}

export function setGlobalPaymentInfo({ commit }, { orderID, payerReference, paymentReference }) {
  return commit('setGlobalPaymentInfo', { orderID, payerReference, paymentReference });
}

export function setIsUsingBillingAddress({ commit }, value) {
  return commit('setIsUsingBillingAddress', value);
}

export function setIsProcessingOrder({ commit }, value) {
  return commit('setIsProcessingOrder', value);
}

export function setOrderStep({ commit }, orderStep) {
  return commit('setOrderStep', orderStep);
}

export function setPaymentMode({ commit }, paymentMode) {
  return commit('setPaymentMode', paymentMode);
}

export function setPickupContact({ commit }, contact) {
  return commit('setPickupContact', contact);
}

export function setPickupMyself({ commit }, pickupMyself) {
  return commit('setPickupMyself', pickupMyself);
}

export function setPickupOptions({ commit }, { date, hour, location }) {
  return commit('setPickupOptions', { date, hour, location });
}

export async function setProducts({ commit }, productIds) {
  if (!productIds || !productIds.length) {
    return commit('setProducts', []);
  }

  const response = await Vue.axios.post('/Umbraco/Api/products/products', productIds);
  return commit('setProducts', response.data);
}

export async function setQuantity({ commit }, product) {
  return commit('setQuantity', product);
}

export function setShippingAddress({ commit }, { address, city, postalCode }) {
  return commit('setShippingAddress', { address, city, postalCode });
}

export function setShippingExtraPrice({ commit }, price) {
  return commit('setShippingExtraPrice', price);
}

export async function setShippingMethod({ commit }, shippingMethod) {
  return commit('setShippingMethod', shippingMethod);
}
