<template>
  <div class="accountForm-container">
    <validation-observer class="accountForm sendCodeForm" tag="form" ref="sendCodeForm" @submit.prevent="sendActivationCode" novalidate>
      <validation-provider class="formItem" tag="div" name="email" rules="required|email|max:255" v-slot="{ errors }" ref="resetPasswordEmailRef">
        <label>{{ $t('users.fields.email') }}</label>
        <input type="email" v-model="resetPasswordEmail" :class="{ invalid: errors.length }" autocomplete="email" maxlength="255" />
        <div class="error" v-if="errors.length">{{ errors[0] }}</div>
      </validation-provider>
      <div class="formItem">
        <working-button class="btn" :isWorking="isSendingActivationCode" :label="sendCodeLabel" type="submit" />
        <div class="actionResult" v-if="isActivationCodeSent">{{ $t('users.messages.activationCodeSent') }}</div>
        <div class="error" v-if="sendCodeError">{{ sendCodeError }}</div>
      </div>
    </validation-observer>
    
    <hr/>
    
    <validation-observer class="accountForm validateCodeForm" ref="validateCodeForm" tag="form" @submit.prevent="validateActivationCode">
      <validation-provider class="formItem" tag="div" name="resetPasswordEmail" rules='required|max:6' v-slot="{ errors }">
        <label>{{ $t('users.fields.activationCode') }}</label>
        <input type="text" v-model="resetPasswordActivationCode" :class="{ invalid: errors.length }" />
        <div class="error" v-if="errors.length">{{ errors[0] }}</div>
      </validation-provider>
      <div class="formItem">
        <working-button class="btn" :isWorking="isValidatingCode" :label="validateCodeLabel" type="submit" />
        <div class="error" v-if="validateCodeErrorType">{{ $t(`users.messages.${validateCodeErrorType}`) }}</div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import WorkingButton from './WorkingButton.vue'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'

setInteractionMode('eager')

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    WorkingButton
  },
  data() {
    return {
      isActivationCodeSent: false,
      isSendingActivationCode: false,
      isValidatingCode: false,
      resetPasswordActivationCode: undefined,
      resetPasswordEmail: undefined,
      sendCodeError: undefined,
      validateCodeError: undefined,
      validatedActivationCode: undefined,
      validatedEmail: undefined,
      validateCodeErrorType: null,
      validationCodeHasBeenSentOnce: false
    }
  },
  methods: {
    handleValidateCodeSuccess() {
      this.$emit('success:validateCode', {
        validatedActivationCode: this.validatedActivationCode,
        validatedEmail: this.validatedEmail
      })
    },
    resetForm() {
      this.resetPasswordEmail = undefined
      this.resetPasswordActivationCode = undefined
      this.sendCodeError = undefined,
      this.validateCodeError = undefined,
      this.isActivationCodeSent = false

      this.$nextTick(() => {
        this.$refs.sendCodeForm.reset()
        this.$refs.validateCodeForm.reset()
      })
    },
    async sendActivationCode() {
      if(!await this.$refs.sendCodeForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.sendCodeForm.$el, '.invalid')
        return
      }

      try {
        this.sendCodeError = undefined
        this.isSendingActivationCode = true
        this.validationCodeHasBeenSentOnce = true
        const response = await this.$axios.post('/Umbraco/Api/Authentication/SendResetPassword', { email: this.resetPasswordEmail })
        this.isActivationCodeSent = true
      } catch (error) {
        this.sendCodeError = this.$t('users.messages.sendActivationCodeError')
      } finally {
        this.isSendingActivationCode = false
      }
    },
    async validateActivationCode() {
      if(!await this.$refs.validateCodeForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.validateCodeForm.$el, '.invalid')
        return
      }

      try {
        this.validateCodeErrorType = undefined
        this.isValidatingCode = true
        const response = await this.$axios.post('/Umbraco/Api/Authentication/ValidateResetPassword', { activationCode: this.resetPasswordActivationCode, email: this.resetPasswordEmail })
        this.validatedEmail = this.resetPasswordEmail
        this.validatedActivationCode = this.resetPasswordActivationCode
        this.handleValidateCodeSuccess()
      }
      catch (error) {
        const errorType = error && error.response && error.response.data ? error.response.data : null

        this.validateCodeErrorType = ['ExpiredActivationTokenException', 'InvalidActivationTokenException'].includes(errorType)
          ? errorType
          : 'DefaultActivationTokenException'
      }
      finally {
        this.isValidatingCode = false
      }
    }
  },
  computed: {
    sendCodeLabel() {
      if(this.isSendingActivationCode) {
        return this.$t('users.actions.sendingActivationCode')
      }

      return this.$t(`users.actions.${this.validationCodeHasBeenSentOnce ? 'reSendActivationCode' : 'sendActivationCode'}`)
    },
    validateCodeLabel() {
      if(this.isValidatingCode) {
        return this.$t('users.actions.validatingActivationCode')
      }

      return this.$t('users.actions.validateActivationCode')
    }
  }
}
</script>