import { render, staticRenderFns } from "./ProductAvailability.vue?vue&type=template&id=524918ac"
import script from "./ProductAvailability.vue?vue&type=script&lang=js"
export * from "./ProductAvailability.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('524918ac')) {
      api.createRecord('524918ac', component.options)
    } else {
      api.reload('524918ac', component.options)
    }
    module.hot.accept("./ProductAvailability.vue?vue&type=template&id=524918ac", function () {
      api.rerender('524918ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Product/ProductAvailability.vue"
export default component.exports