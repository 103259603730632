var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "storeInfo" }, [
    _c(
      "div",
      { staticClass: "storeAddress" },
      _vm._l(_vm.businessAddress, function (address) {
        return _c("span", [_vm._v(_vm._s(address))])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "businessHours" },
      _vm._l(_vm.businessHours, function (hours) {
        return _c("span", [_vm._v(_vm._s(hours))])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }