<template>
  <button :type="type" :disabled="isWorking" class="btn_loading" v-bind="$attrs" @click="() => handler && handler()">{{ label }}<img src="/images/loading.gif" v-if="isWorking" class="loading" /></button>
</template>

<script>
export default {
  props: {
    handler: {
      type: Function,
      default: null
    },
    isWorking: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>