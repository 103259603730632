export const pipe = (...fns) => arg => fns.reduce((prev, fn) => fn(prev), arg);

export const tryParseInt = (value, defaultValue) => {
  if (Number.isInteger(value)) {
    return value;
  }
  if (!value) {
    return defaultValue;
  }

  let newValue = defaultValue;

  if (value !== null && value.length > 0 && !isNaN(value)) {
    newValue = parseInt(value);
  }

  return newValue;
};