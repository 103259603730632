<template>
  <button :class="`addFavorite-${this.product.identifier}`" class="addFavorite">
    <img v-if="memberId" src="@/assets/svg/heart.svg" @click="addFavorite">
  </button>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    memberId: {
      type: Number,
      default: null
    }
  },
  methods: {
    async addFavorite() {
      const model = {
        memberID: this.memberId,
        productID: this.product.identifier
      }

      const addButton = document.querySelector(`.addFavorite-${this.product.identifier}`)
      const deleteButton = document.querySelector(`.deleteFavorite-${this.product.identifier}`)

      try {
        addButton.classList.toggle('hidden')
        deleteButton.classList.toggle('hidden')
        this.sendGaEvent()
        await this.$axios.post('/Umbraco/Api/Favorites/AddFavorite', model)
      }
      catch (ex) {
        addButton.classList.toggle('hidden')
        deleteButton.classList.toggle('hidden')
      }
    },
    sendGaEvent() {
      try {
        if (dataLayer) {
          const price = this.product.bestPrice != null ? this.product.bestPrice : this.product.price.bestPrice;

          dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'add_to_wishlist',
            ecommerce: {
            currency: 'CAD',
            value: price,
            items: [
                {
                  item_id: this.product.sku,
                  item_name: this.product.name,
                  item_brand: this.product.brand,
                  item_category: this.product.majorCategory,
                  item_category2: this.product.intermediateCategory,
                  item_category3: this.product.minorCategory,
                  price: price,
                  quantity: 1
                }
              ]
            }
          });
        }
      } 
      catch { 
        /*Fait rien*/
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>