<template>
  <div class="selectedStore">
    <div id="branchesToggle" class="ctaBranches">
      <a v-if="$root.getStore() == null" onclick="openSelector()">
        {{ noStoreSelectedText }}
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z" fill="#E02028" />
        </svg>
      </a>
      <a v-else class="selectedStore" onclick="openSelector()">
        <span>
          <span class="selectedStoreName">
            {{ selectedStoreName }}
            <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z" fill="#E02028" />
            </svg>
          </span>
          <span v-if="selectedStoreInformation.hours" class="businessHours">{{ $t('todayBusinessHours', { hours: selectedStoreInformation.hours }) }}</span>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import stores from '@/models/store/Store';

export default {
  props: {
    noStoreSelectedText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      stores: stores
    };
  },
  async created() {
    if (this.$cookies.get('store') == null) {
      this.$cookies.set('store', stores.DR);
      const response = await this.$axios.get(`${location.origin}/Umbraco/Api/BusinessHours/GetTodayBusinessHours`);
      this.setSelectedStoreInformation({ store: stores.DR, hours: response.data });
    }

    if (this.selectedStoreInformation.store == null || this.selectedStoreInformation.store != this.$root.getStore()) {
      const response = await this.$axios.get(`${location.origin}/Umbraco/Api/BusinessHours/GetTodayBusinessHours`);
      this.setSelectedStoreInformation({ store: this.$root.getStore(), hours: response.data });
    }
  },
  methods: {
    ...mapActions('globalModule', ['setSelectedStoreInformation']),
  },
  computed: {
    ...mapState('globalModule', ['selectedStoreInformation']),
    selectedStoreName() {
      const storeCode = Object.keys(this.stores).find(key => 
        this.stores[key] === this.$root.getStore());

      return !storeCode ? '' : this.$t(`stores.${storeCode}`);
    }
  }
};
</script>

