var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BamboraForm", {
        attrs: {
          "button-text": _vm.$t("users.actions.save"),
          "is-payment-info-saving": _vm.paymentInfoIsSaving,
          "error-as-events": true,
        },
        on: { submit: _vm.handleCreateProfile },
      }),
      _c("div", { staticClass: "formItem" }, [
        _vm.savePaymentInfoError
          ? _c("div", { staticClass: "formItem-errorMessage error" }, [
              _vm._v(_vm._s(_vm.savePaymentInfoError)),
            ])
          : _vm._e(),
        _vm.savePaymentInfoMessage
          ? _c("div", { staticClass: "actionResult" }, [
              _vm._v(_vm._s(_vm.savePaymentInfoMessage)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }