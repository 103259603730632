export default {
  getMessage() {
    return 'Invalid phone number';
  },
  validate(value) {
    if (value === null) {
      return true;
    }
    return value.length === 10 && !isNaN(value);
  }
};
