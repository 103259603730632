<template>
  <section class="account-passwordInfo infoPanel">
    <div class="infoPanel-title">
      <h3 class="infoPanel-heading">{{ $t('users.titles.passwordInfo') }}</h3>
    </div>
    <div class="infoPanel-data">
      <div>******</div>
      <!-- <small>{{ $t('users.accountText.modifiedOn', { date: new Date().toLocaleDateString('fr-ca') }) }}</small> -->
    </div>
    <div class="infoPanel-actions">
      <button type="button" class="infoPanel-action btnText" @click="handleShowEditPasswordInfoDialog">{{ $t('users.actions.edit') }}</button>
    </div>

    <teleport to='body'>
      <dialog-modal :isOpen="editPasswordInfoDialogIsOpen" @close:dialog="handleCloseEditPasswordInfoDialog" :title="$t('users.titles.passwordEdition')">
        <edit-password-info v-if="user" :memberId="user.contact.memberID" ref="editPasswordInfoForm" @success:updatePassword="handleUpdatePasswordSuccess"></edit-password-info>
      </dialog-modal>
    </teleport>
  </section>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import Teleport from 'vue2-teleport'
import EditPasswordInfo from '../Forms/EditPasswordInfo.vue'

export default {
  components: {
    DialogModal,
    Teleport,
    EditPasswordInfo
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    connectionUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      editPasswordInfoDialogIsOpen: false,
      isPasswordUpdated: false
    }
  },
  methods: {
    handleCloseEditPasswordInfoDialog() {
      this.editPasswordInfoDialogIsOpen = false
      this.$refs.editPasswordInfoForm.resetForm()
      if (this.isPasswordUpdated) {
      location.href = this.connectionUrl;
      }
    },
    handleUpdatePasswordSuccess() {
      this.isPasswordUpdated = true
      return
    },
    handleShowEditPasswordInfoDialog() {
      this.editPasswordInfoDialogIsOpen = true
    }
  },
  computed: {
    
  }
}
</script>