var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "canadaPostSearchComboBox" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.search,
          expression: "search",
        },
      ],
      class: { invalid: _vm.invalid },
      attrs: {
        type: "text",
        id: _vm.searchFieldId,
        autocomplete: "section-pc",
        name: "address",
      },
      domProps: { value: _vm.search },
      on: {
        blur: function ($event) {
          return _vm.$emit("touched")
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.search = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }