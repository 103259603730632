var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderCreditCard" }, [
    _c(
      "div",
      [
        _c("p", [
          _c("strong", [_vm._v(_vm._s(_vm.$t("order.payment.creditCard")))]),
        ]),
        _vm.isUsingBambora === false ? _c("global-payment-form") : _vm._e(),
        _vm.isUsingBambora === true
          ? _c("BamboraForm", { on: { submit: _vm.bamboraSubmit } })
          : _vm._e(),
        _vm.recaptchaEnabled
          ? _c("vue-recaptcha", {
              ref: "captcha",
              attrs: { sitekey: _vm.recaptchaSiteKey },
              on: { verify: _vm.setCaptchaToken, expired: _vm.resetCaptcha },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }