<template>
  <div class="orderSummariesSection-content">
    <div class="filteringSection">
      <div class="search">
        <input type="text" :placeholder="$t('account.searchPlaceholder')" v-model="searchTerm">
        <icon-search />
      </div>
        
      <div class="dropDowns">
        <div class="statusSelector">
          <label for="status">{{ $t('account.dropdowns.statusDropdownLable') }}</label>
            
          <div class="selectField">
            <select id="status" v-model="status" class="statusFilter">
              <option :value="0">{{ $t('account.dropdowns.statusValueAll') }}</option>
              <option :value="1">{{ $t('account.dropdowns.statusValueDelivered') }}</option>
              <option :value="2">{{ $t('account.dropdowns.statusValueProcessing') }}</option>
              <option :value="3">{{ $t('account.dropdowns.statusValuePickup') }}</option>
            </select>
            <angle-down />
          </div>
        </div>

        <div class="periodSelector">
          <label for="period">{{ $t('account.dropdowns.periodDropdownLabel') }}</label>
          <div class="selectField">
            <select id="period" v-model="period" class="dateFilter">
              <option :value="0">{{ $t('account.dropdowns.periodValueLastMonth') }}</option>
              <option :value="1">{{ $t('account.dropdowns.periodValueAll') }}</option>
            </select>
            <angle-down />
          </div>
        </div>
      </div>
    </div>

    <div class="orderSummaries" ref="orderSummaries">
      <ul v-if="filteredPurchases && filteredPurchases.length" class="orderSummaryList">
        <li v-for="(summary, index) in filteredPurchases" :key="index">
          <OrderSummary :summary="summary"></OrderSummary>
        </li>
      </ul>

      <div v-else class="text">
        <p>{{ $t('account.noResults') }}</p>
      </div>

      <div class="orderSummaries-bottom">
        <div class="left pager">
          <!-- TODO: Ajouter la pagination -->
          <Pagination v-model="page" :numberOfItems="totalItems" :itemsPerPage="perPage" />
        </div>
        <div class="right results"> {{ totalItems }} {{ $tc('account.result', totalItems) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Status } from '@/orderStatusEnum'
  import { Period } from '@/orderPeriodEnum'
  import IconSearch from '../Svg/IconSearch.vue'
  import AngleDown from '../Svg/AngleDown.vue'
  import Pagination from './Pagination.vue'
  import OrderSummary from './OrderSummary.vue'

  export default {
    components: {
      AngleDown,
      IconSearch,
      OrderSummary,
      Pagination
    },
    props: {
      orderSummaries: {
        type: Array
      }
    },
    data() {
      return {
        currentPage: 1,
        page: 1,
        perPage: 10,
        period: Period.All,
        searchTerm: undefined,
        status: Status.All,
        totalItems: 0
      }
    },
    methods: {
    },
    computed: {
      filteredPurchases() {
        if (!this.orderSummaries || !this.orderSummaries.length) {
          return []
        }

        if(this.page !== 1 && this.page === this.currentPage) {
          this.currentPage = 1
        }

        this.currentPage = this.page
        
        const moment = require('moment')
        let filteredPurchases = [...this.orderSummaries]

        if (this.searchTerm) {
          filteredPurchases = filteredPurchases.filter(x => x.products.some(y => y.descriptionFr.toLowerCase().includes(this.searchTerm.toLowerCase()) || y.descriptionEn.toLowerCase().includes(this.searchTerm.toLowerCase()) || y.sku.toLowerCase().includes(this.searchTerm.toLowerCase())))
        }

        if (this.status === Status.Delivered) {
          filteredPurchases = filteredPurchases.filter(x => moment(x.shipping.deliveryDate) < moment())
        } else if (this.status === Status.Processing) {
          filteredPurchases = filteredPurchases.filter(x => moment(x.shipping.deliveryDate) >= moment())
        } else if (this.status === Status.Pickup) {
          filteredPurchases = filteredPurchases.filter(x => x.shipping.shippingMethod === 1)
        }

        if (this.period === Period.LastMonth) {
          filteredPurchases = filteredPurchases.filter(x => moment(x.created) >= moment().subtract(1, 'month'))
        }

        this.totalItems = filteredPurchases.length

        const index = (this.currentPage - 1) * this.perPage
        filteredPurchases = filteredPurchases.slice(index, index + this.perPage)

        return filteredPurchases
      }
    },
    watch: {
      searchTerm() {
        if (this.searchTerm) {
          this.filteredPurchases()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>