var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inputCart" }, [
    _c("div", {
      staticClass: "minus",
      class: { disabled: _vm.isMinusDisabled },
      on: { click: _vm.removeQuantity },
    }),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.quantityValue,
          expression: "quantityValue",
        },
      ],
      attrs: {
        type: "number",
        name: "name",
        pattern: "\\d+",
        disabled: _vm.disabled,
        min: _vm.minimumQuantity,
        max: _vm.maximumQuantity,
      },
      domProps: { value: _vm.quantityValue },
      on: {
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.quantityValue = $event.target.value
          },
          _vm.validateOnInput,
        ],
        blur: _vm.validateOnBlur,
      },
    }),
    _c("div", {
      staticClass: "plus",
      class: { disabled: _vm.isPlusDisabled },
      on: { click: _vm.addQuantity },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }