var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "accountForm validateAccountCreationForm",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validateToken.apply(null, arguments)
        },
      },
    },
    [
      !_vm.accountCreationHasSucceeded
        ? [
            _c("validation-provider", {
              ref: "tokenField",
              staticClass: "formItem",
              attrs: { rules: "required", tag: "div" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("accountValidationModal.text"))),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.token,
                              expression: "token",
                            },
                          ],
                          class: { invalid: errors.length },
                          attrs: { type: "text" },
                          domProps: { value: _vm.token },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.token = $event.target.value
                            },
                          },
                        }),
                        errors.length
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(_vm._s(errors[0])),
                            ])
                          : _vm._e(),
                        _vm.validationErrorMessage
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(
                                " " + _vm._s(_vm.validationErrorMessage) + " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.errorType === "ExpiredTokenException"
                          ? _c(
                              "div",
                              { staticClass: "formItem" },
                              [
                                _c("working-button", {
                                  staticClass: "btnText",
                                  attrs: {
                                    isWorking: _vm.isResendingCode,
                                    type: "button",
                                    label: _vm.$t(
                                      "accountValidationModal.resendValidationCode"
                                    ),
                                  },
                                  on: { click: _vm.resendValidationCode },
                                }),
                                _vm.resendActivationTokenError
                                  ? _c("div", { staticClass: "error" }, [
                                      _vm._v(
                                        _vm._s(_vm.resendActivationTokenError)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.resendActivationTokenMessage
                                  ? _c("div", { staticClass: "actionResult" }, [
                                      _vm._v(
                                        _vm._s(_vm.resendActivationTokenMessage)
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                1616170399
              ),
            }),
            _c("working-button", {
              staticClass: "btn",
              attrs: {
                isWorking: _vm.isValidatingAccount,
                label: _vm.submitLabel,
                type: "submit",
              },
            }),
          ]
        : [
            _c(
              "div",
              { staticClass: "resetPasswordForm-successPanel" },
              [
                _c("circle-check", {
                  staticClass: "resetPasswordForm-successIcon",
                }),
                _c("div", { staticClass: "resetPasswordForm-successMessage" }, [
                  _vm._v(
                    _vm._s(_vm.$t("users.messages.success.accountCreation"))
                  ),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "btn resetPasswordForm-successConnect",
                    attrs: { href: "/connexion" },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.connectMe")))]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }