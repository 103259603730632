import { render, staticRenderFns } from "./ProductsContainer.vue?vue&type=template&id=c0015ebc"
import script from "./ProductsContainer.vue?vue&type=script&lang=js"
export * from "./ProductsContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c0015ebc')) {
      api.createRecord('c0015ebc', component.options)
    } else {
      api.reload('c0015ebc', component.options)
    }
    module.hot.accept("./ProductsContainer.vue?vue&type=template&id=c0015ebc", function () {
      api.rerender('c0015ebc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Products/ProductsContainer.vue"
export default component.exports