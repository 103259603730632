var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSelectedStore
    ? _c(
        "a",
        {
          staticClass: "btn branch",
          on: {
            click: function ($event) {
              return _vm.$root.onChangeStore(_vm.store)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("chooseThisStore")))]
      )
    : _c(
        "a",
        {
          staticClass: "btn branch",
          class: { selected: _vm.isSelectedStore },
          on: {
            click: function ($event) {
              return _vm.$root.onChangeStore(_vm.store)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("myStore")))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }