<template>
  <address>
    {{ address.addressLine }}<br />
    {{ address.city }}<br />
    {{ address.postalCode }}
  </address>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    }
  }
}
</script>