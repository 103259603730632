<template>
  <validation-observer class="editUserInfoForm accountForm" ref="editUserInfoForm" tag="form" @submit.prevent="handleSaveUserInfo">
    <validation-provider class="formItem" tag="div" name="firstName" rules="required|max:50" v-slot="{ errors }">
      <label for="editUserInfo-firstNameInput">{{ $t('users.fields.firstName') }}*</label>
      <input id="editUserInfo-firstNameInput" type="text" v-model="contact.firstName" :class="{ invalid: errors.length }"/>
      <div class="formItem-errorMessage error" v-if="errors.length">{{ errors[0] }}</div>
    </validation-provider>
    
    <validation-provider class="formItem" tag="div" name="lastName" rules="required|max:50" v-slot="{ errors }">
      <label for="editUserInfo-lastNameInput">{{ $t('users.fields.lastName') }}*</label>
      <input id="editUserInfo-lastNameInput" type="text" v-model="contact.lastName" :class="{ invalid: errors.length }" />
      <div class="formItem-errorMessage error" v-if="errors.length">{{ errors[0] }}</div>
    </validation-provider>
    
    <validation-provider class="formItem" tag="div" name="phone" rules="required|phone|max:20" v-slot="{ errors }">
      <label for="editUserInfo-phoneInput">{{ $t('users.fields.phone') }}*</label>
      <the-mask id="editUserInfo-phoneInput" type="tel" mask="(###) ###-####" v-model="contact.phone" :class="{ invalid: errors.length }" />
      <div class="formItem-errorMessage error" v-if="errors.length">{{ errors[0] }}</div>
    </validation-provider>
    
    <div class="formItem">
      <working-button class="btn" :isWorking="userInfoIsSaving" :label="$t('users.actions.save')" type="submit"></working-button>
      <div class="formItem-errorMessage error" v-if="saveUserInfoError">{{ saveUserInfoError }}</div>
      <div class="actionResult" v-if="saveUserInfoMessage">{{ saveUserInfoMessage }}</div>
    </div>
  </validation-observer>
</template>

<script>
import { setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'
import { TheMask } from 'vue-the-mask'
import WorkingButton from './WorkingButton.vue'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'

setInteractionMode('eager')

export default {
  components: {
    TheMask,
    ValidationObserver,
    ValidationProvider,
    WorkingButton
  },
  props: {
    userContact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      contact: structuredClone(this.userContact),
      saveUserInfoError: undefined,
      saveUserInfoMessage: undefined,
      userInfoIsSaving: false
    }
  },
  methods: {
    async handleSaveUserInfo() {
      this.saveUserInfoError = undefined

      if(!await this.$refs.editUserInfoForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.editUserInfoForm.$el, '.invalid')
        return
      }

      try {
        this.userInfoIsSaving = true
        if (this.contact.phone === '') {
          this.contact.phone = null
        }
        const response = await this.$axios.post('/Umbraco/Api/User/SaveUserProfile', this.contact)
        this.saveUserInfoMessage = this.$t('users.messages.success.editUserInfo')
        this.$emit('success:updateContact', this.contact)
      } catch (error) {
        this.saveUserInfoError = this.$t('users.messages.SaveUserInfoException')
      } finally {
        this.userInfoIsSaving = false
      }
    },
    resetForm() {
      this.contact = structuredClone(this.userContact)
      this.saveUserInfoError = undefined
      this.saveUserInfoMessage = undefined

      this.$nextTick(() => {
        this.$refs.editUserInfoForm.reset()
      })
    }
  }
}
</script>