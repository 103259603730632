<template>
  <a :href="$t(`infoBoxExternalProviders.link.provider.${provider}`)" target="_blank" v-if="isExternalProviderMinorCategoriesToInclude" class="infoBoxExternalProvider">
    <img src="/images/info-icon.png" >
    <span>{{ $t(`infoBoxExternalProviders.text.provider.${provider}`) }}</span>
    <img class="linkIcon" src="/images/external-link-icon.png">
  </a>
</template>

<script>
export default {
  data() {
    return {
      provider: '',
      minorCategoriesToInclude: ['Bardeaux à toiture Duration']
    }
  },
  props: {
    itemsContainer: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.setprovider();
  },
  computed: {
    isExternalProviderMinorCategoriesToInclude() {
      if (this.itemsContainer.selectedFacets.minorCategories != null) {
        return this.itemsContainer.selectedFacets.minorCategories.some(x => this.minorCategoriesToInclude.includes(x))
      }
    }
  },
  methods: {
    setprovider() {
      if (this.itemsContainer.selectedFacets.minorCategories != null && this.itemsContainer.selectedFacets.minorCategories.some(x => x === 'Bardeaux à toiture Duration')) {
        this.provider = 'bardeauxDuration'
      }
    }
  },
  watch: {
    'itemsContainer.selectedFacets'() {
      this.setprovider()
    }
  }
}
</script>