<template>
  <div class="storeInfo">
    <div class="storeAddress">
      <span v-for="address in businessAddress">{{address}}</span>
    </div>
    <div class="businessHours">
      <span v-for="hours in businessHours">{{hours}}</span>
    </div>
  </div>
</template>

<script>
import PickupLocation from '@/models/cart/PickupLocation';
import PickupMoment from '@/models/cart/PickupMoment';
import Store from '@/models/store/Store';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      PickupLocation,
      PickupMoment,
      pickupOptions: {
        date: '',
        hour: '',
        location: ''
      }
    };
  },
  computed: {
    ...mapState('cartModule', ['businessAddress', 'businessHours']),
    isValid() {
      return this.pickupOptions.date && this.pickupOptions.hour;
    },
    minDate() {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      return now.toISOString().split('T')[0];
    },
    store() {
      const store = this.$root.getStore();
      for (const [key, value] of Object.entries(Store)) {
        if (value === store) {
          return this.$i18n.t(`stores.${key}`);
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions('cartModule', ['getBusinessHours', 'setPickupOptions'])
  },
  created() {
    if (this.store) {
      this.getBusinessHours(this.store);
    }
  },
  watch: {
    pickupOptions: {
      deep: true,
      handler(value) {
        this.setPickupOptions(value);
      }
    }
  }
};
</script>
