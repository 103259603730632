var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.summary
      ? _c(
          "div",
          { staticClass: "wrapper wrapper_small", attrs: { id: "pdfContent" } },
          [
            _c(
              "div",
              { staticClass: "summaryContainer" },
              [
                _vm.backLink
                  ? _c(
                      "a",
                      { staticClass: "back", attrs: { href: _vm.backLink } },
                      [
                        _c("angle-left"),
                        _c("span", [_vm._v(_vm._s(_vm.$t("bill.backLink")))]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "billInfos" }, [
                  _c("div", { staticClass: "billInfos-title" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.$t("bill.title")))]),
                  ]),
                  _c("div", { staticClass: "purchaseHeader" }, [
                    _c("h2", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("bill.buyDate", {
                            date: this.formatDate(_vm.summary.created, "LL"),
                          })
                        )
                      ),
                    ]),
                    _vm.isDelivered
                      ? _c("div", { staticClass: "status" }, [
                          _vm._v(_vm._s(_vm.$t("bill.status.delivered"))),
                        ])
                      : _c("div", { staticClass: "status" }, [
                          _vm._v(
                            _vm._s(_vm.$t("bill.status.processing")) +
                              " " +
                              _vm._s(
                                _vm.$t("bill.shippingDate", {
                                  shippingDate: this.formatDate(
                                    _vm.summary.shipping.deliveryDate,
                                    "Do MMMM"
                                  ),
                                })
                              )
                          ),
                        ]),
                    _c("div", { staticClass: "details" }, [
                      _vm.summary.invoiceNumber
                        ? _c("div", { staticClass: "detailsTab" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("bill.billNumber")) +
                                " " +
                                _vm._s(_vm.summary.invoiceNumber) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.hasPaymentMethod
                        ? _c("div", { staticClass: "detailsTab" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("bill.paymentMethodLabel")) +
                                " " +
                                _vm._s(_vm.getPaymentMethod) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.hasShippingMethod
                        ? _c("div", { staticClass: "detailsTab" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("bill.shippingMethodLabel")) +
                                " " +
                                _vm._s(_vm.getShippingMethod) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c(
                  "ul",
                  { staticClass: "billProducts" },
                  _vm._l(_vm.summary.items, function (product, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "billProduct" },
                      [
                        _c("purchase-details-product", {
                          attrs: { product: product },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "prices" }, [
                  _vm.summary.shippingAmount > 0
                    ? _c("div", { staticClass: "shippingAmount" }, [
                        _vm._v(
                          _vm._s(_vm.$t("cart.shipping")) +
                            ": " +
                            _vm._s(_vm.shippingAmount)
                        ),
                      ])
                    : _vm._e(),
                  _vm.summary.shipping.extraFee > 0
                    ? _c("div", { staticClass: "shippingExtraFee" }, [
                        _vm._v(
                          _vm._s(_vm.$t("cart.shippingExtra")) +
                            ": " +
                            _vm._s(_vm.shippingExtraFee)
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "subTotal" }, [
                    _vm._v(
                      _vm._s(_vm.$t("cart.subTotal")) +
                        ": " +
                        _vm._s(_vm.subTotal)
                    ),
                  ]),
                  _c("div", { staticClass: "gst" }, [
                    _vm._v(_vm._s(_vm.$t("cart.gst")) + " " + _vm._s(_vm.gst)),
                  ]),
                  _c("div", { staticClass: "qst" }, [
                    _vm._v(_vm._s(_vm.$t("cart.qst")) + " " + _vm._s(_vm.qst)),
                  ]),
                  _c("div", { staticClass: "prices-total" }, [
                    _vm._v(
                      _vm._s(_vm.$t("cart.totalBill")) +
                        " " +
                        _vm._s(_vm.total) +
                        " (" +
                        _vm._s(_vm.summaryTotalItems) +
                        " " +
                        _vm._s(_vm.$tc("bill.item", _vm.summaryTotalItems)) +
                        ")"
                    ),
                  ]),
                  _c("div", { staticClass: "downloadBill" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btnText",
                        attrs: { type: "button" },
                        on: { click: _vm.download },
                      },
                      [_vm._v(_vm._s(_vm.$t("bill.printBill")))]
                    ),
                  ]),
                ]),
                _vm.userPaymentIsEnabled
                  ? [
                      _vm.summary.payment !== null
                        ? _c("div", { staticClass: "payment" }, [
                            _c("div", { staticClass: "payment-title" }, [
                              _c("h3", { staticClass: "infoPanel-heading" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("bill.paymentInformationsLabel")
                                  )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "payment-data" },
                              [
                                _c("payment-informations", {
                                  attrs: {
                                    "billing-address": _vm.summary.payment,
                                    receipt: _vm.summary.bamboraReceipt,
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        )
      : _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "loadingProduct",
            },
            [_c("img", { attrs: { src: "/images/triangle-loading.gif" } })]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }