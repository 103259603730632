import BamboraForm from '@/components/Order/BamboraForm.vue'
import Cart from '@/components/Cart/Cart.vue'
import CartButton from '@/components/Cart/CartButton.vue'
import EmptyCart from '@/components/Cart/EmptyCart.vue'
import PanelCartContent from '@/components/Cart/PanelCartContent.vue'
import PanelCartHeader from '@/components/Cart/PanelCartHeader.vue'
import DropdownCount from '@/components/Facets/DropdownCount.vue'
import DropdownFacet from '@/components/Facets/DropdownFacet.vue'
import DropdownSort from '@/components/Facets/DropdownSort.vue'
import FacetItemsViewer from '@/components/Facets/FacetItemsViewer.vue'
import MultipleSelectionFacet from '@/components/Facets/MultipleSelectionFacet.vue'
import Pagination from '@/components/Facets/Pagination.vue'
import RadioButtonFacet from '@/components/Facets/RadioButtonFacet.vue'
import SingleSelectionFacet from '@/components/Facets/SingleSelectionFacet.vue'
import Login from '@/components/Login/Login.vue'
import Register from '@/components/Login/Register.vue'
import Order from '@/components/Order/Order.vue'
import OrderConfirmation from '@/components/Order/OrderConfirmation.vue'
import AddToCart from '@/components/Product/AddToCart.vue'
import AddToCartIndicator from '@/components/Product/AddToCartIndicator.vue'
import AddToFavorites from '@/components/Product/AddToFavorites.vue'
import DeleteFromFavorites from '@/components/Product/DeleteFromFavorites.vue'
import FavoriteProduct from '@/components/Product/FavoriteProduct.vue'
import Product from '@/components/Product/Product.vue'
import ProductAvailability from '@/components/Product/ProductAvailability.vue'
import ProductImages from '@/components/Product/ProductImages.vue'
import ProductPrice from '@/components/Product/ProductPrice.vue'
import PromotionEndDate from '@/components/Product/PromotionEndDate.vue'
import ProductsCarrousel from '@/components/Products/ProductsCarrousel.vue'
import ProductsContainer from '@/components/Products/ProductsContainer.vue'
import SearchBar from '@/components/Search/SearchBar.vue'
import SearchFilter from '@/components/Search/SearchFilter.vue'
import SearchResults from '@/components/Search/SearchResults.vue'
import Account from '@/components/Secure/Account.vue'
import Favorites from '@/components/Secure/Favorites.vue'
import PaymentInformations from '@/components/Secure/PaymentInformations.vue'
import PurchaseDetails from '@/components/Secure/PurchaseDetails.vue'
import SelectStoreButton from '@/components/SelectStoreButton.vue'
import SelectedStore from '@/components/SelectedStore.vue'
import SelectedStoreContent from '@/components/SelectedStoreContent.vue'
import SelectedStoreFooter from '@/components/SelectedStoreFooter.vue'
import StoreChangeWarningModal from '@/components/UI/StoreChangeWarningModal.vue'
import InfoBoxToExternalProvider from '@/components/UI/InfoBoxToExternalProvider.vue'

export default {
  Account,
  AddToCart,
  AddToFavorites,
  AddToCartIndicator,
  BamboraForm,
  Cart,
  CartButton,
  DeleteFromFavorites,
  DropdownCount,
  DropdownFacet,
  DropdownSort,
  EmptyCart,
  FacetItemsViewer,
  FavoriteProduct,
  Favorites,
  InfoBoxToExternalProvider,
  Login,
  MultipleSelectionFacet,
  Order,
  OrderConfirmation,
  Pagination,
  PanelCartContent,
  PanelCartHeader,
  Product,
  ProductImages,
  ProductAvailability,
  ProductsCarrousel,
  ProductsContainer,
  PromotionEndDate,
  RadioButtonFacet,
  Register,
  SearchBar,
  SearchFilter,
  SearchResults,
  SelectedStore,
  SelectedStoreContent,
  SelectedStoreFooter,
  SelectStoreButton,
  SingleSelectionFacet,
  StoreChangeWarningModal,
  ProductPrice,
  PurchaseDetails,
  PaymentInformations
}
