var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "productItem" },
    [
      _c("div", { staticClass: "productItem-image" }, [
        _c("img", {
          attrs: { src: _vm.imageUrl },
          on: { error: _vm.setFallbackProductImage },
        }),
      ]),
      _c("div", { staticClass: "productItem-data" }, [
        _c("header", { staticClass: "productItem-name" }, [
          !_vm.product.productUrl
            ? _c("span", { staticClass: "productItem-productLink" }, [
                _vm._v(_vm._s(_vm.name)),
              ])
            : _c(
                "a",
                {
                  staticClass: "productItem-productLink cardLink",
                  attrs: { href: _vm.product.productUrl },
                },
                [_vm._v(_vm._s(_vm.name))]
              ),
          _vm.specialStatus
            ? _c("div", { staticClass: "productItem-status" }, [
                _vm._v(_vm._s(_vm.specialStatus)),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "productItem-numbers" }, [
          _c("div", { staticClass: "productItem-quantity" }, [
            _vm._v(
              _vm._s(_vm.$t("bill.quantity")) +
                " " +
                _vm._s(_vm.product.quantity)
            ),
          ]),
          _c("div", { staticClass: "productItem-unitPrice" }, [
            _vm._v(_vm._s(_vm.unitPrice)),
          ]),
        ]),
      ]),
      _vm.product.productUrl
        ? _c("angle-right", { staticClass: "chevron" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }