<template>
  <div class="globalPaymentForm">
    <form v-if="params && secureUrl" :action="secureUrl" method="POST" ref="form" target="gpiframe">
      <input type="hidden" name="ACCOUNT" :value="params.account">
      <input type="hidden" name="AMOUNT" :value="params.amount">
      <input type="hidden" name="AUTO_SETTLE_FLAG" value="1">
      <input type="hidden" name="CARD_PAYMENT_BUTTON" :value="$t('order.globalPayment.submit')">
      <input type="hidden" name="CARD_STORAGE_ENABLE" value="1">
      <input type="hidden" name="CURRENCY" :value="params.currency">
      <input type="hidden" name="HPP_LANG" :value="language">
      <input type="hidden" name="HPP_POST_DIMENSIONS" :value="domain">
      <input type="hidden" name="HPP_POST_RESPONSE" :value="domain">
      <input type="hidden" name="HPP_VERSION" value="2">
      <input type="hidden" name="MERCHANT_ID" :value="params.merchantId">
      <input type="hidden" name="OFFER_SAVE_CARD" value="0">
      <input type="hidden" name="ORDER_ID" :value="params.orderId">
      <input type="hidden" name="PAYER_EXIST" value="0">
      <input type="hidden" name="SHA1HASH" :value="params.hash">
      <input type="hidden" name="TIMESTAMP" :value="params.timestamp">
      <input type="hidden" name="VALIDATE_CARD_ONLY" value="1">
    </form>
    <iframe v-if="frame" name="gpiframe" :width="width" :height="height" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      frame: true,
      height: '100%',
      params: null,
      secureUrl: null,
      width: '100%'
    };
  },
  computed: {
    domain() {
      const parts = location.href.split('/');
      return `${parts[0]}//${parts[2]}`;
    },
    language() {
      return this.$i18n.locale.split('-')[0];
    }
  },
  methods: {
    ...mapActions('cartModule', ['setGlobalPaymentInfo']),
    ...mapActions('globalModule', ['setErrorModal']),
    
    async getGlobalPaymentsForm() {
      const response = await this.$axios.get(`${location.origin}/Umbraco/Api/GlobalPayment/Form`);
     
      if (response.status === 200 && response.data) {
        this.params = {
          account: response.data.account,
          amount: response.data.amount,
          currency: response.data.currency,
          hash: response.data.hash,
          merchantId: response.data.merchantID,
          orderId: response.data.orderID,
          timestamp: response.data.timestamp
        };
        this.secureUrl = response.data.secureUrl;
        this.$nextTick(() => {
          this.$refs.form.submit();
          this.params = null;
        });
      }
    }
  },
  created() {
    addEventListener('message', async event => {
      try {
        if (event && event.data && this.secureUrl && this.secureUrl.indexOf(event.origin) >= 0) {
          const data = JSON.parse(event.data);
          if (data.iframe) {
            this.frame = data.iframe.width !== '0px' && data.iframe.height !== '0px';
            this.width = data.iframe.width;
            this.height = data.iframe.height;
          } else {
            for (const [key, value] of Object.entries(data)) {
              data[key] = decodeURIComponent(escape(atob(value)));
            }
            await this.$axios.post(`${location.origin}/Umbraco/Api/GlobalPayment/Validate`, {
              AuthCode: data['AUTHCODE'],
              Hash: data['SHA1HASH'],
              MerchantID: data['MERCHANT_ID'],
              Message: data['MESSAGE'],
              OrderID: data['ORDER_ID'],
              PasRef: data['PASREF'],
              Result: data['RESULT'],
              Timestamp: data['TIMESTAMP']
            });
            if (data['RESULT'] !== '00') {
              return this.setErrorModal('order.globalPayment.cardDeclined');
            }
            this.setGlobalPaymentInfo({
              orderID: data['ORDER_ID'],
              payerReference: data['SAVED_PAYER_REF'],
              paymentReference: data['SAVED_PMT_REF']
            });
          }
        }
      } catch (e) {
        if (event.data.toLowerCase().indexOf('comms error') >= 0) {
          return this.setErrorModal('order.globalPayment.communicationError');
        }
        // eslint-disable-next-line no-console
        console.error(e);
        this.setErrorModal(true);
      }
    });
  },  
  async mounted() {
    await this.getGlobalPaymentsForm();
  }
};
</script>
