<template>
  <section class="account-paymentMethodsInfo infoPanel">
    <div class="infoPanel-title">
      <h3 class="infoPanel-heading">{{ $t('users.titles.paymentMethodsInfo') }}<img src="/images/loading.gif" v-if="isDeleting" class="loading" /></h3>
    </div>
    <div class="infoPanel-data">
      <div v-if="hasPaymentMethods">
        <div class="paymentInfos">
          <div class="paymentInfos-upperSection">
            <div class="paymentInfos-cardDigits">
              <img v-if="payment.creditCardType === 'VI'" src="@/assets/svg/Visa-logo.svg" class="vi-card-logo">
              <img v-else-if="payment.creditCardType === 'MC'" src="@/assets/svg/MasterCard-logo.svg" class="mc-card-logo">             
              <strong>{{ $t('order.payment.endsWith') }} {{ payment.creditCardLastDigits }}</strong>
            </div>
            
            <div v-if="deletePaymentErrorMessage != null" class="formItem-errorMessage error" v-html="deletePaymentErrorMessage"></div>
          </div>
          <div class="paymentInfos-lowerSection">
            <div class="paymentInfos-cardholder">{{ $t('paymentInformations.cardholderName') }} {{ payment.cardholderName }}</div>
            <div class="paymentInfos-exp" v-if="!isCardExpired">{{ $t('paymentInformations.expirationLabel') }} {{ payment.creditCardExpiration }}</div>
            <div class="expired" v-else>{{ $t('paymentInformations.expiredCard') }} {{ payment.creditCardExpiration }}</div>
          </div>
        </div>
      </div>
      <template v-else>
        <span>{{ $t("users.accountText.noPaymentMethods") }}</span>
      </template>
      <button v-if="!hasPaymentMethods" class="btnText infoPanel-action" type="button" @click="handleShowEditPaymentMethodsInfoDialog({})">
        {{ $t("users.actions.addAPaymentMethod") }}
      </button>
    </div>
    <div class="infoPanel-actions">
      <button v-if="hasPaymentMethods" type="button" class="infoPanel-action btnText" @click="handleDeletePayment()">{{ $t('users.actions.delete') }}</button>
    </div>
    <teleport to='body'>
      <dialog-modal :isOpen="editPaymentMethodDialogIsOpen" @close:dialog="handleCloseEditPaymentMethodDialog" :title="$t('users.titles.addPaymentMethod')">
        <edit-payment-methods-info 
          v-if="editingPaymentMethod != null"
          :bambora-configuration="bamboraConfiguration"
          :payment-method="editingPaymentMethod"
          :user="user" 
          ref="editPaymentMethodsInfoForm" 
          @success:updatePaymentInfo="handleUpdatePaymentMethodsSuccess">
        </edit-payment-methods-info>
      </dialog-modal>
    </teleport>
  </section>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import Teleport from 'vue2-teleport'
import EditPaymentMethodsInfo from '../Forms/EditPaymentMethodsInfo.vue'

export default {
  components: {
    DialogModal,
    EditPaymentMethodsInfo,
    Teleport
  },
  props: {
    bamboraConfiguration: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      deletePaymentErrorMessage: null,
      editPaymentMethodDialogIsOpen: false,
      editingPaymentMethod: null,
      isDeleting: false,
      payment: this.user.payment,
    }
  },
  methods: {
    handleCloseEditPaymentMethodDialog() {
      this.editPaymentMethodDialogIsOpen = false
      this.editingPaymentMethod = null
    },
    handleShowEditPaymentMethodsInfoDialog(paymentMethod) {
      this.editingPaymentMethod = paymentMethod
      this.editPaymentMethodDialogIsOpen = true
    },
    handleUpdatePaymentMethodsSuccess(paymentInfo) {
      this.payment = paymentInfo;
    },
    async handleDeletePayment() {
      try {
        this.isDeleting = true
        const model = {
          bamboraCardID: this.payment.bamboraCardID,
          creditCardLastDigits: this.payment.creditCardLastDigits,
          customerCode: this.payment.bamboraCustomerCode,
          memberID: this.user.contact.memberID      
        };
        const response = await this.$axios.post('/Umbraco/Api/User/DeleteUserPayment', model)
        if (response != null && response.data != null) {
          this.payment = null
          this.isDeleting = false
        } else {
          this.deletePaymentErrorMessage = this.$t('users.messages.deletePaymentError') // Pour couvrir axios
          this.isDeleting = false
        }
      } catch (error) {
        // TODO
        this.errorType = error && error.response && error.response.data ? error.response.data : null
        this.deletePaymentErrorMessage = this.errorType != null ? this.$t(`users.messages.${this.errorType}`) : this.$t('users.messages.deletePaymentError')
        this.isDeleting = false
      }
    },
  },
  computed: {
    hasPaymentMethods() {
      return this.payment != null && this.payment.bamboraCardID > 0;
    },
    isCardExpired() {
      return this.$root.isCardExpired(this.payment.creditCardExpiration)
    }
  }
}
</script>