var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderProduct" }, [
    _c("div", { staticClass: "img" }, [
      _c("a", { attrs: { href: _vm.product.Url } }, [
        _vm.product.images && _vm.product.images.length
          ? _c("img", {
              attrs: {
                onerror: "this.src='/images/image-default.png';",
                src: _vm.product.images[0].url
                  ? _vm.product.images[0].url
                  : "/images/image-default.png",
                alt: _vm.product.images[0].alt,
                loading: "lazy",
              },
            })
          : _c("img", {
              attrs: {
                src: "/images/image-default.png",
                alt: "Image par défaut",
                loading: "lazy",
              },
            }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "productInfos" },
      [
        _c("div", { staticClass: "name" }, [
          _vm.cartQuantity
            ? _c("span", [_vm._v(_vm._s(_vm.cartQuantity) + " X ")])
            : _vm._e(),
          _vm._v(_vm._s(_vm.product.name)),
        ]),
        _c("div", { staticClass: "sku" }, [
          _vm._v("#" + _vm._s(_vm.product.sku)),
        ]),
        _c("promotion-end-date", { attrs: { product: _vm.product } }),
        _c("div", { staticClass: "productInfosStore" }, [
          _vm.isAvailable
            ? _c("div", { staticClass: "inStock" }, [
                _vm._v(_vm._s(_vm.$t("product.availability.inStock"))),
              ])
            : !_vm.isInactive && !_vm.isInStock
            ? _c("div", { staticClass: "notInStock" }, [
                _vm._v(_vm._s(_vm.$t("product.availability.notInStock"))),
              ])
            : _vm.isRecall
            ? _c("div", { staticClass: "unavailable" }, [
                _vm._v(_vm._s(_vm.$t("product.availability.recall"))),
              ])
            : _c("div", { staticClass: "unavailable" }, [
                _vm._v(_vm._s(_vm.$t("product.availability.unavailable"))),
              ]),
          _vm.product.dangerousProduct || _vm.product.pesticide
            ? _c("div", { staticClass: "shippingUnavailable" }, [
                _vm._v(
                  _vm._s(_vm.$t("product.availability.shippingUnavailable"))
                ),
              ])
            : _vm._e(),
        ]),
        _vm.showPrice
          ? _c("div", { staticClass: "price" }, [_vm._v(_vm._s(_vm.price))])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }