var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 10 17",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.52344 7.46777C9.98047 7.88965 9.98047 8.62793 9.52344 9.0498L2.77344 15.7998C2.35156 16.2568 1.61328 16.2568 1.19141 15.7998C0.734375 15.3779 0.734375 14.6396 1.19141 14.2178L7.13281 8.24121L1.19141 2.2998C0.734375 1.87793 0.734375 1.13965 1.19141 0.717773C1.61328 0.260742 2.35156 0.260742 2.77344 0.717773L9.52344 7.46777Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }