<template>
  <div class="overlay open" ref="overlay" @click="clickOutside">
    <div :class="isPopin ? 'popin' : 'modal'">
      <div class="modalHead">
        <div>
          <slot name="title"></slot>
        </div>
        <button :class="isPopin ? 'closePopin' : 'closeModal'" @click="close()"></button>
      </div>
      <div class="modalBody">
        <slot name="body"></slot>
      </div>
      <div class="modalFooter">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clickOutsideEnabled: {
      type: Boolean,
      default: true
    },
    isPopin: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickOutside (event) {
      if (this.clickOutsideEnabled && event.target === this.$refs.overlay) {
        this.close();
      }
    },
    close() {
      this.$emit('onclose');
    }
  }
};
</script>
