var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "div",
        { staticClass: "orderCreditCardSelection" },
        [
          _vm.payment != null && _vm.payment.bamboraCardID > 0
            ? _c("div", [
                _c("div", { staticClass: "paymentInfos" }, [
                  _c("div", { staticClass: "paymentInfos-cardDigits" }, [
                    _vm.payment.creditCardType === "VI"
                      ? _c("img", {
                          staticClass: "vi-card-logo",
                          attrs: { src: require("@/assets/svg/Visa-logo.svg") },
                        })
                      : _vm.payment.creditCardType === "MC"
                      ? _c("img", {
                          staticClass: "mc-card-logo",
                          attrs: {
                            src: require("@/assets/svg/MasterCard-logo.svg"),
                          },
                        })
                      : _vm._e(),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("order.payment.endsWith")) +
                          " " +
                          _vm._s(_vm.payment.creditCardLastDigits)
                      ),
                    ]),
                  ]),
                  _vm.isCardExpired
                    ? _c("div", { staticClass: "expired" }, [
                        _vm._v(
                          _vm._s(_vm.$t("paymentInformations.expiredCard")) +
                            " " +
                            _vm._s(_vm.payment.creditCardExpiration)
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btnText infoPanel-action",
                    attrs: { type: "button" },
                    on: { click: _vm.handleEditPayment },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.edit")))]
                ),
              ])
            : _vm._e(),
          _vm.payment != null && _vm.payment.bamboraCardID > 0
            ? _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: {
                      type: "button",
                      disabled: _vm.isProcessingOrder || _vm.isCardExpired,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("process")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("order.payment.submit")))]
                ),
              ])
            : _c("div", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("users.accountText.noPaymentMethods"))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btnText infoPanel-action",
                    attrs: { type: "button" },
                    on: { click: _vm.handleEditPayment },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.addAPaymentMethod")))]
                ),
                _c("span", [_vm._v(_vm._s(_vm.$t("users.accountText.or")))]),
                _c(
                  "button",
                  {
                    staticClass: "btnText infoPanel-action",
                    attrs: { type: "button" },
                    on: { click: _vm.handleUniquePayment },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.uniquePayment")))]
                ),
              ]),
          _c(
            "teleport",
            { attrs: { to: "body" } },
            [
              _c(
                "dialog-modal",
                {
                  attrs: {
                    isOpen: _vm.editOrderPaymentMethodDialogIsOpen,
                    title: _vm.$t("users.titles.editPaymentMethod"),
                  },
                  on: {
                    "close:dialog": _vm.handleCloseOrderPaymentMethodDialog,
                  },
                },
                [
                  _vm.editOrderPaymentMethodDialogIsOpen
                    ? _c("edit-order-payment-method", {
                        attrs: {
                          "bambora-configuration": _vm.bamboraConfiguration,
                          payment: _vm.payment,
                          user: _vm.user,
                        },
                        on: {
                          close: _vm.handleCloseOrderPaymentMethodDialog,
                          "success:updatePaymentInfo":
                            _vm.handleUpdatePaymentMethodsSuccess,
                          "success:deletePayment":
                            _vm.handleDeletePaymentSuccess,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }