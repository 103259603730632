var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    {
      ref: "editPasswordInfoForm",
      staticClass: "editPasswordInfoForm accountForm",
      attrs: { tag: "form", novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSavePasswordInfo.apply(null, arguments)
        },
      },
    },
    [
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "currentPassword", rules: "required" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c(
                  "label",
                  { attrs: { for: "editPasswordInfo-currentPasswordInput" } },
                  [_vm._v(_vm._s(_vm.$t("users.fields.currentPassword")) + "*")]
                ),
                _c("password-container", {
                  attrs: { errors: errors },
                  model: {
                    value: _vm.currentPassword,
                    callback: function ($$v) {
                      _vm.currentPassword = $$v
                    },
                    expression: "currentPassword",
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: {
          tag: "div",
          name: "newPassword",
          rules: "required|password|differentPassword:@currentPassword",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("users.fields.newPassword")) + "*"),
                ]),
                _c("password-container", {
                  attrs: { errors: errors },
                  model: {
                    value: _vm.newPassword,
                    callback: function ($$v) {
                      _vm.newPassword = $$v
                    },
                    expression: "newPassword",
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
                _c("ul", { staticClass: "criterias" }, [
                  _c("li", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("users.messages.passwordCriterias.characters")
                      )
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("users.messages.passwordCriterias.length"))
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "users.messages.passwordCriterias.specialCharacters"
                        )
                      )
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.$t("users.messages.passwordCriterias.numbers"))
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: {
          tag: "div",
          name: "newPasswordConfirmation",
          rules: "required|passwordMatch:@newPassword",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("users.fields.passwordConfirmation")) + "*"
                  ),
                ]),
                _c("password-container", {
                  attrs: { errors: errors },
                  model: {
                    value: _vm.newPasswordConfirmation,
                    callback: function ($$v) {
                      _vm.newPasswordConfirmation = $$v
                    },
                    expression: "newPasswordConfirmation",
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "formItem" },
        [
          _c("working-button", {
            staticClass: "btn",
            attrs: {
              isWorking: _vm.passwordInfoIsSaving,
              label: _vm.$t("users.actions.save"),
              type: "submit",
            },
          }),
          _vm.savePasswordInfoError
            ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                _vm._v(_vm._s(_vm.savePasswordInfoError)),
              ])
            : _vm._e(),
          _vm.savePasswordInfoMessage
            ? _c("div", {
                staticClass: "actionResult",
                domProps: { innerHTML: _vm._s(_vm.savePasswordInfoMessage) },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }