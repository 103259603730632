export default {
  methods: {
    scrollToFirstError(className = 'error') {
      this.$nextTick(() => {
        let errorElements = document.getElementsByClassName(className);
        if (errorElements && errorElements.length > 0) {
          errorElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }
};