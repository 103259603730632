var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    {
      ref: "resetPasswordForm",
      staticClass: "accountForm resetPasswordForm",
      attrs: { tag: "form", novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitResetPassword.apply(null, arguments)
        },
      },
    },
    [
      !_vm.resetPasswordHasSucceded
        ? [
            _c("h3", [_vm._v(_vm._s(_vm.$t("users.titles.passwordEdition")))]),
            _c("validation-provider", {
              staticClass: "formItem",
              attrs: {
                tag: "div",
                name: "newPassword",
                rules: "required|password",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("users.fields.newPassword")) + "*"
                          ),
                        ]),
                        _c("password-container", {
                          attrs: { errors: errors },
                          model: {
                            value: _vm.newPassword,
                            callback: function ($$v) {
                              _vm.newPassword = $$v
                            },
                            expression: "newPassword",
                          },
                        }),
                        errors.length
                          ? _c(
                              "div",
                              { staticClass: "formItem-errorMessage error" },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          : _vm._e(),
                        _c("ul", { staticClass: "criterias" }, [
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.characters"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.length"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.specialCharacters"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.numbers"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                1798201972
              ),
            }),
            _c("validation-provider", {
              staticClass: "formItem",
              attrs: {
                tag: "div",
                name: "newPasswordConfirmation",
                rules: "required|passwordMatch:@newPassword",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("users.fields.passwordConfirmation")
                            ) + "*"
                          ),
                        ]),
                        _c("password-container", {
                          attrs: { errors: errors },
                          model: {
                            value: _vm.newPasswordConfirmation,
                            callback: function ($$v) {
                              _vm.newPasswordConfirmation = $$v
                            },
                            expression: "newPasswordConfirmation",
                          },
                        }),
                        errors.length
                          ? _c(
                              "div",
                              { staticClass: "formItem-errorMessage error" },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                2118030157
              ),
            }),
            _c(
              "div",
              { staticClass: "formItem" },
              [
                _c("working-button", {
                  staticClass: "btn",
                  attrs: {
                    isWorking: _vm.passwordIsResetting,
                    label: _vm.resetPasswordLabel,
                    type: "submit",
                  },
                }),
                _vm.resetPasswordError
                  ? _c("div", {
                      staticClass: "formItem-errorMessage error",
                      domProps: { innerHTML: _vm._s(_vm.resetPasswordError) },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "resetPasswordForm-successPanel" },
              [
                _c("circle-check", {
                  staticClass: "resetPasswordForm-successIcon",
                }),
                _c("div", { staticClass: "resetPasswordForm-successMessage" }, [
                  _vm._v(_vm._s(_vm.$t("users.messages.resetPasswordSuccess"))),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "btn resetPasswordForm-successConnect",
                    attrs: { href: "/connexion" },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.connectMe")))]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }