var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "globalPaymentForm" }, [
    _vm.params && _vm.secureUrl
      ? _c(
          "form",
          {
            ref: "form",
            attrs: {
              action: _vm.secureUrl,
              method: "POST",
              target: "gpiframe",
            },
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: "ACCOUNT" },
              domProps: { value: _vm.params.account },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "AMOUNT" },
              domProps: { value: _vm.params.amount },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "AUTO_SETTLE_FLAG", value: "1" },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "CARD_PAYMENT_BUTTON" },
              domProps: { value: _vm.$t("order.globalPayment.submit") },
            }),
            _c("input", {
              attrs: {
                type: "hidden",
                name: "CARD_STORAGE_ENABLE",
                value: "1",
              },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "CURRENCY" },
              domProps: { value: _vm.params.currency },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "HPP_LANG" },
              domProps: { value: _vm.language },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "HPP_POST_DIMENSIONS" },
              domProps: { value: _vm.domain },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "HPP_POST_RESPONSE" },
              domProps: { value: _vm.domain },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "HPP_VERSION", value: "2" },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "MERCHANT_ID" },
              domProps: { value: _vm.params.merchantId },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "OFFER_SAVE_CARD", value: "0" },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "ORDER_ID" },
              domProps: { value: _vm.params.orderId },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "PAYER_EXIST", value: "0" },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "SHA1HASH" },
              domProps: { value: _vm.params.hash },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "TIMESTAMP" },
              domProps: { value: _vm.params.timestamp },
            }),
            _c("input", {
              attrs: { type: "hidden", name: "VALIDATE_CARD_ONLY", value: "1" },
            }),
          ]
        )
      : _vm._e(),
    _vm.frame
      ? _c("iframe", {
          attrs: { name: "gpiframe", width: _vm.width, height: _vm.height },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }