var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "billingAddress" },
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.useBillingAddressDisabled,
              expression: "!useBillingAddressDisabled",
            },
          ],
          staticClass: "checkboxContainer",
          attrs: { for: "useBillingAddress" },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.useBillingAddress,
                expression: "useBillingAddress",
              },
            ],
            attrs: {
              type: "checkbox",
              id: "useBillingAddress",
              disabled: _vm.useBillingAddressDisabled,
            },
            domProps: {
              checked: Array.isArray(_vm.useBillingAddress)
                ? _vm._i(_vm.useBillingAddress, null) > -1
                : _vm.useBillingAddress,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.useBillingAddress,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.useBillingAddress = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.useBillingAddress = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.useBillingAddress = $$c
                }
              },
            },
          }),
          _c("span", { staticClass: "checkmark" }),
          _c("span", { staticClass: "txt" }, [
            _vm._v(_vm._s(_vm.$t("order.billing.useBillingAddress"))),
          ]),
        ]
      ),
      !_vm.useBillingAddress || _vm.useBillingAddressDisabled
        ? [
            _c(
              "div",
              { staticClass: "row addressRow" },
              [
                _c("validation-provider", {
                  attrs: {
                    name: "address",
                    rules: "required|max:50",
                    slim: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "address" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.billing.address")) +
                                      "*"
                                  ),
                                ]),
                                _c("canada-post-search", {
                                  attrs: {
                                    addressCompleteSettings:
                                      _vm.addressCompleteSettings,
                                    invalid:
                                      _vm.isShippingAddressTouched &&
                                      errors.length > 0,
                                    searchFieldId: "address",
                                  },
                                  on: {
                                    updateAddress: function ($event) {
                                      return _vm.updateAddress($event)
                                    },
                                    input: _vm.validateAddress,
                                    touched: _vm.setAddressTouched,
                                  },
                                  model: {
                                    value: _vm.shippingAddress.address,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shippingAddress,
                                        "address",
                                        $$v
                                      )
                                    },
                                    expression: "shippingAddress.address",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2307276289
                  ),
                }),
                _c("validation-provider", {
                  attrs: { name: "city", rules: "required|max:50", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors, touched }) {
                          return [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "city" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("order.billing.city")) + "*"
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.shippingAddress.city,
                                    expression: "shippingAddress.city",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                class: { invalid: touched && errors.length },
                                attrs: { type: "text", id: "city" },
                                domProps: { value: _vm.shippingAddress.city },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.shippingAddress,
                                      "city",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4248218787
                  ),
                }),
                _c("validation-provider", {
                  attrs: {
                    name: "postalCode",
                    rules: "required|max:6|postalCode",
                    slim: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "form-group postalCodeGroup" },
                              [
                                _c("label", { attrs: { for: "postalCode" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.billing.postalCode")) +
                                      "*"
                                  ),
                                ]),
                                _c("the-mask", {
                                  class: {
                                    invalid:
                                      _vm.isShippingPostalCodeTouched &&
                                      errors.length > 0,
                                  },
                                  attrs: { type: "text", mask: "A#A#A#" },
                                  on: { input: _vm.validateAddress },
                                  nativeOn: {
                                    blur: function ($event) {
                                      return _vm.setPostalCodeTouched.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.shippingAddress.postalCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shippingAddress,
                                        "postalCode",
                                        $$v
                                      )
                                    },
                                    expression: "shippingAddress.postalCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3335672994
                  ),
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }