import GlobalComponents from '@/global-components.js'
import i18n from '@/i18n'
import stores from '@/models/store/Store'
import orderMixins from '@/orderMixins'
import '@/plugins/axios'
import passwordRule from '@/rules/password'
import passwordMatchRule from '@/rules/passwordMatch'
import differentPassword from '@/rules/differentPassword'
import phoneRule from '@/rules/phone'
import postalCodeRule from '@/rules/postalCode'
import store from '@/store'
import tostini from 'v-tostini/src'
import { extend, localize } from 'vee-validate'
import { email, max, required } from 'vee-validate/dist/rules'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { mapActions, mapGetters, mapState } from 'vuex'
import insideQc from './rules/insideQc'
import { getClickTags } from '@/helpers/analyticsHelper'

extend('differentPassword', differentPassword)
extend('email', email)
extend('insideQc', insideQc)
extend('max', max)
extend('password', passwordRule)
extend('passwordMatch', passwordMatchRule)
extend('phone', phoneRule)
extend('postalCode', postalCodeRule)
extend('required', required)

localize({
  fr: {
    messages: {
      differentPassword: 'Le nouveau mot de passe doit être différent de celui utilisé actuellement',
      email: 'Ce champ doit être une adresse courriel valide',
      insideQc: 'L’adresse est à l’extérieur de la zone desservie',
      max: 'La valeur entrée dépasse la longueur maximum',
      password: 'Le mot de passe ne répond pas aux critères de sécurité. Assurez‑vous qu’il contienne tous les éléments de sécurité demandés.',
      passwordMatch: 'Le mot de passe ne correspond pas. Veuillez réessayer.',
      phone: 'Ce champ doit être un téléphone valide',
      required: 'Ce champ est requis'
    }
  }
})
localize('fr')

Vue.config.productionTip = false

Vue.use(tostini)
Vue.use(VueCookies)
Vue.$cookies.config('2y', '/')

window.addEventListener('hashchange', function() {
  if(this.location.pathname.startsWith('/departements')) {
    location.reload()
  }
}, false)

Vue.config.errorHandler = (err) => {
  store.dispatch('globalModule/setErrorModal', true)
  // eslint-disable-next-line no-console
  console.log(err)
}

const numberFormatter = new Intl.NumberFormat('fr-CA', { // TODO: i18n est mal configuré
  style: 'currency',
  currency: window.appConfig.Currency
})

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
} 

const vue = new Vue({
  store,
  i18n,
  components: {
    ...GlobalComponents
  },
  data: {
    hiddenCategories: null,
    itemsContainer: {
      isDesc: true,
      items: [],
      facets: [],
      selectedFacets: {},
      filtrationFacets: [],
      lastSelectedFiltrationFacetName: null,
      total: 0
    },
    isLoadingHiddenCategories: false
  },
  methods: {
    ...mapActions('globalModule', ['setStoreChangeWarningModal']),
    async ensureHiddenCategoriesAreLoaded() {
      if (this.hiddenCategories === null) {
        this.hiddenCategories = await this.fetchHiddenCategories()
      }
    },
    async fetchHiddenCategories() {
      if (this.isLoadingHiddenCategories) {
        return;
      }

      this.isLoadingHiddenCategories = true
      const response = await Vue.axios.get('/Umbraco/Api/Products/HiddenCategories')
      this.isLoadingHiddenCategories = false
      return response.data
    },
    formatPrice(price) {
      return numberFormatter.format(price).replace('CA', '').trim()
    },
    getAppSearchFacets() {
      return {
        majorCategory: {
          type: 'value'
        },
        intermediateCategory: { 
          type: 'value',
          size: 50
        },
        minorCategory: {
          type: 'value',
          size: 50
        },
        tags: { 
          type: 'value'
        },
        brand: {
          type: 'value'
        },
        promotionTag: { 
          type: 'value'
        }
      }
    },
    async sendAppSearchRequest(params) {
      let recordAnalytics = true;
      if (!params.query || params.query.length <= 1) { // Enlève les strings vides et les lettres simples des analytiques
        recordAnalytics = false;
      }

      params.record_analytics = recordAnalytics; // Permet de ne pas tracker une requête dans AppSearch

      const response = await Vue.axios.post(
        `${window.laferte.searchConfig.endpointBase}/api/as/v1/engines/${window.laferte.searchConfig.engineName}/search.json`,
        params,
        {
          headers: {
            Authorization: `Bearer ${window.laferte.searchConfig.searchKey}`
          }
        })

      return response.data
    },
    getAppSearchFilters(filters) {
      const store = this.getStore()

      const commonFilters = [
        {
          field: 'isDiscontinuedOrOffSeason',
          value: 1,
          type: 'none'
        },
        {
          field: 'status',
          value: 1,
          type: 'none'
        },
        {
          field: 'deleted',
          value: 0,
          type: 'all'
        },
        {
          field: 'omniCategory',
          value: this.hiddenCategories || [],
          type: 'none'
        },
        {
          field: 'store',
          value: store === null ? 0 : store,
          type: 'any'
        }
      ]
      
      const allFilters = [...commonFilters, ...filters || []].map(function (filter) {
        const isArrayValue = Array.isArray(filter.value)
        const values = isArrayValue ? filter.value : [filter.value]

        return {
          [filter.type]: {
            [filter.field]: values
          }
        }
      })

      return {
        all: allFilters
      }
    },
    getDiscount(discount) {
      return this.truncate(discount)
    },
    getFeatureFlag(name) {
      const featureFlag = window.laferte.featureFlags[name]
      return typeof featureFlag === 'boolean' ? featureFlag : false
    },
    getImageSrc(image) {
      if (typeof image.url === 'string') {
        return image.url
      } else if (image.url && image.url.length) {
        return image.url[0]
      }
    },
    getPriceDollars(price) {
      const parts = numberFormatter.formatToParts(price)
      let hasReachedDecimal = false

      return parts.reduce((acc, part) => {
        if (part.type === 'decimal') {
          hasReachedDecimal = true
        }

        return acc + (hasReachedDecimal ? '' : part.value)
      }, '')
    },
    getPriceCents(price) {
      const parts = numberFormatter.formatToParts(price)
      let hasReachedDecimal = false

      return parts.reduce((acc, part) => {
        acc += hasReachedDecimal ? part.value : ''

        if (part.type === 'decimal') {
          hasReachedDecimal = true
        }

        return acc
      }, '')
    },
    isCardExpired(cardExpiration) {
      const moment = require('moment')
      var dateData = cardExpiration.split('/')
      var month = dateData[0]
      var year = dateData[1]
      return moment() >= moment().year(year) && moment() > moment().month(month)
    },
    getStore() {
      const store = Number.parseInt(this.$cookies.get('store'))
      return !Object.values(stores).includes(store) ? stores.DR : store
    },
    onChangeStore(selectedStore) {
      // if (this.count > 0) {
      //   this.setStoreChangeWarningModal({ store: selectedStore });
      // } else {
      this.selectStore(selectedStore)
      this.sendSelectedStoreGaEvent()
      //}
    },
    selectStore(selectedStore) {
      let store = null
      if (selectedStore) {
        store = selectedStore
      } else if (this.storeChangeWarningModal) {
        store = this.storeChangeWarningModal.store
      }
      this.$cookies.set('store', store)
      location.reload()
    },
    sendSelectedStoreGaEvent() {
      /* eslint-disable-next-line */
      gtag('', this.getSelectedStore(), 'Label');
    },
    sendSelectItemGaEvent(item) {
      try {
        if (dataLayer) {
          dataLayer.push({ ecommerce: null })  // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'select_item',
            ecommerce: {
              items: [{
                item_id: item.Sku,
                item_name: item.Name,
                price: item.Price
              }]
            }
          })
        }
      } catch { /*Fait rien*/ }
    },
    async trackClick(product, query) {
      const extension = this.getStore() !== null ? Object.keys(stores)[this.$root.getStore() - 1] : 'NA'
      const productId = product.sku + '.' +  extension
      try {
        await Vue.axios.post(`${window.laferte.searchConfig.endpointBase}/api/as/v1/engines/${window.laferte.searchConfig.engineName}/click.json`, {
          document_id: productId,
          query: query || '',
          request_id: '',
          tags: getClickTags()
        }, { headers: { Authorization: `Bearer ${window.laferte.searchConfig.searchKey}` } })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Search click tracking unsuccessful', error)
      }
    },
    truncate(value) {
      return value < 0 ? Math.ceil(value) : Math.floor(value)
    }
  },
  computed: {
    ...mapGetters('cartModule', ['count']),
    ...mapState('globalModule', ['storeChangeWarningModal']),
    hasStoreSelected() {
      return this.getStore() !== null
    },
    selectedStoreName() {
      const storeCode = Object.keys(stores).find(key =>
        stores[key] === this.$root.getStore())

      return !storeCode ? '' : this.$t(`stores.${storeCode}`)
    }
  },
  provide() {
    return {
      fallbackProductImageUrl: '/images/image-default.png'
    }
  },
  mixins: [orderMixins]
}).$mount('#app')

window.vue = vue
