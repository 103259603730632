/* eslint-disable linebreak-style */
/*
  Transforme les résultats d'ElasticSearch AppSearch en modèle 
  Laferté pour être rétrocompatible avec nos ancienne recherche
*/

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const promotionEndDateThreshold = new Date().setFullYear(new Date().getFullYear() + 1)
function getEndDate(item) {
  if (!item['price.endDate'] || !item['price.endDate'].raw) {
    return null
  }
  const stringValue = item['price.endDate'].raw
  let date = Date.parse(stringValue)
  if (isNaN(date)) {
    // eslint-disable-next-line no-console
    console.warn(`${stringValue} is not a valid date for the product SKU "${item['sku'].raw}" at store "${item['store'] && item['store'].raw ? item['store'].raw : 'no store'}".`)
    return null
  } else {
    date = new Date(date)
  }
  if (item['price.endDate'] && item['price.endDate'].raw && date < promotionEndDateThreshold) {
    return item['price.endDate'].raw
  } else {
    return null
  }
}

function getDiscountType(item) {
  if (item['price.displayedDiscountType'] && item['price.displayedDiscountType'].raw >= 0) {
    return item['price.displayedDiscountType'].raw
  } else if (item['price.discountType'] && item['price.discountType'].raw >= 0) {
    return item['price.discountType'].raw
  }
  return null
}

function getDiscountValue(item) {
  if (item['price.displayedDiscounted'] && item['price.displayedDiscounted'].raw >= 0) {
    return item['price.displayedDiscounted'].raw
  } else if (item['price.discountValue'] && item['price.discountValue'].raw >= 0) {
    return item['price.discountValue'].raw
  }
  return null
}

// TODO Mapping de ProductModel (à finaliser, reste plusieurs champs à corriger)
// export const getProductItems = (productItems, selectedStore) => {
//   if (!Array.isArray(productItems)) {
//     return [];
//   }

//   return productItems.map(item => {
//           // eslint-disable-next-line no-console
//     console.warn(item);
//     let baseProduct = {
//       brand: item.brand.raw,
//       collection: item.collection.raw,
//       color: item.color.raw,
//       culture: item.culture.raw,
//       description: item.description.raw,
//       dimension: item.dimension.raw,
//       format: item.format.raw,
//       id: item.id.raw,
//       identifier: item.identifier.raw,
//       image: {
//         alt: item['images.alt'].raw,
//         order: item['images.order'].raw,
//         url: item['images.url'].raw
//       },
//       intermediateCategory: item.intermediateCategory.raw,
//       hasPromotion: item.hasPromotion.raw,
//       name: item.name.raw,
//       omniCategory: item.omniCategory.raw,
//       price: {
//         discounted: item.price && item.price.discounted && item.price.discounted.raw >= 0 ? item.price.discounted.raw : null,
//         discountType: getDiscountType(item),
//         discountValue: getDiscountValue(item),
//         store: item.store && item.store.raw ? item.store.raw : null,
//         startDate: item.price && item.price.startDate && item.price.startDate.raw ? item.price.startDate.raw : null,
//         endDate: getEndDate(item),
//         value: item.price && item.price.value && item.price.value.raw >= 0 ? item.price.value.raw : null,
//       },
//       promotionTag: item.promotionTag.raw,
//       sku: item.sku.raw,
//       tags: item.tags.raw,
//       url: `/${process.env.VUE_APP_PRODUCTS_URL_PREFIX}${item.url.raw}`
//     };

//     let quantities = getProductQuantities(item);

//     let internalStatuses = [];
//     // Dans ElasticSearch AppSearch, les propriétés imbriquées sont formées en clés séparées par des "."
//     ['internalStatuses.1', 'internalStatuses.2', 'internalStatuses.3', 'internalStatuses.4'].forEach(internalStatusKey => {
//       if (item[internalStatusKey]) {
//         internalStatuses.push({
//           store: parseInt(internalStatusKey.split('.')[1]), // Le chiffre après le "internalStatuses" (ex: "internalStatuses.2") représente la valeur de l'enum de la succursale
//           value: item[internalStatusKey].raw
//         });
//       }
//     });

//     var t = {
//       ...baseProduct,
//       internalStatuses,
//       quantities,
//       quantity: (quantities.find(qty => qty.store === selectedStore) || {}).value
//     };
//       // eslint-disable-next-line no-console
//     console.warn(t);

//     return {
//       ...baseProduct,
//       internalStatuses,
//       quantities,
//       quantity: (quantities.find(qty => qty.store === selectedStore) || {}).value
//     };
//   });
// };

export const getSummarizedProductsItemFacets = (productItems, selectedStore) => {
  if (!Array.isArray(productItems)) {
    return []
  }

  return productItems.map(item => {
    let isDeleted = false
    if (item.deleted) {
      isDeleted = item.deleted.raw === 1
    }

    let isItemDiscontinuedOrOffSeason = false
    if (item.isDiscontinuedOrOffSeason) {
      isItemDiscontinuedOrOffSeason = item.isDiscontinuedOrOffSeason.raw === 1
    }

    return {
      bestPrice: item['price.bestPrice'] && item['price.bestPrice'].raw >= 0 ? item['price.bestPrice'].raw : null,
      deleted: isDeleted,
      hasPromotion: item.hasPromotion.raw === 1,
      identifier: item.identifier.raw,
      image: getAppSearchImage(item),
      internalStatuses: getProductInternalStatuses(item),
      isItemDiscontinuedOrOffSeason: isItemDiscontinuedOrOffSeason,
      majorCategory: item.majorCategory.raw,
      name: item.name.raw,
      omniCategory: item.omniCategory.raw,
      price: item['price.value'] && item['price.value'].raw >= 0 ? item['price.value'].raw : null,
      priceTag: item.promotionTag && item.promotionTag.raw ? item.promotionTag.raw : null,
      quantities: getProductQuantities(item),
      sku: item.sku.raw,
      status: item.status.raw,
      tags: item.tags.raw,
      url: `/${process.env.VUE_APP_PRODUCTS_URL_PREFIX}${item.url.raw}`
    }
  })
}

export const getProductItemFacets = (productItems, selectedStore) => {
  if (!Array.isArray(productItems)) {
    return []
  }

  return productItems.map(item => {
    let isDeleted = false
    if (item.deleted) {
      isDeleted = item.deleted.raw === 1
    }

    let isItemDiscontinuedOrOffSeason = false
    if (item.isDiscontinuedOrOffSeason) {
      isItemDiscontinuedOrOffSeason = item.isDiscontinuedOrOffSeason.raw === 1
    }

    let baseProduct = {
      brand: item.brand.raw,
      bestPrice: item['price.bestPrice'] && item['price.bestPrice'].raw >= 0 ? item['price.bestPrice'].raw : null,
      deleted: isDeleted,
      discountAmount: item['price.value'] && item['price.discounted'] && item['price.discounted'].raw >= 0 ? item['price.discounted'].raw : null,
      discountType: getDiscountType(item),
      discountValue: getDiscountValue(item),
      hasPromotion: item.hasPromotion.raw === 1,
      identifier: item.identifier.raw,
      image: {
        alt: item['images.alt'] ? item['images.alt'].raw : null,
        order: item['images.order'] ? item['images.order'].raw : null,
        url: item['images.url'] ? item['images.url'].raw : null
      },
      intermediateCategory: item.intermediateCategory.raw,
      internalStatuses: getProductInternalStatuses(item),
      isItemDiscontinuedOrOffSeason: isItemDiscontinuedOrOffSeason,
      isBarcodeItem: item.isBarcodeItem && item.isBarcodeItem.raw >= 0 ? item.isBarcodeItem.raw === 1 : false,
      isPriceInStoreOnly: item.isPriceInStoreOnly && item.isPriceInStoreOnly.raw >= 0 ? item.isPriceInStoreOnly.raw === 1 : false,
      modificationDate: item.modificationDate.raw,
      majorCategory: item.majorCategory.raw,
      minorCategory: item.minorCategory.raw,
      name: item.name.raw,
      omniCategory: item.omniCategory.raw,
      price: item['price.value'] && item['price.value'].raw >= 0 ? item['price.value'].raw : null,
      priceTag: item.promotionTag && item.promotionTag.raw ? item.promotionTag.raw : null,
      status: item.status.raw,
      sku: item.sku.raw,
      tags: item.tags.raw,
      url: `/${process.env.VUE_APP_PRODUCTS_URL_PREFIX}${item.url.raw}`
    }

    let quantities = getProductQuantities(item);

    return {
      ...baseProduct,
      quantities,
      quantity: (quantities.find(qty => qty.store === selectedStore) || {}).value
    }
  })
}

export const getProductFacets = (productFacets) => {
  if (typeof productFacets !== 'object') {
    return []
  }

  const facets = []

  for (const [field, value] of Object.entries(productFacets)) {
    value[0].data.forEach(facetDataItem => {
      facets.push({
        name: capitalizeFirstLetter(field), // Majuscule au début du nom pour être rétrocompatible
        count: facetDataItem.count,
        value: facetDataItem.value
      })
    })
  }

  return facets.sort(function (a, b) {
    // Mettre les valeurs vides à la fin des résultats
    // Dans notre cas, les valeurs vides, c'est les facettes "Autres"
    if (a.value === '' || a.value === null) return 1
    if (b.value === '' || b.value === null) return -1
    if (a.value === b.value) return 0
    return a.value < b.value ? -1 : 1
  })
}

export const getUniqueProducts = (products) => {
  if(!Array.isArray(products)) {
    return []
  }

  const distinctMap = products.reduce((mapping, product) => {
    if(!mapping.has(product)) {
      mapping.set(product.sku, product)
    }
    return mapping
  }, new Map())

  return Array.from(distinctMap.values())
}

const getAppSearchImage = (product) => {
  if (product['images.url'] === undefined || product['images.url'] === null) {
    return null
  }

  if(!Array.isArray(product['images.url'].raw)) {
    return {
      alt: product['images.alt'].raw,
      order: product['images.order'].raw,
      url: product['images.url'].raw
    }
  }

  const imageOrder = product['images.order']
  let imageOrderIndex = -1
  
  if (imageOrder && imageOrder.raw) {
    imageOrderIndex = imageOrder.raw.indexOf(0)
  }

  const indexOfOrderZero = imageOrderIndex !== -1 ? imageOrderIndex : 0

  return {
    alt: product['images.alt'].raw[indexOfOrderZero],
    order: 0,
    url: product['images.url'].raw[indexOfOrderZero]
  }
}

const getProductInternalStatuses = (product) => {
  let internalStatuses = [];
  // Dans ElasticSearch AppSearch, les propriétés imbriquées sont formées en clés séparées par des "."
  ['internalStatuses.1', 'internalStatuses.2', 'internalStatuses.3'].forEach(statusKey => {
    if (product[statusKey]) {
      internalStatuses.push({
        store: parseInt(statusKey.split('.')[1]), // Le chiffre après le "internalStatuses" (ex: "internalStatuses.2") représente la valeur de l'enum de la succursale
        value: product[statusKey].raw
      })
    }
  })

  return internalStatuses;
}

const getProductQuantities = (product) => {
  let quantities = [];
  // Dans ElasticSearch AppSearch, les propriétés imbriquées sont formées en clés séparées par des "."
  ['quantities.1', 'quantities.2', 'quantities.3'].forEach(quantityKey => {
    if (product[quantityKey]) {
      quantities.push({
        inStock: product[quantityKey].raw > 0,
        store: parseInt(quantityKey.split('.')[1]), // Le chiffre après le "quantities" (ex: "quantities.2") représente la valeur de l'enum de la succursale
        value: product[quantityKey].raw
      })
    }
  })

  return quantities;
}
