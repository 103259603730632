var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("form", { attrs: { id: "checkout-form" } }, [
        _c("div", { staticClass: "firstRow" }, [
          _c("label", { attrs: { for: "card-number" } }, [
            _vm._v(_vm._s(_vm.$t("bambora.label.cardNumber"))),
          ]),
          _c(
            "div",
            { ref: "cardNumberParentElement", attrs: { id: "card-number" } },
            [
              _vm.isErrorCardNumber == true
                ? _c("span", { staticClass: "cardError" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(`bambora.errorTypes.${_vm.cardNumberError}`)
                      )
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _c("div", { staticClass: "seccondRow" }, [
          _c("div", { staticClass: "expiry" }, [
            _c("label", { attrs: { for: "card-expiry" } }, [
              _vm._v(_vm._s(_vm.$t("bambora.label.expiry"))),
            ]),
            _c(
              "div",
              { ref: "expiryParentElement", attrs: { id: "card-expiry" } },
              [
                _vm.isErrorExpiry == true
                  ? _c("span", { staticClass: "cardError" }, [
                      _vm._v(
                        _vm._s(_vm.$t(`bambora.errorTypes.${_vm.expiryError}`))
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _c("div", { staticClass: "cvv" }, [
            _c("label", { attrs: { for: "card-cvv" } }, [
              _vm._v(_vm._s(_vm.$t("bambora.label.cvv"))),
            ]),
            _c("div", { ref: "cvvParentElement", attrs: { id: "card-cvv" } }, [
              _vm.isErrorCVV == true
                ? _c("span", { staticClass: "cardError" }, [
                    _vm._v(
                      _vm._s(_vm.$t(`bambora.errorTypes.${_vm.CVVError}`))
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "thirdRow" }, [
          _c("div", [
            _c("label", { attrs: { for: "card-holder" } }, [
              _vm._v(_vm._s(_vm.$t("bambora.label.cardholderName"))),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cardholderName,
                    expression: "cardholderName",
                  },
                ],
                attrs: { id: "card-holder", type: "text" },
                domProps: { value: _vm.cardholderName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.cardholderName = $event.target.value
                  },
                },
              }),
              _vm.isErrorCardholder
                ? _c("span", { staticClass: "cardError" }, [
                    _vm._v(_vm._s(_vm.$t(`bambora.errorTypes.cardholder`))),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "btn",
          attrs: { disabled: _vm.isPaymentInfoSaving || _vm.isProcessingOrder },
          on: { click: _vm.submit },
        },
        [
          _vm._v(_vm._s(_vm.buttonText || _vm.$t("bambora.submitButtonText"))),
          _vm.isPaymentInfoSaving || _vm.isProcessingOrder
            ? _c("img", {
                staticClass: "loading",
                attrs: { src: "/images/loading.gif" },
              })
            : _vm._e(),
        ]
      ),
      _vm.isInvalidOrIncomplete && !_vm.errorAsEvents
        ? _c("modal", {
            attrs: { isPopin: true, "click-outside-enabled": true },
            on: {
              onclose: function ($event) {
                return _vm.close()
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("bambora.modal.title")))]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", [_vm._v(_vm._s(_vm.$t("bambora.modal.body")))]),
                      _c(
                        "button",
                        { staticClass: "btn", on: { click: _vm.close } },
                        [_vm._v(_vm._s(_vm.$t("bambora.modal.close")))]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              38758139
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }