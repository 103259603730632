<!-- eslint-disable -->
<template>
  <div class="staticDropDownFacet">
    <label v-if="header" class="facetTitle">{{ header }}</label>
    <div class="divSelect dropdownStyle">
      <select v-model="selectedFacet">
        <option v-for="value in facets"
                :key="value[displayNameProperty]"
                :value="value[valueProperty]">
          {{ value[displayNameProperty] }}
        </option>
      </select>
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>

import hashMixins from '@/hashMixins';

  export default {
    props: {
      allValue: {
        type: Object
      },
      defaultFacetKey: {
        type: String
      },
      displayNameProperty: {
        type: String,
        default: 'displayName'
      },
      facetProperty: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: false
      },
      itemsContainer: {
        type: Object,
        required: true
      },
      keyProperty: {
        type: String,
        default: 'key'
      },
      valueProperty: {
        type: String,
        default: 'value'
      },
      values: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        selectedFacet: null
      };
    },
    created() {
      const defaultFacet = this.facets.find(facet => 
        facet[this.keyProperty] === this.defaultFacetKey);

      this.selectedFacet = defaultFacet 
        ? defaultFacet[this.valueProperty]
        : this.facets[0][this.valueProperty];
        
      const hashParams = this.getHashParams();
      if (hashParams[this.facetProperty]) {
        this.selectedFacet = hashParams[this.facetProperty];
        refreshSelectedFacets();
      }
    },
    methods: {
      selectFacet(facet) {
        this.selectedFacet = facet;
      },
      refreshSelectedFacets() {
        this.itemsContainer.selectedFacets = {
          ...this.itemsContainer.selectedFacets,
          [this.facetProperty]: this.selectedFacet
        };
      }
    },
    computed: {
      facets() {
        if (this.allValue) {
          return [this.allValue].concat(this.values);
        }

        return this.values;
      }
    },
    watch: {
      'itemsContainer.items'() {
        this.$root.$emit('scroll');
      },
      selectedFacet() {
        this.refreshSelectedFacets();
      }
    },
    mixins: [hashMixins]
  };
</script>
