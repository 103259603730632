<template>
  <validation-observer class="editDefaultBranchInfoForm accountForm" ref="editDefaultBranchInfoForm" tag="form" @submit.prevent="handleSaveDefaultBranchInfo" novalidate>
    <validation-provider class="formItem" tag="div" name="branchId" rules="required" v-slot="{ errors }">
      <label for="editDefaultBranchInfoForm-branchSelect">{{ $t('users.fields.defaultBranch') }}*</label>
      <custom-select v-model="selectedBranchId" :options="branchOptions"></custom-select>
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
    </validation-provider>

    <branch-address :branch="selectedBranch" :hideName="true"></branch-address>
    
    <div class="formItem">
      <working-button class="btn" :isWorking="defaultBranchInfoIsSaving" :label="$t('users.actions.save')" type="submit"></working-button>
      <div v-if="saveDefaultBranchInfoError" class="formItem-errorMessage error">{{ saveDefaultBranchInfoError }}</div>
      <div v-if="saveDefaultBranchInfoMessage" class="actionResult">{{ saveDefaultBranchInfoMessage }}</div>
    </div>
  </validation-observer>
</template>

<script>
import { setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'
import WorkingButton from './WorkingButton.vue'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'
import BranchAddress from '../Account/BranchAddress.vue'
import CustomSelect from './CustomSelect.vue'

setInteractionMode('eager')

export default {
  components: {
    BranchAddress,
    CustomSelect,
    ValidationObserver,
    ValidationProvider,
    WorkingButton
  },
  props: {
    branchId: {
      type: Number
    },
    branches: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedBranchId: this.branchId,
      defaultBranchInfoIsSaving: false,
      saveDefaultBranchInfoError: undefined,
      saveDefaultBranchInfoMessage: undefined
    }
  },
  methods: {
    async handleSaveDefaultBranchInfo() {
      this.resetActionMessages()

      if(!await this.$refs.editDefaultBranchInfoForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.editDefaultBranchInfoForm.$el, '.invalid')
        return
      }

      try {
        this.defaultBranchInfoIsSaving = true
        const request = {
          memberId: this.user.contact.memberID,
          defaultBranch: this.selectedBranchId
        }
        const response = await this.$axios.post('/Umbraco/Api/User/SetDefaultBranch', request)
        this.saveDefaultBranchInfoMessage = this.$t('users.messages.success.editDefaultBranchInfo')
        this.$emit('success:updateDefaultBranch', this.selectedBranchId)
      } catch (error) {
        this.saveDefaultBranchInfoError = this.$t('users.messages.SaveDefaultBranchInfoException')
      } finally {
        this.defaultBranchInfoIsSaving = false
      }
    },
    resetForm() {
      this.selectedBranchId = this.branchId
      this.resetActionMessages()

      this.$nextTick(() => {
        this.$refs.editDefaultBranchInfoForm.reset()
      })
    },
    resetActionMessages() {
      this.saveDefaultBranchInfoError = undefined
      this.saveDefaultBranchInfoMessage = undefined
    }
  },
  computed: {
    branchOptions() {
      return this.branches.map(branch => ({
        label: branch.name,
        value: branch.branchID
      }))
    },
    selectedBranch() {
      return this.branches.find(branch => branch.branchID === this.selectedBranchId)
    }
  }
}
</script>