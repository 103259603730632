var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "headerPanel" }, [
    _c("div", { staticClass: "panelCartCloser" }, [
      _c(
        "svg",
        {
          attrs: {
            width: "17",
            height: "16",
            viewBox: "0 0 17 16",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M15.5312 12.9688C16.1406 13.5312 16.1406 14.5156 15.5312 15.0781C15.25 15.3594 14.875 15.5 14.5 15.5C14.0781 15.5 13.7031 15.3594 13.4219 15.0781L8.5 10.1562L3.53125 15.0781C3.25 15.3594 2.875 15.5 2.5 15.5C2.07812 15.5 1.70312 15.3594 1.42188 15.0781C0.8125 14.5156 0.8125 13.5312 1.42188 12.9688L6.34375 8L1.42188 3.07812C0.8125 2.51562 0.8125 1.53125 1.42188 0.96875C1.98438 0.359375 2.96875 0.359375 3.53125 0.96875L8.5 5.89062L13.4219 0.96875C13.9844 0.359375 14.9688 0.359375 15.5312 0.96875C16.1406 1.53125 16.1406 2.51562 15.5312 3.07812L10.6094 8.04688L15.5312 12.9688Z",
              fill: "#E31F28",
            },
          }),
        ]
      ),
    ]),
    _c("div", { staticClass: "title" }, [_vm._t("default")], 2),
    _c(
      "div",
      { staticClass: "subTotal" },
      [
        !this.$root.getFeatureFlag("hidePrices")
          ? [
              _c("div", { staticClass: "label" }, [_vm._v(" Sous-total ")]),
              _c("div", { staticClass: "subTotalValue" }, [
                _vm._v(_vm._s(_vm.$root.formatPrice(_vm.subTotal()))),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }