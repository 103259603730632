<template>
  <div class="promotionEndDate" v-if="getPromotionEndDate(product) !== null">
    {{ getPromotionEndDate(product) }}
  </div>
</template>

<script>
import productMixins from '@/productMixins';

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  mixins: [productMixins]
};
</script>
