<template>
  <div class="orderCreditCard">
    <div>
      <p>
        <strong>{{ $t('order.payment.creditCard') }}</strong>
      </p>
      <global-payment-form v-if="isUsingBambora === false" />
      <BamboraForm v-if="isUsingBambora === true" @submit="bamboraSubmit" />
      <vue-recaptcha
        v-if="recaptchaEnabled"
        :sitekey="recaptchaSiteKey"
        ref="captcha"
        @verify="setCaptchaToken"
        @expired="resetCaptcha"
      />
    </div>
  </div>
</template>

<script>
import GlobalPaymentForm from './GlobalPaymentForm.vue';
import VueRecaptcha from 'vue-recaptcha';
import { mapActions, mapGetters } from 'vuex';
import BamboraForm from './BamboraForm.vue';

export default {
  components: {
    BamboraForm,
    GlobalPaymentForm,
    VueRecaptcha
  },
  props: {
    addressCompleteSettings: {
      type: Object,
      required: true
    },
    recaptchaEnabled: {
      type: Boolean,
      default: false
    },
    recaptchaSiteKey: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('cartModule', ['isDelivery']),
    isUsingBambora() {
      return this.$root.getFeatureFlag('bambora');
    }
  },
  methods: {
    ...mapActions('cartModule', ['setCaptchaToken']),
    resetCaptcha() {
      this.setCaptchaToken(null);
      this.$refs.captcha.reset();
    },
    bamboraSubmit(result) {
      this.$emit('submit', result)
    }
  }
};
</script>
