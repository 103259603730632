<template>

<div class="userAccount">
  <div v-if="isLoadingUser" class="loading">
    <img src="/images/triangle-loading.gif" />
  </div>
  <template v-else-if="!user">
    <span v-html="$t('users.messages.getUserError')"></span>
  </template>
  <template v-else>  
    <section class="userInfoSection">
      <h2 class="userInfoSection-heading">{{ $t('users.titles.userInfo') }}</h2>
      <template>
        <user-info :user="user" />
        <hr />
        <email-info v-if="user.accountProvider == null" :user="user" />
        <hr v-if="user.accountProvider == null" />
        <password-info v-if="user.accountProvider == null" :user="user" :connection-url="connectionUrl" />
        <hr v-if="user.accountProvider == null" />
        <payment-methods-info :bambora-configuration="bamboraConfiguration" :user="user" v-if="userPaymentIsEnabled && useBambora" />
        <hr v-if="userPaymentIsEnabled && useBambora" />
        <billing-info :user="user" v-if="userPaymentIsEnabled"></billing-info>
        <hr v-if="userPaymentIsEnabled" />
        <default-branch-info :user="user" :branches="branches"></default-branch-info>
        <hr />
        <shipping-info :user="user"></shipping-info>
      </template>
    </section>

    <!-- <OrdersSummary /> -->
    <section class="orderSummariesSection">
      <h2 ref="ordersSection">{{ $t('account.purchaseTitle') }}</h2>
      <order-summaries :orderSummaries="user.orders"></order-summaries>
    </section>
  </template>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import BillingInfo from '../Account/BillingInfo.vue'
import UserInfo from '../Account/UserInfo.vue'
import PasswordInfo from '../Account/PasswordInfo.vue'
import DefaultBranchInfo from '../Account/DefaultBranchInfo.vue'
import PaymentMethodsInfo from '../Account/PaymentMethodsInfo.vue'
import ShippingInfo from '../Account/ShippingInfo.vue'
import OrderSummaries from '../Account/OrderSummaries.vue'
import EmailInfo from '../Account/EmailInfo'

export default {
  props: {
    addressCompleteSettings: {
      type: Object,
      required: true
    },
    bamboraConfiguration: {
      type: Object,
      required: true
    },
    branches: {
      type: Array,
      required: true
    },
    memberId: {
      type: Number,
      required: true
    },
    useBambora: {
      type: Boolean,
      default: false
    },
    userPaymentIsEnabled: {
      type: Boolean,
      default: false
    },
    connectionUrl: {
      type: String,
      required: true
    }
  },
  components: {
    BillingInfo,
    DefaultBranchInfo,
    EmailInfo,
    PasswordInfo,
    PaymentMethodsInfo,
    ShippingInfo,
    TheMask,
    UserInfo,
    OrderSummaries
  },
  data() {
    return {
      emailToken: null,
      isLoadingUser: false,
      isSendingUpdateEmail: false,
      IsUpdatingEmail: false,
      logoutUserForm: undefined,
      newEmail: null,
      orderSummaries: null,
      searchTerm: '',
      status: 0,
      user: null,
      sendUpdateEmailValidationErrorMessage: undefined,
      updateEmailValidationErrorMessage: undefined
    }
  },
  async mounted() {
    await this.getUser()
    this.logoutUserForm = document.querySelector('form[action="/zone-securisee/mon-compte"]')
    if (location.href.includes('#ordersSection') && this.$refs.ordersSection != null) {
      this.$refs.ordersSection.scrollIntoView({ behavior: 'smooth' })
    }
  },
  methods: {
    async sendUpdateEmail() {
      this.isSendingUpdateEmail = true
      const model = {
        oldEmail: this.user.contact.email,
        newEmail: this.newEmail
      }
      try {
        const response = await this.$axios.post('/Umbraco/Api/Authentication/SendUpdateEmail', model)

      } catch (error) {
        this.errorType =
          error && error.response && error.response.data
            ? error.response.data
            : null
        
        this.sendUpdateEmailValidationErrorMessage = this.$t(`accountValidationModal.${this.errorType}`)
      } finally {
        this.isSendingUpdateEmail = false
      }

    },
    async updateEmail() {
      this.IsUpdatingEmail = true
      const model = {
        oldEmail: this.user.contact.email,
        newEmail: this.newEmail,
        token: this.emailToken
      }
      try {
        const response = await this.$axios.post('/Umbraco/Api/Authentication/updateEmail', model)
       
      } catch (error) {
        this.errorType =
          error && error.response && error.response.data
            ? error.response.data
            : null
        
        this.updateEmailValidationErrorMessage = this.$t(`accountValidationModal.${this.errorType}`)
      } finally {
        this.IsUpdatingEmail = false
      }
    },
    async getUser() {
      if (!this.memberId) {
        return null
      }

      try {
        this.isLoadingUser = true

        const response = await this.$axios.get(`/Umbraco/Api/User/GetUserProfile?memberId=${this.memberId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        if (response.status === 200) {
          this.user = response.data
        }
      }
      catch(e) {
        console.log(e)
      }
      finally {
        this.isLoadingUser = false
      }
    }
  },
  computed: {
    sameAsPaymentAddress() {
      if (!this.user || !this.user.addresses) {
        return false
      }

      return this.user.defaultPaymentAddressID && this.user.defaultPaymentAddressID === this.user.defaultShippingAddressID
    }
  },
  provide() {
    return {
      addressCompleteSettings: this.addressCompleteSettings
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
