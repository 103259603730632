<script>
import MultipleSelectionFacet from './MultipleSelectionFacet.vue';

export default {
  extends: MultipleSelectionFacet,
  methods: {
    toggleFacet(facet) {
      if (this.selectedFacets.indexOf(facet) === -1) {
        this.selectedFacets = [ facet ];
      } else {
        this.selectedFacets = [];
      }
      this.refreshSelectedFacets();
    }
  }
};
</script>
