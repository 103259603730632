var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cartProductsContent" }, [
    _vm.count <= 0
      ? _c("div", { staticClass: "hasNotProducts" }, [
          _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "content" }, [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.$root.getFeatureFlag("ecommerce")
                      ? _vm.$t("cart.empty")
                      : _vm.$t("cart.emptyList")
                  )
                ),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          [
            _vm._t("cta-cart"),
            _c("div", { staticClass: "productList" }, [
              _c("div", [
                _vm.isLoading
                  ? _c("div", { staticClass: "loading" }, [
                      _c("img", {
                        attrs: { src: "/images/triangle-loading.gif" },
                      }),
                    ])
                  : _c(
                      "div",
                      [
                        _vm.products.some((x) => x.isBarcodeItem) &&
                        _vm.products.some((x) => !x.isBarcodeItem)
                          ? _c("div", { staticClass: "billSplitMentionning" }, [
                              _c("img", {
                                attrs: { src: "/images/info-icon.png" },
                              }),
                              _c("p", { staticClass: "firstLine" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("product.billSplitMention"))
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._l(_vm.products, function (product) {
                          return _c("cart-product", {
                            key: product.Identifier,
                            attrs: {
                              "disable-cart-product": _vm.isUnavailable,
                              getProductQuantity: _vm.getProductQuantity,
                              product: product,
                            },
                            on: {
                              deleteProduct: _vm.deleteProduct,
                              quantityChanged: _vm.onQuantityChanged,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "productInfosStore" },
                                        [
                                          _vm.isBarcodeItem(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "inStock" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "product.availability.inStock"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : !_vm.isUnavailable(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "inStock" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "product.availability.inStockWithValue",
                                                          {
                                                            value:
                                                              _vm.getQuantity(
                                                                props.product
                                                              ).value,
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : !_vm.isInactive(props.product) &&
                                              !_vm.isInStock(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "notInStock" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "product.availability.notInStock"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm.isPromotional(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "unavailable" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "product.availability.promotional"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm.isRecall(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "unavailable" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "product.availability.recall"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "unavailable" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "product.availability.unavailable"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                      ],
                      2
                    ),
              ]),
            ]),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }