var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { wishlist: true, productAdded: _vm.count > 0 } },
    [
      _vm.count > 0
        ? _c("span", { class: { count: true, small: _vm.count > 99 } }, [
            _vm._v(_vm._s(_vm.count)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "titleSidePanelCart" },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "23",
                height: "21",
                viewBox: "0 0 23 21",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M3.125 0.5C3.39844 0.5 3.63281 0.734375 3.71094 1.00781L3.90625 1.75H21.1328C21.9531 1.75 22.5781 2.57031 22.3438 3.35156L20.2344 10.8516C20.0781 11.3984 19.6094 11.75 19.0234 11.75H6.17188L6.71875 14.25H19.375C19.6875 14.25 20 14.5625 20 14.875C20 15.2266 19.6875 15.5 19.375 15.5H6.25C5.9375 15.5 5.70312 15.3047 5.625 15.0312L2.61719 1.75H0.625C0.273438 1.75 0 1.47656 0 1.125C0 0.8125 0.273438 0.5 0.625 0.5H3.125ZM4.17969 3L5.85938 10.5H19.0234L21.1328 3H4.17969ZM5 18.3125C5 17.1406 5.97656 16.125 7.1875 16.125C8.35938 16.125 9.375 17.1406 9.375 18.3125C9.375 19.5234 8.35938 20.5 7.1875 20.5C5.97656 20.5 5 19.5234 5 18.3125ZM7.1875 19.25C7.69531 19.25 8.125 18.8594 8.125 18.3125C8.125 17.8047 7.69531 17.375 7.1875 17.375C6.64062 17.375 6.25 17.8047 6.25 18.3125C6.25 18.8594 6.64062 19.25 7.1875 19.25ZM20 18.3125C20 19.5234 18.9844 20.5 17.8125 20.5C16.6016 20.5 15.625 19.5234 15.625 18.3125C15.625 17.1406 16.6016 16.125 17.8125 16.125C18.9844 16.125 20 17.1406 20 18.3125ZM17.8125 17.375C17.2656 17.375 16.875 17.8047 16.875 18.3125C16.875 18.8594 17.2656 19.25 17.8125 19.25C18.3203 19.25 18.75 18.8594 18.75 18.3125C18.75 17.8047 18.3203 17.375 17.8125 17.375Z",
                  fill: "#2A2A2A",
                },
              }),
            ]
          ),
          _vm._t("title-panel-label", function () {
            return [_vm._v(_vm._s(_vm.$t("order.myCart2")))]
          }),
          _vm._v(" "),
          _vm.count > 0
            ? _c("span", { class: { count: true, small: _vm.count > 99 } }, [
                _vm._v("(" + _vm._s(_vm.count) + ")"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }