var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accountForm-container" },
    [
      !_vm.editEmailHasSucceeded
        ? [
            _c(
              "validation-observer",
              {
                ref: "sendCodeForm",
                staticClass: "accountForm sendCodeForm",
                attrs: { tag: "form", novalidate: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.sendActivationCode.apply(null, arguments)
                  },
                },
              },
              [
                _c("validation-provider", {
                  ref: "newEmailRef",
                  staticClass: "formItem",
                  attrs: {
                    tag: "div",
                    name: "newEmail",
                    rules: "required|email|max:255",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("users.fields.newEmail"))),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newEmail,
                                  expression: "newEmail",
                                },
                              ],
                              class: { invalid: errors.length },
                              attrs: {
                                type: "email",
                                autocomplete: "email",
                                maxlength: "255",
                              },
                              domProps: { value: _vm.newEmail },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.newEmail = $event.target.value
                                },
                              },
                            }),
                            errors.length
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3183572991
                  ),
                }),
                _c(
                  "div",
                  { staticClass: "formItem" },
                  [
                    _c("working-button", {
                      staticClass: "btn",
                      attrs: {
                        isWorking: _vm.isSendingActivationCode,
                        label: _vm.sendCodeLabel,
                        type: "submit",
                      },
                    }),
                    _vm.isActivationCodeSent
                      ? _c("div", { staticClass: "actionResult" }, [
                          _vm._v(
                            _vm._s(_vm.$t("users.messages.activationCodeSent"))
                          ),
                        ])
                      : _vm._e(),
                    _vm.sendCodeError
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.sendCodeError)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("hr"),
            _c(
              "validation-observer",
              {
                ref: "validateCodeForm",
                staticClass: "accountForm validateCodeForm",
                attrs: { tag: "form" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.validateActivationCode.apply(null, arguments)
                  },
                },
              },
              [
                _c("validation-provider", {
                  staticClass: "formItem",
                  attrs: {
                    tag: "div",
                    name: "editEmailCode",
                    rules: "required|max:6",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("label", [
                              _vm._v(
                                _vm._s(_vm.$t("users.fields.activationCode"))
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editEmailActivationCode,
                                  expression: "editEmailActivationCode",
                                },
                              ],
                              class: { invalid: errors.length },
                              attrs: { type: "text" },
                              domProps: { value: _vm.editEmailActivationCode },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.editEmailActivationCode =
                                    $event.target.value
                                },
                              },
                            }),
                            errors.length
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    649213335
                  ),
                }),
                _c(
                  "div",
                  { staticClass: "formItem" },
                  [
                    _c("working-button", {
                      staticClass: "btn",
                      attrs: {
                        isWorking: _vm.isValidatingCode,
                        label: _vm.validateCodeLabel,
                        type: "submit",
                      },
                    }),
                    _vm.validateCodeErrorType
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                `users.messages.${_vm.validateCodeErrorType}`
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "resetPasswordForm-successPanel" },
              [
                _c("circle-check", {
                  staticClass: "resetPasswordForm-successIcon",
                }),
                _c("div", { staticClass: "resetPasswordForm-successMessage" }, [
                  _vm._v(
                    _vm._s(_vm.$t("users.messages.success.editEmailInfo"))
                  ),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "btn resetPasswordForm-successConnect",
                    attrs: { href: "/connexion" },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.connectMe")))]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }