<template>
  <section class="account-billingInfo infoPanel">
    <div class="infoPanel-title">
      <h3 class="infoPanel-heading">{{ $t('users.titles.billingInfo') }}</h3>
    </div>

    <div class="infoPanel-data">
      <shipping-address-summary v-if="billingAddress" :address="billingAddress"></shipping-address-summary>
      <template v-else>
        <span>{{ $t("users.accountText.noBillingAddress") }}</span>
        <button class="infoPanel-action btnText" type="button" @click="handleShowEditBillingInfoDialog">{{ $t("users.actions.addBillingAddress") }}</button>
      </template>
    </div>

    <div class="infoPanel-actions">
      <button v-if="billingAddress" type="button" class="infoPanel-action btnText" @click="handleShowEditBillingInfoDialog">{{ $t('users.actions.edit') }}</button>
    </div>

    <teleport to='body'>
      <dialog-modal :isOpen="editBillingInfoDialogIsOpen" @close:dialog="handleCloseEditBillingInfoDialog" :title="$t('users.titles.billingInfoEdition')">
        <edit-billing-info :user="user" :addressInfo="billingAddress" ref="editBillingInfoForm" @success:updateBillingInfo="handleUpdateBillingInfoSuccess"></edit-billing-info>
      </dialog-modal>
    </teleport>
  </section>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import Teleport from 'vue2-teleport'
import EditBillingInfo from '../Forms/EditBillingInfo.vue'
import BranchAddress from './BranchAddress.vue'
import ShippingAddressSummary from './ShippingAddressSummary.vue'

export default {
  components: {
    DialogModal,
    Teleport,
    EditBillingInfo,
    BranchAddress,
    ShippingAddressSummary
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      billingAddress: this.getDefaultBillingAddress(),
      editBillingInfoDialogIsOpen: false
    }
  },
  methods: {
    getDefaultBillingAddress() {
      if(!this.user.defaultPaymentAddressID) {
        return undefined
      }

      return structuredClone(this.user.addresses.find(address => address.addressID === this.user.defaultPaymentAddressID))
    },
    handleCloseEditBillingInfoDialog() {
      this.editBillingInfoDialogIsOpen = false
      this.$refs.editBillingInfoForm.resetForm()
    },
    handleShowEditBillingInfoDialog() {
      this.editBillingInfoDialogIsOpen = true
    },
    handleUpdateBillingInfoSuccess(newAddress) {
      this.billingAddress = {...newAddress}
    }
  },
  computed: {
    
  }
}
</script>