<template>
  <section class="account-userInfo infoPanel">
    <div class="infoPanel-title">
      <h3 class="infoPanel-heading">{{ $t('users.titles.userInfo') }}</h3>
    </div>
    <div class="infoPanel-data">
      <address>
        {{ getUserFullName }}<br />
        <div v-if="userContact.phone">{{ formattedPhoneNumber }}</div>
      </address>
    </div>
    <div class="infoPanel-actions">
      <button type="button" class="infoPanel-action btnText" @click="handleShowEditUserInfoDialog">{{ $t('users.actions.edit') }}</button>
    </div>
    <teleport to='body'>
      <dialog-modal :isOpen="editUserInfoDialogIsOpen" @close:dialog="handleCloseEditUserInfoDialog" :title="$t('users.titles.userInfoEdition')">
        <edit-user-info :userContact="userContact" ref="editUserInfoForm" @success:updateContact="handleUpdateContactSuccess"></edit-user-info>
      </dialog-modal>
    </teleport>
  </section>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import Teleport from 'vue2-teleport'
import { formatPhoneNumber } from '../../helpers/stringHelper'
import EditUserInfo from '../Forms/EditUserInfo.vue'

export default {
  components: {
    DialogModal,
    Teleport,
    EditUserInfo
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editUserInfoDialogIsOpen: false,
      userContact: structuredClone(this.user.contact)
    }
  },
  methods: {
    handleCloseEditUserInfoDialog() {
      this.editUserInfoDialogIsOpen = false
      this.$refs.editUserInfoForm.resetForm()
    },
    handleShowEditUserInfoDialog() {
      this.editUserInfoDialogIsOpen = true
    },
    handleUpdateContactSuccess(contactInfo) {
      this.userContact.firstName = contactInfo.firstName
      this.userContact.lastName = contactInfo.lastName
      this.userContact.phone = contactInfo.phone
    }
  },
  computed: {
    formattedPhoneNumber() {
      return formatPhoneNumber(this.userContact.phone)
    },
    getUserFullName() {
      return `${this.userContact.firstName} ${this.userContact.lastName}`
    }
  }
}
</script>