<template>
  <div>
    <div id="pdfContent" v-if="summary" class="wrapper wrapper_small">
      <div class="summaryContainer">
        <a class="back" v-if="backLink" :href="backLink">
          <angle-left />
          <span>{{ $t('bill.backLink') }}</span>
        </a>
        <div class="billInfos">
          <div class="billInfos-title">
            <h1>{{ $t('bill.title') }}</h1>
          </div>
          <div class="purchaseHeader">
            <h2>{{ $t('bill.buyDate', { date: this.formatDate(summary.created, 'LL') }) }}</h2>
            <div v-if="isDelivered" class="status">{{ $t('bill.status.delivered') }}</div>
            <div v-else class="status">{{ $t('bill.status.processing') }} {{ $t('bill.shippingDate', {shippingDate: this.formatDate(summary.shipping.deliveryDate, 'Do MMMM') }) }}</div>
            <div class="details">
              <div v-if="summary.invoiceNumber" class="detailsTab">
                {{ $t('bill.billNumber') }} {{ summary.invoiceNumber }}
              </div>
              <div v-if="hasPaymentMethod" class="detailsTab">
                {{ $t('bill.paymentMethodLabel') }} {{ getPaymentMethod }}
              </div>
              <div v-if="hasShippingMethod" class="detailsTab">
                {{ $t('bill.shippingMethodLabel') }} {{ getShippingMethod }}
              </div>
            </div>
          </div>
        </div>
        <ul class="billProducts">
          <li class="billProduct" v-for="(product, index) in summary.items" :key="index">
            <purchase-details-product :product="product"></purchase-details-product>
          </li>
        </ul>
        <div class="prices">
          <div v-if="summary.shippingAmount > 0" class="shippingAmount">{{ $t('cart.shipping') }}: {{ shippingAmount }}</div>
          <div v-if="summary.shipping.extraFee > 0" class="shippingExtraFee">{{ $t('cart.shippingExtra') }}: {{ shippingExtraFee }}</div>
          <div class="subTotal">{{ $t('cart.subTotal') }}: {{ subTotal }}</div>
          <div class="gst">{{ $t('cart.gst') }} {{ gst }}</div>
          <div class="qst">{{ $t('cart.qst') }} {{ qst }}</div>
          <div class="prices-total">{{ $t('cart.totalBill') }} {{ total }} ({{ summaryTotalItems }} {{ $tc('bill.item', summaryTotalItems) }})</div>
          <div class="downloadBill"><button class="btnText" type="button" @click="download">{{ $t('bill.printBill') }}</button></div>
        </div>
        <template v-if="userPaymentIsEnabled">
          <div v-if="summary.payment !== null" class="payment">
            <div class="payment-title">
              <h3 class="infoPanel-heading">{{ $t('bill.paymentInformationsLabel') }}</h3>
            </div>
            <div class="payment-data">
              <!-- TODO ajouter numero de la carte -->
              <payment-informations :billing-address="summary.payment" :receipt="summary.bamboraReceipt"></payment-informations>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else>
      <div v-show="isLoading" class="loadingProduct">
        <img src="/images/triangle-loading.gif" />
      </div>
    </div>
  </div>
</template>

<script>
  import PaymentInformations from '@/components/Secure/PaymentInformations.vue'
  import PaymentMode from '@/models/cart/PaymentMode.js'
  import ShippingMethod from '@/models/store/ShippingMethod.js'
  import AngleLeft from '../Svg/AngleLeft.vue'
  import PurchaseDetailsProduct from '../Product/PurchaseDetailsProduct.vue'
  import { getFormattedCanadianDollars } from '../../helpers/stringHelper'
  import { getSummarizedProductsItemFacets } from '../../helpers/searchHelper'

  const paymentMethodLocaleMapping = new Map()
  paymentMethodLocaleMapping.set(PaymentMode.CreditCard, 'creditCard')
  paymentMethodLocaleMapping.set(PaymentMode.Account, 'account')
  
  const shippingMethodLocaleMapping = new Map()
  shippingMethodLocaleMapping.set(ShippingMethod.Delivery, 'delivery')
  shippingMethodLocaleMapping.set(ShippingMethod.Pickup, 'pickup')

  export default {
    props: {
      backLink: {
        type: String,
        required: true
      },
      userPaymentIsEnabled: {
        type: Boolean,
        default: false
      }
    },
    components: {
      AngleLeft,
      PaymentInformations,
      PurchaseDetailsProduct
    },
    data() {
      return {
        isLoading: true,
        summary: null,
        paymentMethod: PaymentMode,
        shippingMethod: ShippingMethod
      }
    },
    
    computed: {
      getPaymentMethod() {
        if(!paymentMethodLocaleMapping.has(this.summary.paymentMethod)) {
          return ''
        }

        return this.$t(`bill.paymentMethod.${paymentMethodLocaleMapping.get(this.summary.paymentMethod)}`)
      },
      gst() {
        return getFormattedCanadianDollars(this.summary.taxes.gst, this.$i18n.locale)
      },
      getShippingMethod() {
        if(!shippingMethodLocaleMapping.has(this.summary.shippingMethod)) {
          return ''
        }

        return this.$t(`bill.shippingMethod.${shippingMethodLocaleMapping.get(this.summary.shippingMethod)}`)
      },
      hasPaymentMethod() {
        return this.summary.paymentMethod !== null && this.summary.paymentMethod !== undefined
      },
      hasShippingMethod() {
        return this.summary.shippingMethod !== null && this.summary.shippingMethod !== undefined
      },
      isDelivered() {
        return this.summary.shippingMethod === this.shippingMethod.Pickup || this.checkDelivered
      },
      orderID() {
        return new URL(window.location.href).searchParams.get('orderid')
      },
      qst() {
        return getFormattedCanadianDollars(this.summary.taxes.qst, this.$i18n.locale)
      },
      shippingAmount() {
        return getFormattedCanadianDollars(this.summary.shippingAmount, this.$i18n.locale)
      },
      shippingExtraFee() {
        return getFormattedCanadianDollars(this.summary.shipping.extraFee, this.$i18n.locale)
      },
      subTotal() {
        return getFormattedCanadianDollars(this.summary.subTotal, this.$i18n.locale)
      },
      summaryTotalItems() {
        var total = 0
        if (this.summary && this.summary.items !== null) {
          for (const item of this.summary.items) {
            total += item.quantity
          }
        }
        return total
      },
      total() {
        return getFormattedCanadianDollars(this.summary.total, this.$i18n.locale)
      },
      checkDelivered() {
        var delivered = false
        if (this.summary && this.summary.shipping.deliveryDate && this.summary.shipping.deliveryDate < Date.now) {
          delivered = true
        }
        return delivered
      }
    },
    async mounted() {
      this.isLoading = true
      await this.getOrderSummary()
      this.isLoading = false
    },
    methods: {
      async getOrderSummary() {
        if (!this.orderID) {
          return
        }

        try {
          const response = await this.$axios.get(`/Umbraco/Api/OrderSummary/GetOrderSummary?id=${this.orderID}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json; charset=UTF-8'
            }
          })

          const responseSummary = response.data 
          const productSkus = responseSummary.items.map(item => item.sku)

          const elasticRequest = {
            filters: {
              all: [
                {
                  any: {
                    sku: productSkus
                  }
                }
              ]
            },
            query: '',
            page: {
              size: 1000
            }
          }

          const elasticResponse = await this.$root.sendAppSearchRequest(elasticRequest)
          const parsedAppSearchProducts = getSummarizedProductsItemFacets(elasticResponse.results, this.$root.getStore())
          
          for(const item of responseSummary.items) {
            const elasticProduct = parsedAppSearchProducts.find(result => result.sku === item.sku)
            if(elasticProduct) {
              item.imageUrl = elasticProduct.image && elasticProduct.image.url ? elasticProduct.image.url : null
              item.productUrl = elasticProduct.url
            }
          }

          this.summary = responseSummary
        }
        catch (e) {
          console.log(e)
        }
      },
      formatDate(date, format) {
        var moment = require('moment')
        moment.locale(this.$i18n.locale)
        return moment(date).format(format)
      },
      download() {
        window.print()
      }
    }
  }
</script>
