<template>
  <label v-if="hasDefaultSlot" :class="classes + ' fakeRadio'" :disabled="disabled">
    <input type="radio" v-on:click="$emit('click')" ref="radio" :value="checkedValue" v-model="checked" @change="onChange"/>
    <span class="icon"></span>
    <span class="label">
      <slot></slot>
    </span>
  </label>

  <label v-else :class="classes + ' fakeRadio noMargin'" :disabled="disabled">
    <input type="radio" v-on:click="$emit('click')" ref="radio" :value="checkedValue" v-model="checked" @change="onChange"/>
    <span class="icon"></span>
  </label>
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      classes: {
        type: [String, Object, Array],
        default: ''
      },
      checkedValue: {},
      value: {
        required: true
      }
    },
    methods: {
      onChange() {
        this.$emit('input', this.checkedProxy);
      }
    },
    computed: {
      hasDefaultSlot() {
        return !!this.$slots.default;
      },
      checked: {
        get() {
          return this.value || null;
        },
        set(val) {
          this.checkedProxy = val;
        }
      }
    }
  };
</script>
