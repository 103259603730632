import productStatus from '@/models/product/Status';
import InternalStatus from '@/models/product/InternalStatus';

export default {
  methods: {
    getPromotionEndDate(product) {
      const price = product.price ? product.price : null;

      if (!price) {
        return null;
      }

      const date = price.endDate != null ? new Date(price.endDate) : null;

      if (date === null) {
        return null;
      }

      return this.$t('cart.promotionExpiration', {
        date: new Intl.DateTimeFormat(
          'fr-CA',
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }).format(date)
      });
    },
    getQuantity(product) {
      if (!product || !product.quantities) {
        return null;
      }
      return product.quantities.find(x => x.store === this.$root.getStore());
    },
    getQuantityValue(product) {
      const quantity = this.getQuantity(product);
      return quantity ? quantity.value : null;
    },
    isBarcodeItem(product) {
      return product && product.isBarcodeItem;
    },
    isInactive(product) {
      return product && product.status === productStatus.Inactive;
    },
    isInStock (product)  {
      const quantity = this.getQuantity(product);
      const qytInStock = quantity ? quantity.inStock : false;
      return this.isBarcodeItem(product) || qytInStock;
    },
    isPriceInStoreOnly(product) {
      return product && product.isPriceInStoreOnly;
    },
    isPromotional(product) {
      if(!product || !product.internalStatuses) {
        return false;
      }
      const internalStatus = product.internalStatuses.find(x => x.store === this.$root.getStore());

      return internalStatus.value === InternalStatus.Promotional;
    },
    isRecall(product) {
      if(!product || !product.internalStatuses) {
        return false;
      }
      const internalStatus = product.internalStatuses.find(x => x.store === this.$root.getStore());

      return internalStatus.value === InternalStatus.StoppedForBilling;
    },
    isUnavailable(product) {
      return this.isInactive(product)
        || !this.isInStock(product)
        || this.isPriceInStoreOnly(product)
        || this.isPromotional(product)
        || this.isRecall(product);
    }
  }
};
