<template>
  <div class="orderAccount">
    <p><strong>{{ $t('order.payment.account.information') }}</strong></p>
    <shipping-address :addressCompleteSettings="addressCompleteSettings" />
    <p><strong>{{ $t('order.payment.account.type') }}*</strong></p>
    <div class="accountTypes">
      <label class="radioboxContainer">
        <input type="radio" name="accountType" :value="AccountType.Private" v-model="account.type">
        <span class="checkmark"></span>
        {{ $t('order.payment.account.types.private') }}
      </label>
      <label class="radioboxContainer">
        <input type="radio" name="accountType" :value="AccountType.Company" v-model="account.type">
        <span class="checkmark"></span>
        {{ $t('order.payment.account.types.company') }}
      </label>
    </div>
    <div class="row">
      <validation-provider name="accountNumber" rules="required|max:8" v-slot="{ errors }" slim>
        <div class="form-group">
          <label for="accountNumber">{{ $t('order.payment.account.number') }}*</label>
          <the-mask id="accountNumber" type="text" mask="########" v-model="account.number" :class="{ invalid: errors.length }"/>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import AccountType from '@/models/cart/AccountType';
import ShippingAddress from './ShippingAddress.vue';
import { ValidationProvider } from 'vee-validate';
import { TheMask } from 'vue-the-mask';
import { mapActions } from 'vuex';

export default {
  components: {
    ShippingAddress,
    TheMask,
    ValidationProvider
  },
  props: {
    addressCompleteSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      account: {
        number: null,
        type: null
      },
      AccountType
    };
  },
  methods: {
    ...mapActions('cartModule', ['setAccount'])
  },
  created() {
    const storedAccount = this.$store.state.cartModule.account;
    if (storedAccount) {
      this.account.number = storedAccount.number || null;
      this.account.type = storedAccount.type || null;
    }
  },
  watch: {
    account: {
      deep: true,
      handler(value) {
        this.setAccount(value);
      }
    }
  }
};
</script>
