var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    {
      ref: "editShippingInfoForm",
      staticClass: "editShippingInfoForm accountForm",
      attrs: { tag: "form", novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSaveShippingInfo.apply(null, arguments)
        },
      },
    },
    [
      _c("validation-provider", {
        ref: "addressLine",
        staticClass: "formItem",
        attrs: {
          tag: "div",
          name: "addressLine1",
          rules: "required|max:50|insideQc:@province",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c(
                  "label",
                  {
                    attrs: { for: "editShippingInfoForm-addressLineCombobox" },
                  },
                  [_vm._v(_vm._s(_vm.$t("order.billing.address")) + "*")]
                ),
                _c("canada-post-search", {
                  ref: "canadaPostSearch",
                  attrs: {
                    id: "editShippingInfoForm-addressLineCombobox",
                    addressCompleteSettings: _vm.addressCompleteSettings,
                    invalid: errors.length > 0,
                    searchFieldId: "address",
                  },
                  on: {
                    updateAddress: _vm.updateCPAddress,
                    "ready:addressComplete": _vm.handleAddressCompleteReady,
                  },
                  model: {
                    value: _vm.address.addressLine,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "addressLine", $$v)
                    },
                    expression: "address.addressLine",
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
                _c("small", { staticClass: "hintText" }, [
                  _vm._v(_vm._s(_vm.$t("users.hints.deliveryOnlyInQuebec"))),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "city", rules: "required|max:50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c(
                  "label",
                  { attrs: { for: "editShippingInfoForm-cityInput" } },
                  [_vm._v(_vm._s(_vm.$t("order.billing.city")) + "*")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.city,
                      expression: "address.city",
                    },
                  ],
                  class: { invalid: errors.length },
                  attrs: { id: "editShippingInfoForm-cityInput", type: "text" },
                  domProps: { value: _vm.address.city },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.address, "city", $event.target.value)
                    },
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "province", mode: "aggressive", hidden: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.province,
                      expression: "province",
                    },
                  ],
                  attrs: {
                    id: "editShippingInfoForm-provinceInput",
                    type: "hidden",
                  },
                  domProps: { value: _vm.province },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.province = $event.target.value
                    },
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "postalCode", rules: "required|max:50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c(
                  "label",
                  { attrs: { for: "editShippingInfoForm-postalCodeInput" } },
                  [_vm._v(_vm._s(_vm.$t("order.billing.postalCode")) + "*")]
                ),
                _c("the-mask", {
                  class: { invalid: errors.length },
                  attrs: {
                    id: "editShippingInfoForm-postalCodeInput",
                    type: "text",
                    mask: "A#A#A#",
                  },
                  model: {
                    value: _vm.address.postalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.address, "postalCode", $$v)
                    },
                    expression: "address.postalCode",
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "formItem" },
        [
          _c("working-button", {
            staticClass: "btn",
            attrs: {
              isWorking: _vm.shippingInfoIsSaving,
              label: _vm.$t("users.actions.save"),
              type: "submit",
            },
          }),
          _vm.saveShippingInfoError
            ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                _vm._v(_vm._s(_vm.saveShippingInfoError)),
              ])
            : _vm._e(),
          _vm.saveShippingInfoMessage
            ? _c("div", { staticClass: "actionResult" }, [
                _vm._v(_vm._s(_vm.saveShippingInfoMessage)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }