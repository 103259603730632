var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "orderSummary" }, [
    _c("div", { staticClass: "date-gridItem" }, [
      _c(
        "a",
        {
          staticClass: "orderSummary-dateLink cardLink",
          attrs: { href: _vm.orderDetailsUrl },
        },
        [_c("span", [_vm._v(_vm._s(_vm.formatDate(_vm.summary.created)))])]
      ),
    ]),
    _c("div", { staticClass: "total-gridItem" }, [
      _c("span", { staticClass: "totalText" }, [
        _vm._v(
          _vm._s(_vm.$t("account.summary.total")) +
            " " +
            _vm._s(_vm.getOrderTotal) +
            " "
        ),
        _c("span", { staticClass: "numArticle" }, [
          _vm._v(
            "(" +
              _vm._s(_vm.summary.products.length) +
              " " +
              _vm._s(
                _vm.$tc("account.summary.item", _vm.summary.products.length)
              ) +
              ")"
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "products-gridItem" }, [
      _c(
        "div",
        {
          ref: "productListContainer",
          staticClass: "orderSummary-productListContainer",
        },
        [
          _c(
            "ul",
            { staticClass: "orderSummary-productList strippedList" },
            [
              _vm._l(_vm.displayedProducts, function (product, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "orderSummary-productItem" },
                  [
                    _c("img", {
                      staticClass: "orderSummary-productImage",
                      attrs: { src: product.url },
                      on: { error: _vm.setFallbackProductImage },
                    }),
                  ]
                )
              }),
              _vm.hasOverflowingDisplayedProducts
                ? _c("li", { staticClass: "orderSummary-productItem" }, [
                    _c("span", [
                      _vm._v("+" + _vm._s(_vm.overflowDisplayedProductCount)),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "status-gridItem" }, [
      _c("span", [_vm._v(_vm._s(_vm.getOrderStatus))]),
    ]),
    _c(
      "div",
      { staticClass: "icon-gridItem" },
      [_c("angle-right", { staticClass: "chevron" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }