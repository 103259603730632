var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "deliveryDate" }, [
    _c("label", [_vm._v(_vm._s(_vm.$t("order.delivery.label")))]),
    _c(
      "div",
      { staticClass: "radioOptions" },
      [
        _vm._l(_vm.deliveryDates, function (date) {
          return _c(
            "div",
            {
              key: date,
              staticClass: "checkboxContainer",
              on: {
                click: function ($event) {
                  return _vm.selectDeliveryDate(date)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deliveryDate,
                    expression: "deliveryDate",
                  },
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: date,
                  checked: _vm._q(_vm.deliveryDate, date),
                },
                on: {
                  change: function ($event) {
                    _vm.deliveryDate = date
                  },
                },
              }),
              _c("span", { staticClass: "radio" }),
              _c("label", [_vm._v(_vm._s(_vm._f("formatDate")(date)))]),
            ]
          )
        }),
        _c(
          "div",
          {
            staticClass: "checkboxContainer",
            on: {
              click: function ($event) {
                return _vm.selectDeliveryDate("call")
              },
            },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.deliveryDate,
                  expression: "deliveryDate",
                },
              ],
              attrs: { type: "radio" },
              domProps: {
                value: "call",
                checked: _vm._q(_vm.deliveryDate, "call"),
              },
              on: {
                change: function ($event) {
                  _vm.deliveryDate = "call"
                },
              },
            }),
            _c("span", { staticClass: "radio" }),
            _c("label", [_vm._v(_vm._s(_vm.$t("cart.callMe")))]),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }