var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.productContext.showPrices
    ? _c("div", { staticClass: "priceNpromo" }, [
        _c("div", { staticClass: "priceSection" }, [
          _vm.isPromotionalProduct
            ? _c("div", { staticClass: "regularSection" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("product.availability.promotional")) +
                      " "
                  ),
                ]),
              ])
            : !_vm.productContext.hasDiscountedPrice
            ? _c("div", { staticClass: "regularSection" }, [
                _vm.productContext.basePrice !== null
                  ? _c("div", { staticClass: "price" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$root.getPriceDollars(
                              _vm.productContext.basePrice
                            )
                          ) +
                          "," +
                          _vm._s(
                            _vm.$root.getPriceCents(
                              _vm.productContext.basePrice
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c(
                "div",
                { staticClass: "saleSection" },
                [
                  _vm.productContext.discountedPrice
                    ? _c("div", { staticClass: "salePrice" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$root.getPriceDollars(
                                _vm.productContext.discountedPrice
                              )
                            ) +
                            "," +
                            _vm._s(
                              _vm.$root.getPriceCents(
                                _vm.productContext.discountedPrice
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.productContext.basePrice
                    ? _c("div", { staticClass: "currentPrice" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$root.formatPrice(
                                _vm.productContext.basePrice
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c("promotion-end-date", {
                    attrs: { product: _vm.productContext.product },
                  }),
                ],
                1
              ),
        ]),
      ])
    : _vm.productContext.isPriceInStoreOnly
    ? _c("div", [
        _vm._v(
          " " + _vm._s(_vm.$t("product.availability.priceInStoreOnly")) + " "
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }