<template>
  <section class="account-defaultBranchInfo infoPanel">
    <div class="infoPanel-title">
      <h3 class="infoPanel-heading">{{ $t('users.titles.defaultBranchInfo') }}</h3>
    </div>
    <div class="infoPanel-data">
      <branch-address v-if="defaultBranch" :branch="defaultBranch"></branch-address>
      <span v-else>{{ $t("users.accountText.noSelectedFavoriteBranch") }}</span>
    </div>
    <div class="infoPanel-actions">
      <button type="button" class="infoPanel-action btnText" @click="handleShowEditDefaultBranchInfoDialog">{{ $t('users.actions.edit') }}</button>
    </div>
    <teleport to='body'>
      <dialog-modal :isOpen="editDefaultBranchInfoDialogIsOpen" @close:dialog="handleCloseEditDefaultBranchInfoDialog" :title="$t('users.titles.defaultBranchInfoEdition')">
        <edit-default-branch-info :branches="branches" :branchId="branchId !== 0 ? branchId : 1" :user="user" ref="editDefaultBranchInfoForm" @success:updateDefaultBranch="handleUpdateDefaultBranchSuccess"></edit-default-branch-info>
      </dialog-modal>
    </teleport>
  </section>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import Teleport from 'vue2-teleport'
import EditDefaultBranchInfo from '../Forms/EditDefaultBranchInfo.vue'
import BranchAddress from './BranchAddress.vue'

export default {
  components: {
    DialogModal,
    Teleport,
    EditDefaultBranchInfo,
    BranchAddress
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    branches: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      branchId: this.user.defaultBranch,
      editDefaultBranchInfoDialogIsOpen: false
    }
  },
  methods: {
    handleCloseEditDefaultBranchInfoDialog() {
      this.editDefaultBranchInfoDialogIsOpen = false
      this.$refs.editDefaultBranchInfoForm.resetForm()
    },
    handleShowEditDefaultBranchInfoDialog() {
      this.editDefaultBranchInfoDialogIsOpen = true
    },
    handleUpdateDefaultBranchSuccess(branchId) {
      this.branchId = branchId
    }
  },
  computed: {
    defaultBranch() {
      return this.branches.find(branch => branch.branchID === this.branchId)
    }
  }
}
</script>