<template>
  <div>
    <BamboraForm :button-text="$t('users.actions.save')" :is-payment-info-saving="paymentInfoIsSaving" :error-as-events="true" @submit="handleCreateProfile"></BamboraForm>

    <div class="formItem">
      <div v-if="savePaymentInfoError" class="formItem-errorMessage error">{{ savePaymentInfoError }}</div>
      <div v-if="savePaymentInfoMessage" class="actionResult">{{ savePaymentInfoMessage }}</div>
    </div>
  </div>
</template>

<script>
import BamboraForm from '../Order/BamboraForm'
import WorkingButton from './WorkingButton.vue'

export default {
  components: {
    BamboraForm,
    WorkingButton
  },
  props: {
    bamboraConfiguration: {
      type: Object,
      required: true
    },
    paymentMethod: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      bamboraCardID:1, //TODO à changer si on supporte le multi carte,
      payment: structuredClone(this.paymentMethod),
      savePaymentInfoError: undefined,
      savePaymentInfoMessage: undefined,
      paymentInfoIsSaving: false
    }
  },
  methods: {
    async handleAddCardToProfile(tokenResult, customerCode, cardholderName) {
      try {
        const request = {
          token: {
            name: cardholderName,
            code: tokenResult.token
          },
          validate: true
        }

        const response = await this.$axios.post(`${this.bamboraConfiguration.apiUrl}/profiles/${customerCode}/cards`, request, { 
          headers: {
            'Authorization': `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`,
          }
        });

        if (response && response.data && response.data.validation && response.data.validation.approved === '1') {
          await this.handleSaveUserPayment(tokenResult, customerCode, response.data, cardholderName);
        } else {
          await this.handleDeleteProfile(customerCode);
          this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
        }
      }
      catch(error) {
        await this.handleDeleteProfile(customerCode);
        this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
      }
    },
    async handleCreateProfile(result) {
      if (this.paymentInfoIsSaving) {
        return;
      }

      this.savePaymentInfoError = null;

      try {
        this.paymentInfoIsSaving = true

        if (this.user.bamboraCustomerCode == null) {
          const createProfileRequest = {
            token: result.result.token,
            validate: true
          }

          const createProfileResponse = await this.$axios.post('https://api.na.bambora.com/v1/profiles', createProfileRequest, { 
            headers: {
              'Authorization': `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`,
            }
          });

          if (createProfileResponse && createProfileResponse.data) {
            await this.handleAddCardToProfile(result.result, createProfileResponse.data.customer_code, result.cardholderName);
          } else {
            this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
          }
        }
        else {
          await this.handleAddCardToProfile(result.result, this.user.bamboraCustomerCode, result.cardholderName);
        }
      } catch (error) {
        this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
      } finally {
        this.paymentInfoIsSaving = false
      }
    },
    async handleDeleteProfile(customerCode) {
      try {
        this.paymentInfoIsSaving = true

        const response = await this.$axios.delete(`https://api.na.bambora.com/v1/profiles/${customerCode}`, {
          headers: {
            Authorization: `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`
          },
          data: {
          }
        });

        if (!response || !response.data) {
          this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
        }
      } catch (error) {
        this.savePaymentInfoError = this.$t('users.messages.savePaymentInfoException')
      } finally {
        this.paymentInfoIsSaving = false
      }
    },
    async handleSaveUserPayment(tokenResult, customerCode, cardData, cardholderName) {
      const response = await this.$axios.get(`https://api.na.bambora.com/v1/profiles/${customerCode}/cards/${this.bamboraCardID}`, {
          headers: {
            Authorization: `Passcode ${btoa(encodeURI(`${this.bamboraConfiguration.merchantId}:${this.bamboraConfiguration.paymentProfilePasscode}`))}`
          }
        });

      const request = {
        bamboraCardID: this.bamboraCardID,
        bamboraCustomerCode: customerCode,
        memberID: this.user.contact.memberID,
        cardholderName,
        creditCardholderName: cardholderName,
        creditCardLastDigits: tokenResult.last4,
        creditCardExpiration: `${tokenResult.expiryMonth}/${tokenResult.expiryYear}`,
        creditCardType: response && response.data && response.data.card[0] && response.data.card[0].card_type ? response.data.card[0].card_type : null
      }
      const result = await this.$axios.post('/Umbraco/Api/User/SaveUserPayment', request)

      if (result && result.data) {
        this.savePaymentInfoMessage = this.$t('users.messages.success.editPaymentInfo')
        this.$emit('success:updatePaymentInfo', result.data)
      }
    }
  }
}
</script>