<template>
  <modal v-if="error" @onclose="close()" :isPopin="true">
    <template v-slot:title>
      <h3>{{ $t('errorModal.title') }}</h3>
    </template>
    <template v-slot:body>
      <p v-html="text"></p>
    </template>
    <template v-slot:actions>
      <button class="btn big" @click="close()">{{ $t('errorModal.close') }}</button>
    </template>
  </modal>
</template>

<script>
import Modal from '../UI/Modal.vue';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    Modal
  },
  async created() {
    window.onerror = this.onError;
  },
  methods: {
    ...mapActions('globalModule', ['setErrorModal']),
    clickOutside(event) {
      if (event.target === this.$refs.mask) {
        this.close();
      }
    },
    close() {
      window.location.reload();
    },
    onError() {
      this.setErrorModal(true);
    }
  },
  computed: {
    ...mapState('globalModule', ['error']),
    text() {
      // eslint-disable-next-line no-dynamic-keys
      return this.$t(this.error);
    }
  }
};
</script>
