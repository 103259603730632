var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoading
      ? _c("div", { staticClass: "loading" }, [
          _c("img", { attrs: { src: "/images/triangle-loading.gif" } }),
        ])
      : _c(
          "div",
          { staticClass: "order" },
          [
            !_vm.isLoggedIn
              ? _c("div", { staticClass: "accountIncitator" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("users.messages.notLoggedIn")),
                    },
                  }),
                ])
              : _vm._e(),
            _c("back-to-cart", { attrs: { href: _vm.cartUrl } }),
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("error-modal"),
                _c("h1", [_vm._v(_vm._s(_vm.$t("order.title")))]),
                _c("validation-observer", {
                  attrs: { slim: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ validate }) {
                        return [
                          _c("order-customer", {
                            attrs: {
                              addressCompleteSettings:
                                _vm.addressCompleteSettings,
                              "shipping-disabled": _vm.shippingDisabled,
                              user: _vm.user,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "legalnotices",
                                  fn: function () {
                                    return [_vm._t("legalnotices")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("order-payment", {
                            attrs: {
                              addressCompleteSettings:
                                _vm.addressCompleteSettings,
                              "bambora-configuration": _vm.bamboraConfiguration,
                              confirmationUrl: _vm.confirmationUrl,
                              recaptchaSiteKey: _vm.recaptchaSiteKey,
                              validate: validate,
                              user: _vm.user,
                              "user-payment-is-enabled":
                                _vm.userPaymentIsEnabled,
                            },
                            on: {
                              updateUserPayment: _vm.handleUserPaymentUpdate,
                              deletePayment: _vm.handleDeletePayment,
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("order-details", {
                  attrs: {
                    gst: _vm.gst,
                    "has-regular-and-barcode-items":
                      _vm.hasRegularAndBarcodeItems(_vm.products),
                    qst: _vm.qst,
                    shipping:
                      _vm.shippingMethod === 0 ? _vm.shippingPrice : null,
                    shippingExtra:
                      _vm.addShippingExtra === true
                        ? _vm.shippingExtraFee
                        : null,
                    "sub-total":
                      _vm.addShippingExtra === true
                        ? _vm.subTotal(parseInt(_vm.shippingExtraFee))
                        : _vm.subTotal(),
                    total: _vm.total(),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "legalnotices",
                        fn: function () {
                          return [_vm._t("legalnotices")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }