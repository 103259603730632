var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "result",
      attrs: { href: _vm.getProductUrl(), tabindex: "0" },
      on: {
        click: function ($event) {
          return _vm.$root.trackClick(_vm.product, _vm.query)
        },
      },
    },
    [
      _c("span", { staticClass: "resultContent" }, [
        _c("span", { staticClass: "resultImg" }, [
          _vm.product.image && _vm.product.image.url
            ? _c("img", {
                attrs: {
                  src: _vm.productImageUrl,
                  alt: _vm.productImageAlt,
                  onerror: "this.src='/images/image-default.png';",
                  loading: "lazy",
                },
              })
            : _c("img", {
                attrs: {
                  src: "/images/image-default.png",
                  alt: "Image par défaut",
                  loading: "lazy",
                },
              }),
        ]),
        _c("span", { staticClass: "resultName" }, [
          _vm._v(_vm._s(_vm.product.name)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }