var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    attrs: { isPopin: true },
    on: {
      onclose: function ($event) {
        return _vm.cancel()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("h5", [_vm._v(_vm._s(_vm.$t("storeChangeWarningModal.title")))]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", [_vm._v(_vm._s(_vm.$t("storeChangeWarningModal.text")))]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn big",
                on: {
                  click: function ($event) {
                    return _vm.confirm()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("storeChangeWarningModal.confirm")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn big bordered",
                on: {
                  click: function ($event) {
                    return _vm.cancel()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("storeChangeWarningModal.cancel")))]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }