var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "searchBar" }, [
    _c("div", { staticClass: "divInput" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchText,
            expression: "searchText",
          },
        ],
        staticClass: "search",
        attrs: { type: "text", placeholder: _vm.$t("searchBar.placeholder") },
        domProps: { value: _vm.searchText },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.refreshSearchText.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchText = $event.target.value
          },
        },
      }),
    ]),
    _c("button", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.searchText.length > 0,
          expression: "searchText.length > 0",
        },
      ],
      staticClass: "reset",
      on: { click: _vm.resetSearch },
    }),
    _c("button", { on: { click: _vm.refreshSearchText } }, [
      _c(
        "svg",
        {
          attrs: {
            width: "13",
            height: "13",
            viewBox: "0 0 13 13",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M11.8125 11.5391L8.67188 8.39844C9.35156 7.57812 9.72656 6.52344 9.72656 5.375C9.72656 2.70312 7.52344 0.5 4.85156 0.5C2.15625 0.5 0 2.70312 0 5.375C0 8.07031 2.17969 10.25 4.85156 10.25C5.97656 10.25 7.03125 9.875 7.875 9.19531L11.0156 12.3359C11.1328 12.4531 11.2734 12.5 11.4375 12.5C11.5781 12.5 11.7188 12.4531 11.8125 12.3359C12.0469 12.125 12.0469 11.7734 11.8125 11.5391ZM1.125 5.375C1.125 3.3125 2.78906 1.625 4.875 1.625C6.9375 1.625 8.625 3.3125 8.625 5.375C8.625 7.46094 6.9375 9.125 4.875 9.125C2.78906 9.125 1.125 7.46094 1.125 5.375Z",
              fill: "#2A2A2A",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }