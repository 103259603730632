var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isExternalProviderMinorCategoriesToInclude
    ? _c(
        "a",
        {
          staticClass: "infoBoxExternalProvider",
          attrs: {
            href: _vm.$t(
              `infoBoxExternalProviders.link.provider.${_vm.provider}`
            ),
            target: "_blank",
          },
        },
        [
          _c("img", { attrs: { src: "/images/info-icon.png" } }),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t(`infoBoxExternalProviders.text.provider.${_vm.provider}`)
              )
            ),
          ]),
          _c("img", {
            staticClass: "linkIcon",
            attrs: { src: "/images/external-link-icon.png" },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }