<template>
  <validation-observer class="editShippingInfoForm accountForm" ref="editShippingInfoForm" tag="form" @submit.prevent="handleSaveShippingInfo" novalidate>
    <validation-provider class="formItem" tag="div" name="addressLine1" rules="required|max:50|insideQc:@province" v-slot="{ errors }" ref="addressLine">
      <label for="editShippingInfoForm-addressLineCombobox">{{ $t('order.billing.address') }}*</label>
      <canada-post-search
        v-model.lazy="address.addressLine"
        id="editShippingInfoForm-addressLineCombobox"
        :addressCompleteSettings="addressCompleteSettings"
        :invalid="errors.length > 0"
        searchFieldId="address"
        ref="canadaPostSearch"
        @updateAddress="updateCPAddress"
        @ready:addressComplete="handleAddressCompleteReady" />
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
      <small class="hintText">{{ $t("users.hints.deliveryOnlyInQuebec") }}</small>
    </validation-provider>

    <validation-provider class="formItem" tag="div" name="city" rules="required|max:50" v-slot="{ errors }">
      <label for="editShippingInfoForm-cityInput">{{ $t('order.billing.city') }}*</label>
      <input id="editShippingInfoForm-cityInput" v-model="address.city" type="text" :class="{ invalid: errors.length }" />
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
    </validation-provider>

    <validation-provider class="formItem" tag="div" name="province" mode="aggressive" v-slot="{ errors }" hidden>
      <input id="editShippingInfoForm-provinceInput" v-model="province" type="hidden" />
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
    </validation-provider>

    <validation-provider class="formItem" tag="div" name="postalCode" rules="required|max:50" v-slot="{ errors }">
      <label for="editShippingInfoForm-postalCodeInput">{{ $t('order.billing.postalCode') }}*</label>
      <the-mask id="editShippingInfoForm-postalCodeInput" type="text" mask="A#A#A#" v-model.lazy="address.postalCode" :class="{ invalid: errors.length }" />
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
    </validation-provider>

    <div class="formItem">
      <working-button class="btn" :isWorking="shippingInfoIsSaving" :label="$t('users.actions.save')" type="submit"></working-button>
      <div v-if="saveShippingInfoError" class="formItem-errorMessage error">{{ saveShippingInfoError }}</div>
      <div v-if="saveShippingInfoMessage" class="actionResult">{{ saveShippingInfoMessage }}</div>
    </div>
  </validation-observer>
</template>

<script>
import { setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'
import WorkingButton from './WorkingButton.vue'
import { TheMask } from 'vue-the-mask'
import CanadaPostSearch from '@/components/CanadaPostSearch'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'
import CustomSelect from './CustomSelect.vue'

setInteractionMode('eager')

export default {
  components: {
    CanadaPostSearch,
    CustomSelect,
    TheMask,
    ValidationObserver,
    ValidationProvider,
    WorkingButton
  },
  props: {
    addressInfo: {
      type: Object
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      address: this.getAddressInfo(this.addressInfo),
      province: undefined,
      shippingInfoIsSaving: false,
      saveShippingInfoError: undefined,
      saveShippingInfoMessage: undefined
    }
  },
  inject: {
    addressCompleteSettings: {
      type: Object
    }
  },
  methods: {
    getAddressInfo(address) {
      return {
        addressID: address ? address.addressID : undefined,
        addressLine: address ? address.addressLine : undefined,
        city: address ? address.city : undefined,
        postalCode: address ? address.postalCode : undefined
      }
    },
    handleAddressCompleteReady() {
      const pcaElement = document.querySelector('.pca')
      if(!pcaElement) {
        return
      }

      this.$refs.canadaPostSearch.$el.appendChild(pcaElement)
    },
    async handleSaveShippingInfo() {
      this.resetActionMessages()

      if(!await this.$refs.editShippingInfoForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.editShippingInfoForm.$el, '.invalid')
        return
      }

      try {
        this.shippingInfoIsSaving = true
        const request = {
          memberID: this.user.contact.memberID,
          address: this.address,
          isDefaultShippingAddress: true
        }
        const response = await this.$axios.post('/Umbraco/Api/User/SaveUserAddress', request)
        this.saveShippingInfoMessage = this.$t('users.messages.success.editShippingInfo')
        this.$emit('success:updateShippingInfo', response.data)
      } catch (error) {
        this.saveShippingInfoError = this.$t('users.messages.SaveShippingInfoException')
      } finally {
        this.shippingInfoIsSaving = false
      }
    },
    resetForm() {
      this.address = this.getAddressInfo(this.addressInfo)
      this.resetActionMessages()

      this.$nextTick(() => {
        this.$refs.editShippingInfoForm.reset()
      })
    },
    resetActionMessages() {
      this.saveShippingInfoError = undefined
      this.saveShippingInfoMessage = undefined
    },
    updateCPAddress(address) {
      this.address.addressLine = address.Line1
      this.address.city = address.City
      this.province = address.ProvinceCode
      this.address.postalCode = address.PostalCode
      this.$nextTick(async () => {
        await this.$refs.editShippingInfoForm.validate()
      })
    }
  }
}
</script>