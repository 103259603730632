<template>
  <dialog ref="dialogElement" class="dialogModal wrapper">
    <div class="dialogModal-inner" ref="dialogInner">
      <header class="dialogModal-header">
        <slot name="header">
          <h2 class="dialogModal-heading">{{ title }}</h2>
        </slot>
        <div class="closeDialog-gridItem">
          <button type="button" class="dialogModal-closeDialog" @click="closeDialog()">
            <x-mark></x-mark>
          </button>
        </div>
      </header>
      <hr>
      <div class="dialogModal-content">
        <slot />
      </div>
    </div>
  </dialog>
</template>

<script>
import XMark from '../Svg/XMark.vue'

export default {
  components: {
    XMark
  },
  props: {
    shouldCloseOnClickOutside: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalIsOpening: false
    }
  },
  methods: {
    closeDialog: function() {
      this.$emit('close:dialog')
      this.$refs.dialogElement.removeEventListener('click', this.clickOutsideModal)
    },
    clickOutsideModal: function(event) {
      if(this.$refs.dialogInner.contains(event.target)) {
        return
      }

      this.closeDialog()
    },
    listenToClickOutside: function() {
      this.$refs.dialogElement.addEventListener('click', this.clickOutsideModal)
    }
  },
  watch: {
    isOpen: function(newValue, oldValue) {
      if(!this.$refs.dialogElement) {
        return
      }

      if(newValue && !this.$refs.dialogElement.open) {
        this.$refs.dialogElement.showModal()
        if(this.shouldCloseOnClickOutside) {
          this.listenToClickOutside()
        }
        return
      }

      if(this.$refs.dialogElement.open) {
        this.$refs.dialogElement.close()
      }
    }
  }
}
</script>
