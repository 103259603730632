<template>
  <div class="addCartBtn" :class="{ disabled: disabled }">
    <a class="btn addCart"
       @click="handleClick"
       :class="{ disabled: disabled, productAdded: productAdded  }"
       >{{ !productAdded ? $root.getFeatureFlag('ecommerce') ? $t('addToCart') : $t('addToMyList')  : $t('productAdded') }}</a>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    maximumQuantity: {
      type: Number,
      default: null
    },
    product: {
      type: Object,
      default: null
    },
    productId: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      effectDisplayTime: 3000,
      effetTimeout: null,
      productAdded: false
    };
  },
  methods: {
    handleClick() {
      if (this.currentQuantity + this.quantity > this.maximumQuantity) {
        this.$tostini({ message: this.$t('cart.maxQuantityReached'), duration: 5000 });
        return;
      }

      this.addToCart();
    },
    sendAddToCartGaEvent() {
      try {
        if (dataLayer && this.product) {
          dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              items: [{
                item_id: this.product.sku,
                item_name: this.product.name,
                item_brand: this.product.brand,
                item_category: this.product.majorCategory,
                item_category2: this.product.intermediateCategory,
                item_category3: this.product.minorCategory,
                price: this.price.value,
                quantity: this.currentQuantity
              }]
            }
          });
        }
      } catch { /*Fait rien*/ }
    },
    addToCart() {
      const selectedStore = this.$root.getStore();

      if (selectedStore === null) {
        this.$tostini({ message: this.$t('selectStoreToast'), duration: 3000 });
        return;
      }

      if (!this.productAdded) {
        this.productAdded = true;
        this.$store.dispatch('cartModule/addQuantity', {
          productId: this.productId,
          quantity: this.quantity
        });

        if (this.$root.getFeatureFlag('ecommerce')) {
          this.$tostini({ message: this.$t('productAddedToast'), duration: 3000, html: true });
        } else {
          this.$tostini({ message: this.$t('productAddedListToast'), duration: 3000, html: true });
        }

        setTimeout(() => this.productAdded = false, this.effectDisplayTime);
      }

      this.sendAddToCartGaEvent();
    }
  },
  computed: {
    ...mapState('cartModule', ['cart']),
    currentQuantity() {
      return this.cart[this.productId];
    }
  }
};
</script>
