var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "account-emailInfo infoPanel" },
    [
      _c("div", { staticClass: "infoPanel-title" }, [
        _c("h3", { staticClass: "infoPanel-heading" }, [
          _vm._v(_vm._s(_vm.$t("users.titles.emailInfo"))),
        ]),
      ]),
      _c("div", { staticClass: "infoPanel-data" }, [
        _c("div", [_vm._v(_vm._s(_vm.email))]),
      ]),
      _c("div", { staticClass: "infoPanel-actions" }, [
        _c(
          "button",
          {
            staticClass: "infoPanel-action btnText",
            attrs: { type: "button" },
            on: { click: _vm.handleShowEditEmailInfoDialog },
          },
          [_vm._v(_vm._s(_vm.$t("users.actions.edit")))]
        ),
      ]),
      _c(
        "teleport",
        { attrs: { to: "body" } },
        [
          _c(
            "dialog-modal",
            {
              attrs: {
                isOpen: _vm.editEmailInfoDialogIsOpen,
                title: _vm.$t("users.titles.emailEdition"),
              },
              on: { "close:dialog": _vm.handleCloseEditEmailInfoDialog },
            },
            [
              _c("edit-email-info", {
                ref: "editEmailInfoForm",
                attrs: { userEmail: _vm.email },
                on: { "success:updateEmail": _vm.handleUpdateEmailSuccess },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }