<template>
  <button :class="`deleteFavorite-${this.product.identifier}`" class="deleteFavorite">
    <img v-if="memberId" src="@/assets/svg/heart-solid.svg" @click="deleteFavorite">
  </button>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    memberId: {
      Type: Number,
      default: null
    }
  },
  methods: {
    async deleteFavorite() {
      const model = {
        memberID: this.memberId,
        productID: this.product.identifier
      }
      const addButton = document.querySelector(`.addFavorite-${this.product.identifier}`)
      const deleteButton = document.querySelector(`.deleteFavorite-${this.product.identifier}`)

      try {
        addButton.classList.toggle('hidden')
        deleteButton.classList.toggle('hidden') 
        await this.$axios.post('/Umbraco/Api/Favorites/DeleteFavorite', model)       
      }
      catch (error) {
        addButton.classList.toggle('hidden')
        deleteButton.classList.toggle('hidden') 
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>