<template>
  <div v-show="itemsContainer && itemsContainer.items.length">
    <div class="productsCarrouselHeader">
      <slot name="header" />
      <div class="nav">
        <div class="previousButton" ref="previousButton"></div>
        <div class="nextButton" ref="nextButton"></div>
      </div>
    </div>
    <facet-items-viewer
      ref="itemsContainer"
      :items-container.sync="itemsContainer"
      :defaults="defaults"
      :addParamsToUrl="addParamsToUrl"
      :member-id="memberId"
      class="wrapperProductResult"
      @dataChanged="initializeSlider"
    >
      <template v-slot="obj">
        <slot name="content" :item="obj.item" :member="obj.member" :isFavorite="obj.isFavorite" />
      </template>
    </facet-items-viewer>
    <slot name="footer" />
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';
import FacetItemsViewer from '@/components/Facets/FacetItemsViewer.vue';

export default {
  components: {
    FacetItemsViewer
  },
  props: {
    defaults: {
      type: Object,
      default: { count: 8 }
    },
    itemsContainer: {
      type: Object,
      required: true
    },
    sliderOptions: {
      type: Object,
      default: () => { 
        return {
          items: 5
        };
      }
    },
    addParamsToUrl: {
      type: Boolean,
      default: true
    },
    memberId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      slider: null
    };
  },
  mounted () {
    this.itemsContainer.selectedFacets = {};
  },
  methods: {
    initializeSlider() {
      if (!this.slider) {
        this.$nextTick(function() {
          this.slider = tns({
            container: this.$refs.itemsContainer.$refs.itemsProducts,
            prevButton: this.$refs.previousButton,
            nextButton: this.$refs.nextButton,
            ...this.sliderOptions
          });
        });
      } else {
        this.slider.rebuild();
      }
    }
  },
  beforeDestroy() {
    this.slider.destroy();
  }
};
</script>
