var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "account-passwordInfo infoPanel" },
    [
      _c("div", { staticClass: "infoPanel-title" }, [
        _c("h3", { staticClass: "infoPanel-heading" }, [
          _vm._v(_vm._s(_vm.$t("users.titles.passwordInfo"))),
        ]),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "infoPanel-actions" }, [
        _c(
          "button",
          {
            staticClass: "infoPanel-action btnText",
            attrs: { type: "button" },
            on: { click: _vm.handleShowEditPasswordInfoDialog },
          },
          [_vm._v(_vm._s(_vm.$t("users.actions.edit")))]
        ),
      ]),
      _c(
        "teleport",
        { attrs: { to: "body" } },
        [
          _c(
            "dialog-modal",
            {
              attrs: {
                isOpen: _vm.editPasswordInfoDialogIsOpen,
                title: _vm.$t("users.titles.passwordEdition"),
              },
              on: { "close:dialog": _vm.handleCloseEditPasswordInfoDialog },
            },
            [
              _vm.user
                ? _c("edit-password-info", {
                    ref: "editPasswordInfoForm",
                    attrs: { memberId: _vm.user.contact.memberID },
                    on: {
                      "success:updatePassword": _vm.handleUpdatePasswordSuccess,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "infoPanel-data" }, [
      _c("div", [_vm._v("******")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }