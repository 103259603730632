var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderAccount" },
    [
      _c("p", [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("order.payment.account.information"))),
        ]),
      ]),
      _c("shipping-address", {
        attrs: { addressCompleteSettings: _vm.addressCompleteSettings },
      }),
      _c("p", [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("order.payment.account.type")) + "*"),
        ]),
      ]),
      _c("div", { staticClass: "accountTypes" }, [
        _c("label", { staticClass: "radioboxContainer" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.account.type,
                expression: "account.type",
              },
            ],
            attrs: { type: "radio", name: "accountType" },
            domProps: {
              value: _vm.AccountType.Private,
              checked: _vm._q(_vm.account.type, _vm.AccountType.Private),
            },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.account, "type", _vm.AccountType.Private)
              },
            },
          }),
          _c("span", { staticClass: "checkmark" }),
          _vm._v(
            " " + _vm._s(_vm.$t("order.payment.account.types.private")) + " "
          ),
        ]),
        _c("label", { staticClass: "radioboxContainer" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.account.type,
                expression: "account.type",
              },
            ],
            attrs: { type: "radio", name: "accountType" },
            domProps: {
              value: _vm.AccountType.Company,
              checked: _vm._q(_vm.account.type, _vm.AccountType.Company),
            },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.account, "type", _vm.AccountType.Company)
              },
            },
          }),
          _c("span", { staticClass: "checkmark" }),
          _vm._v(
            " " + _vm._s(_vm.$t("order.payment.account.types.company")) + " "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("validation-provider", {
            attrs: { name: "accountNumber", rules: "required|max:8", slim: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "accountNumber" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("order.payment.account.number")) + "*"
                          ),
                        ]),
                        _c("the-mask", {
                          class: { invalid: errors.length },
                          attrs: {
                            id: "accountNumber",
                            type: "text",
                            mask: "########",
                          },
                          model: {
                            value: _vm.account.number,
                            callback: function ($$v) {
                              _vm.$set(_vm.account, "number", $$v)
                            },
                            expression: "account.number",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }