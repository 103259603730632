var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasDefaultSlot
    ? _c(
        "label",
        {
          class: _vm.classes + " fakeRadio",
          attrs: { disabled: _vm.disabled },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked",
              },
            ],
            ref: "radio",
            attrs: { type: "radio" },
            domProps: {
              value: _vm.checkedValue,
              checked: _vm._q(_vm.checked, _vm.checkedValue),
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
              change: [
                function ($event) {
                  _vm.checked = _vm.checkedValue
                },
                _vm.onChange,
              ],
            },
          }),
          _c("span", { staticClass: "icon" }),
          _c("span", { staticClass: "label" }, [_vm._t("default")], 2),
        ]
      )
    : _c(
        "label",
        {
          class: _vm.classes + " fakeRadio noMargin",
          attrs: { disabled: _vm.disabled },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked,
                expression: "checked",
              },
            ],
            ref: "radio",
            attrs: { type: "radio" },
            domProps: {
              value: _vm.checkedValue,
              checked: _vm._q(_vm.checked, _vm.checkedValue),
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
              change: [
                function ($event) {
                  _vm.checked = _vm.checkedValue
                },
                _vm.onChange,
              ],
            },
          }),
          _c("span", { staticClass: "icon" }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }