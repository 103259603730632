var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderConfirmationProducts" }, [
    _c("div", { staticClass: "cartProducts" }, [
      _c("div", { staticClass: "cartProductsHeader" }, [
        _c("div", { staticClass: "label product" }, [
          _vm._v(_vm._s(_vm.$t("orderConfirmation.items"))),
        ]),
        _c("div", { staticClass: "label unitPrice" }, [
          _vm._v(_vm._s(_vm.$t("orderConfirmation.unitPrice"))),
        ]),
        _c("div", { staticClass: "label totalPrice" }, [
          _vm._v(_vm._s(_vm.$t("cart.totalPrice"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "cartProductsContent" },
        _vm._l(_vm.products, function (product) {
          return _c(
            "div",
            { key: product.identifier, staticClass: "cartProduct" },
            [
              _c(
                "div",
                { staticClass: "productInfosImg" },
                [
                  _c("product-info", {
                    attrs: {
                      product: product,
                      quantity: product.quantity,
                      isAvailable: true,
                      isInactive: false,
                      isInStock: true,
                      showPrice: false,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "price regular" }, [
                _c("label", { staticClass: "m" }, [
                  _vm._v(_vm._s(_vm.$t("orderConfirmation.unitPrice"))),
                ]),
                _c("span", [_vm._v(_vm._s(product.price))]),
              ]),
              _c("div", { staticClass: "priceTotal" }, [
                _c("label", { staticClass: "m" }, [
                  _vm._v(_vm._s(_vm.$t("cart.totalPrice"))),
                ]),
                _vm.total !== null
                  ? _c("span", [_vm._v(_vm._s(product.total))])
                  : _c("span", [_vm._v("N/D")]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }