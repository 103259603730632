<template>
  <section class="account-emailInfo infoPanel">
    <div class="infoPanel-title">
      <h3 class="infoPanel-heading">{{ $t('users.titles.emailInfo') }}</h3>
    </div>
    <div class="infoPanel-data">
      <div>{{ email }}</div>
    </div>
    <div class="infoPanel-actions">
      <button type="button" class="infoPanel-action btnText" @click="handleShowEditEmailInfoDialog">{{ $t('users.actions.edit') }}</button>
    </div>
    
    <teleport to='body'>
      <dialog-modal :isOpen="editEmailInfoDialogIsOpen" @close:dialog="handleCloseEditEmailInfoDialog" :title="$t('users.titles.emailEdition')">
        <edit-email-info :userEmail="email" ref="editEmailInfoForm" @success:updateEmail="handleUpdateEmailSuccess"></edit-email-info>
      </dialog-modal>
    </teleport>
  </section>
</template>

<script>
import DialogModal from '../Utilities/DialogModal.vue'
import Teleport from 'vue2-teleport'
import EditEmailInfo from '../Forms/EditEmailInfo.vue'

export default {
  components: {
    DialogModal,
    Teleport,
    EditEmailInfo
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editEmailInfoDialogIsOpen: false,
      email: this.user.contact.email
    }
  },
  methods: {
    handleCloseEditEmailInfoDialog() {
      this.editEmailInfoDialogIsOpen = false
      this.$refs.editEmailInfoForm.resetForm()
    },
    handleUpdateEmailSuccess(newEmail) {
      this.email = newEmail
      return
    },
    handleShowEditEmailInfoDialog() {
      this.editEmailInfoDialogIsOpen = true
    }
  },
  computed: {
    
  }
}
</script>