<template>
  <a :href="getProductUrl()" @click="$root.trackClick(product, query)" tabindex="0" class="result">
    <span class="resultContent">
      <span class="resultImg">
        <img v-if="product.image && product.image.url"
             :src="productImageUrl"
             :alt="productImageAlt"
             onerror="this.src='/images/image-default.png';"
             loading="lazy"
             />
        <img v-else src="/images/image-default.png" alt="Image par défaut" loading="lazy" />
      </span>
      <span class="resultName">{{ product.name }}</span>
    </span>
  </a>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    query: {
      type: String
    }
  },
  methods: {
    getProductUrl() {
      return `${location.origin}${this.product.url}`;
    }
  },
  computed: {
    productImageAlt() {
      if (!this.product || !this.product.image || !this.product.image.alt) {
        return null;
      }

      if (typeof this.product.image.alt === 'string') {
        return this.product.image.alt;
      } else if (Array.isArray(this.product.image.alt)) {
        return this.product.image.alt[0];
      }

      return null;
    },
    productImageUrl() {
      if (!this.product || !this.product.image || !this.product.image.url) {
        return null;
      }

      if (typeof this.product.image.url === 'string') {
        return this.product.image.url;
      } else if (Array.isArray(this.product.image.url)) {
        return this.product.image.url[0];
      }

      return null;
    }
  }
};
</script>
