import { render, staticRenderFns } from "./DefaultBranchInfo.vue?vue&type=template&id=0d89f78f"
import script from "./DefaultBranchInfo.vue?vue&type=script&lang=js"
export * from "./DefaultBranchInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d89f78f')) {
      api.createRecord('0d89f78f', component.options)
    } else {
      api.reload('0d89f78f', component.options)
    }
    module.hot.accept("./DefaultBranchInfo.vue?vue&type=template&id=0d89f78f", function () {
      api.rerender('0d89f78f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Account/DefaultBranchInfo.vue"
export default component.exports