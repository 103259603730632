<template>
  <div class="addToCart">
    <quantity-selector v-model="quantity" :disabled="unavailable" :maximum-quantity="maximumQuantity" />
    <add-to-cart-button
      :disabled="unavailable"
      :maximumQuantity="maximumQuantity"
      :productId="productId"
      :product="product"
      :quantity="quantity"
    />
    <add-to-favorites v-if="memberId" :class="isUserFavorite === true ? 'hidden' : ''" :product="product" :member-id="memberId" />
    <delete-from-favorites v-if="memberId" :class="isUserFavorite === false ? 'hidden' : ''" :product="product" :member-id="memberId" />
  </div>
</template>

<script>
import AddToCartButton from '@/components/Product/AddToCartButton.vue';
import AddToFavorites from '@/components/Product/AddToFavorites.vue';
import DeleteFromFavorites from '@/components/Product/DeleteFromFavorites.vue';
import productMixins from '@/productMixins';
import QuantitySelector from '@/components/Product/QuantitySelector.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AddToCartButton,
    AddToFavorites,
    DeleteFromFavorites,
    QuantitySelector
  },
  props: {
    memberId: {
      Type: Number,
      default: null
    }
  },
  data() {
    return {
      quantity: 1,
      isUserFavorite: false
    };
  },
  computed: {
    ...mapGetters('productModule', ['product']),
    productId() {
      return this.product ? this.product.identifier : '';
    },
    maximumQuantity() {
      //2147483647 = plus gros int signed pour pas géré la quantité pour produits a barrcode
      return this.isBarcodeItem(this.product) ? 2147483647 : this.getQuantityValue(this.product);
    },
    unavailable() {
      return this.isUnavailable(this.product);
    }
  },
  async updated() {
    if (this.memberId) {
      await this.getUserFavorites()
    }
  },
  methods: {
    async getUserFavorites() {
      try {
        const response = await this.$axios.get(`/Umbraco/Api/Favorites/GetFavorites?memberid=${this.memberId}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        });
        if (response.data.some(x => x.productID.includes(this.productId))) {
          this.isUserFavorite = true;
        } else {
          this.isUserFavorite = false;
        }
      } catch(e) {
        // Fait rien
      }
    }
  },
  mixins: [productMixins]
};
</script>
