<template>
  <modal @onclose="cancel()" :isPopin="true">
    <template v-slot:title>
      <h5>{{ $t('storeChangeWarningModal.title') }}</h5>
    </template>
    <template v-slot:body>
      <p>{{ $t('storeChangeWarningModal.text') }}</p>
    </template>
    <template v-slot:actions>
      <button class="btn big" @click="confirm()">{{ $t('storeChangeWarningModal.confirm') }}</button>
      <button class="btn big bordered" @click="cancel()">{{ $t('storeChangeWarningModal.cancel') }}</button>
    </template>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from './Modal.vue';

export default {
  components: { 
    Modal 
  },
  methods: {
    ...mapActions('globalModule', ['setStoreChangeWarningModal']),
    clickOutside (event) {
      if (event.target === this.$refs.overlay) {
        this.cancel();
      }
    },
    cancel() {
      this.setStoreChangeWarningModal(null);
    },
    confirm() {
      this.$root.selectStore();
      this.setStoreChangeWarningModal(null);
    }
  }
};
</script>
