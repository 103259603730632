var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "deleteFavorite",
      class: `deleteFavorite-${this.product.identifier}`,
    },
    [
      _vm.memberId
        ? _c("img", {
            attrs: { src: require("@/assets/svg/heart-solid.svg") },
            on: { click: _vm.deleteFavorite },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }