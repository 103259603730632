var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "backToCart" }, [
    _c("a", { attrs: { href: _vm.href } }, [
      _c(
        "svg",
        {
          attrs: {
            width: "10",
            height: "11",
            viewBox: "0 0 10 11",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
              fill: "#E31F28",
            },
          }),
        ]
      ),
      _vm._v(" " + _vm._s(_vm.$t("order.backToCart")) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }