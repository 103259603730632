<template>
  <div class="mainContent productTemplateVue">
    <slot :product-context="this" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stores from '@/models/store/Store';

export default {
  data() {
    return {
      stores: stores
    };
  },
  mounted() {
    const productElement = document.getElementById('product-data');

    if (!productElement) {
      throw new Error('Product data was not found on the page.');
    }
    this.$store.dispatch('productModule/setProduct', JSON.parse(productElement.innerHTML));
  },
  methods: {
    getObjectByProperty(product, listProperty) {
      return product && product[listProperty]
        ? product[listProperty].find(x => x.store === this.$root.getStore())
        : null;
    },
    sendViewItemGaEvent() {
      try {
        if (dataLayer) {
          dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          dataLayer.push({
            event: 'view_item',
            ecommerce: {
              items: [{
                item_id: this.product.sku,
                item_name: this.product.name,
                item_brand: this.product.brand,
                item_category: this.product.majorCategory,
                item_category2: this.product.intermediateCategory,
                item_category3: this.product.minorCategory,
                price: this.price.value,
                quantity: this.quantity
              }]
            }
          });
        }
      } catch { /*Fait rien*/ }
    }
  },
  computed: {
    ...mapGetters('productModule', ['product']),
    basePrice() {
      return this.price ? this.price.value : null;
    },
    showPrices() {
      return this.product && !this.product.isPriceInStoreOnly && this.basePrice !== null;
    },
    discountedPrice() {
      return this.price ? this.price.discounted : null;
    },
    hasDiscountedPrice() {
      return this.price !== null && this.price.discounted !== null;
    },
    isPriceInStoreOnly() {
      return this.product ? this.product.isPriceInStoreOnly : false;
    },
    price() {
      return this.product ? this.product.price : null;
    },
    quantity() {
      return this.getObjectByProperty(this.product, 'quantities');
    },
    selectedStoreName() {
      const storeCode = Object.keys(this.stores).find(key => 
        this.stores[key] === this.$root.getStore());

      return !storeCode ? '' : this.$t(`stores.${storeCode}`);
    }
  },
  watch: {
    product() {
      if (this.product) {
        this.sendViewItemGaEvent();
      }
    }
  }
};
</script>
