<template>
  <div class="selectedStore">
    <div id="branchesToggle" class="ctaBranches">
      <a v-show="!$root.hasStoreSelected" onclick="openSelector()">
        {{ noStoreSelectedText }}
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z" fill="#E02028" />
        </svg>
      </a>
      <div class="footerInfos">
        <div class="left">
          <div class="branchName">{{ storeInformation.branchName }}</div>
          <div class="branchAddress" v-html="storeInformation.address" />
          <div class="branchInfosGlobal">
            <div class="phone">
              {{storeInformation.phone}}
            </div>
            <div class="mail">
              <a :href="`mailto:${storeInformation.email}`"
                onclick="gtag('event', 'Courriel', {'event_category': 'Prise de contact','event_label': 'Drummondville'});">{{
                    storeInformation.email
                }}
              </a>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="labelHours">
            <div class="label">{{ $t('branchHours') }}</div>
            <div class="branchSchedule">
              <div v-html="storeInformation.businessHours"/>
            </div>
          </div>
        </div>
      </div>
      <div v-show="$root.hasStoreSelected" class="changeBranchLink">
        <a href="#app" class="btn txt" onclick="openSelector()">
          {{ $t('changeStore') }}
          <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.00718 8.5L8 0.500001L6.99382e-07 0.500001L4.00718 8.5Z" fill="#E02028" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import stores from '@/models/store/Store';

export default {
  props: {
    noStoreSelectedText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      storeInformation: {},
      stores: stores
    };
  },
  async mounted() {
    if (this.selectedStoreName) {
      const response = await this.$axios.get(`${location.origin}/Umbraco/Api/Store/GetStoreInformation?name=${this.selectedStoreName}`);
      this.storeInformation = response.data;
    }
  },
  computed: {
    selectedStoreName() {
      const storeCode = Object.keys(this.stores).find(key =>
        this.stores[key] === this.$root.getStore());
      return !storeCode ? '' : this.$t(`stores.${storeCode}`);
    }
  }
};
</script>

