<!-- eslint-disable -->
<template>
  <div class="dropdownCount">
    <label class="facetTitle">{{ header }}</label>
    <div class="divSelect dropdownStyle">
      <select v-model="selectedFacet">
        <option v-for="value in values"
                :key="value"
                :value="value">
          {{ value }}
        </option>
      </select>
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>
import { tryParseInt } from '@/functions';
import hashMixins from '@/hashMixins';

export default {
  props: {
    itemsContainer: {
      type: Object,
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedFacet: null
    };
  },
  created() {
    const hashParams = this.getHashParams();
    const count = hashParams['count'] || null;
    const defaultValue = this.values[0];
    this.selectedFacet = count === null
      ? defaultValue
      : this.values.find(x => x === tryParseInt(count, null))
        || defaultValue;
  },
  methods: {
    selectFacet(facet) {
      this.selectedFacet = facet;
    },
    refreshSelectedFacets() {
      this.itemsContainer.selectedFacets = {
        ...this.itemsContainer.selectedFacets,
        count: this.selectedFacet
      };
    }
  },
  watch: {
    selectedFacet() {
      this.refreshSelectedFacets();
      this.$root.$emit('scroll');
    }
  },
  mixins: [hashMixins]
};
</script>
