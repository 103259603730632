import Vue from 'vue';

export async function findProducts({ commit }, params) {
  const response = await Vue.axios.post('/Umbraco/Api/products/search', params);

  return commit('setProducts', { products: response.data, store: params.store });
}

export async function setProducts({ commit }, { products, selectedStore }) {
  return commit('setProducts', { products, selectedStore });
}

export async function resetProducts({ commit }) {
  return commit('resetProducts');
}
