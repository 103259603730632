<template>
  <validation-observer class="accountForm resetPasswordForm" ref="resetPasswordForm" tag="form" @submit.prevent="submitResetPassword" novalidate>
    <template v-if="!resetPasswordHasSucceded">
      <h3>{{ $t('users.titles.passwordEdition') }}</h3>
      <validation-provider class="formItem" tag="div" name="newPassword" rules="required|password" v-slot="{ errors }">
        <label>{{ $t('users.fields.newPassword') }}*</label>
        <password-container v-model="newPassword" :errors="errors"></password-container>
        <div class="formItem-errorMessage error" v-if="errors.length">{{ errors[0] }}</div>
        <ul class="criterias">
          <li>{{ $t('users.messages.passwordCriterias.characters')}}</li>
          <li>{{ $t('users.messages.passwordCriterias.length')}}</li>
          <li>{{ $t('users.messages.passwordCriterias.specialCharacters')}}</li>
          <li>{{ $t('users.messages.passwordCriterias.numbers')}}</li>
        </ul>
      </validation-provider>
      <validation-provider class="formItem" tag="div" name="newPasswordConfirmation" rules="required|passwordMatch:@newPassword" v-slot="{ errors }">
        <label>{{ $t('users.fields.passwordConfirmation') }}*</label>
        <password-container v-model="newPasswordConfirmation" :errors="errors"></password-container>
        <div class="formItem-errorMessage error" v-if="errors.length">{{ errors[0] }}</div>
      </validation-provider>
      <div class="formItem">
        <working-button class="btn" :isWorking="passwordIsResetting" :label="resetPasswordLabel" type="submit" />
        <div class="formItem-errorMessage error" v-if="resetPasswordError" v-html="resetPasswordError"></div>
      </div>
    </template>

    <template v-else>
      <div class="resetPasswordForm-successPanel">
        <circle-check class="resetPasswordForm-successIcon"></circle-check>
        <div class="resetPasswordForm-successMessage">{{ $t('users.messages.resetPasswordSuccess') }}</div>
        <a class="btn resetPasswordForm-successConnect" href="/connexion">{{ $t('users.actions.connectMe') }}</a>
      </div>
    </template>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'
import WorkingButton from './WorkingButton.vue'
import PasswordContainer from './PasswordContainer.vue'
import CircleCheck from '../Svg/CircleCheck.vue'

export default {
  components: {
    CircleCheck,
    PasswordContainer,
    ValidationObserver,
    ValidationProvider,
    WorkingButton
  },
  props: {
    validatedActivationCode: {
      type: String,
      required: true
    },
    validatedEmail: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      passwordIsResetting: false,
      newPassword: undefined,
      newPasswordConfirmation: undefined,
      resetPasswordError: undefined,
      resetPasswordHasSucceded: false
    }
  },
  methods: {
    handleResetSuccess() {
      this.resetPasswordHasSucceded = true
    },
    async submitResetPassword() {
      if(!await this.$refs.resetPasswordForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.resetPasswordForm.$el, '.invalid')
        return
      }

      try {
        if (this.isPasswordConfirmationInvalid) {
          return
        }

        this.passwordIsResetting = true
        const response = await this.$axios.post('/Umbraco/Api/Authentication/ResetPassword', {
          activationCode: this.validatedActivationCode,
          email: this.validatedEmail,
          password: this.newPassword 
        })
        this.handleResetSuccess()
      } catch (error) {
        this.resetPasswordError = this.$t('user.messages.ResetPasswordException')
      } finally {
        this.passwordIsResetting = false
      }
    }
  },
  computed: {
    resetPasswordLabel() {
      return this.$t(`users.actions.${this.passwordIsResetting ? 'resettingPassword' : 'resetPassword'}`)
    }
  }
}
</script>