<template>
  <div class="orderProducts box">
    <div class="toggle">
      <div class="title flex center">
        <div ref="toggleTitle" @click="toggle">
          <div class="orderTitle">{{ title }}</div>
        </div>
        <button @click="toggle">
          <span v-show="hidden" class="down"></span>
          <span v-show="!hidden" class="up"></span>
        </button>
      </div>
      <div class="orderList toggleContent text" v-show="!hidden">
        <div class="wrapProduct" v-for="product in products" v-bind:key="product.identifier">
          <product-info 
            :product="product"
            :isAvailable="!isUnavailable(product)"
            :isInactive="isInactive(product)"
            :isInStock="isInStock(product)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartProducts from '@/components/Cart/CartProducts.vue';
import ProductInfo from '@/components/Product/ProductInfo.vue';
import productMixins from '@/productMixins';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    CartProducts,
    ProductInfo
  },
  data() {
    return {
      hidden: true
    };
  },
  computed: {
    ...mapGetters('cartModule', ['cart']),
    ...mapState('cartModule', ['products']),
    title() {
      if (this.hidden) {
        return this.$t('order.products.showItems');
      }

      return this.$t('order.products.hideItems');
    }
  },
  methods: {
    getProductQuantity(product) {
      return this.isUnavailable(product) ? 0 : this.cart[product.identifier];
    },
    toggle() {
      this.hidden = !this.hidden;
    }
  },
  mixins: [productMixins]
};
</script>
