var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "account-shippingInfo infoPanel" },
    [
      _c("div", { staticClass: "infoPanel-title" }, [
        _c("h3", { staticClass: "infoPanel-heading" }, [
          _vm._v(_vm._s(_vm.$t("users.titles.shippingInfo"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "infoPanel-data" },
        [
          _vm.shippingAddress
            ? _c("shipping-address-summary", {
                attrs: { address: _vm.shippingAddress },
              })
            : [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("users.accountText.noShippingAddress"))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "infoPanel-action btnText",
                    attrs: { type: "button" },
                    on: { click: _vm.handleShowEditShippingInfoDialog },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.addShippingAddress")))]
                ),
              ],
        ],
        2
      ),
      _c("div", { staticClass: "infoPanel-actions" }, [
        _vm.shippingAddress
          ? _c(
              "button",
              {
                staticClass: "infoPanel-action btnText",
                attrs: { type: "button" },
                on: { click: _vm.handleShowEditShippingInfoDialog },
              },
              [_vm._v(_vm._s(_vm.$t("users.actions.edit")))]
            )
          : _vm._e(),
      ]),
      _c(
        "teleport",
        { attrs: { to: "body" } },
        [
          _c(
            "dialog-modal",
            {
              attrs: {
                isOpen: _vm.editShippingInfoDialogIsOpen,
                title: _vm.$t("users.titles.shippingInfoEdition"),
              },
              on: { "close:dialog": _vm.handleCloseEditShippingInfoDialog },
            },
            [
              _c("edit-shipping-info", {
                ref: "editShippingInfoForm",
                attrs: { user: _vm.user, addressInfo: _vm.shippingAddress },
                on: {
                  "success:updateShippingInfo":
                    _vm.handleUpdateShippingInfoSuccess,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }