export function formatPhoneNumber(unformattedPhoneNumber) {
  const regex = /^(\d)?(\d{3})(\d{3})(\d{4})$/
  const cleanedNumber = unformattedPhoneNumber.replace(/\D/g, '')
  const matches = cleanedNumber.match(regex)

  if(matches.length < 4) {
    return cleanedNumber
  }
  return `${(matches[1] ? `${matches[1]} ` : '')}${matches[2]} ${matches[3]}-${matches[4]}`
}

export function getPhoneUrlFromFormattedPhoneString(phoneString) {
  if(phoneString === null || phoneString === undefined || phoneString === '') {
    return undefined
  }
  const cleanedNumber = phoneString.replace(/\D/g, '')
  return `tel:+${cleanedNumber.startsWith(1) ? '' : '1'}${cleanedNumber}`
}

export function getFormattedCanadianDollars(number, locale = 'fr-ca') {
  if(!number) {
    return undefined
  }
  
  const currencyFormatOptions = {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol'
  }

  return new Intl.NumberFormat(locale, currencyFormatOptions)
    .format(number)
}