var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "account-billingInfo infoPanel" },
    [
      _c("div", { staticClass: "infoPanel-title" }, [
        _c("h3", { staticClass: "infoPanel-heading" }, [
          _vm._v(_vm._s(_vm.$t("users.titles.billingInfo"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "infoPanel-data" },
        [
          _vm.billingAddress
            ? _c("shipping-address-summary", {
                attrs: { address: _vm.billingAddress },
              })
            : [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("users.accountText.noBillingAddress"))),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "infoPanel-action btnText",
                    attrs: { type: "button" },
                    on: { click: _vm.handleShowEditBillingInfoDialog },
                  },
                  [_vm._v(_vm._s(_vm.$t("users.actions.addBillingAddress")))]
                ),
              ],
        ],
        2
      ),
      _c("div", { staticClass: "infoPanel-actions" }, [
        _vm.billingAddress
          ? _c(
              "button",
              {
                staticClass: "infoPanel-action btnText",
                attrs: { type: "button" },
                on: { click: _vm.handleShowEditBillingInfoDialog },
              },
              [_vm._v(_vm._s(_vm.$t("users.actions.edit")))]
            )
          : _vm._e(),
      ]),
      _c(
        "teleport",
        { attrs: { to: "body" } },
        [
          _c(
            "dialog-modal",
            {
              attrs: {
                isOpen: _vm.editBillingInfoDialogIsOpen,
                title: _vm.$t("users.titles.billingInfoEdition"),
              },
              on: { "close:dialog": _vm.handleCloseEditBillingInfoDialog },
            },
            [
              _c("edit-billing-info", {
                ref: "editBillingInfoForm",
                attrs: { user: _vm.user, addressInfo: _vm.billingAddress },
                on: {
                  "success:updateBillingInfo":
                    _vm.handleUpdateBillingInfoSuccess,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }