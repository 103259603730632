/* eslint-disable linebreak-style */

// Alias de templates Umbraco qu'on veut reconnaître pour les convertir en étiquettes
// Pas toutes les pages sont utiles, donc on met juste ceux qu'on veut
const wellKnownTemplateAliases = ['products', 'productscategory', 'promotions', 'product', 'searchresults']

// Limite de 16 étiquettes qu'on peut envoyer. Max 64 caractères par étiquette. À bien y penser si on en ajoute
export function getClickTags() {
  const tags = ['origin-web']
  
  const platform = getOperatingSystem();
  if (platform) {
    tags.push(`platform-${platform.toLowerCase()}`)
  }

  const deviceType = getDeviceType();
  if (deviceType) {
    tags.push(`device-${deviceType.toLowerCase()}`)
  }

  const pageTemplate = window.laferte.metadata.pageTemplate ? window.laferte.metadata.pageTemplate.toLowerCase() : null;
  if (pageTemplate && wellKnownTemplateAliases.includes(pageTemplate.toLowerCase())) {
    tags.push(`page-origin-${pageTemplate}`)
  }

  return tags
}

function getDeviceType() {
  const userAgent = navigator.userAgent;

  // Regular expressions to match common device types
  const mobileRegExp = /Mobile|Android|iPhone|iP[oa]d|Windows Phone|Lumia|BlackBerry|BB10|PlayBook|Opera Mini|\bCrMo\/|Opera Mobi/i;
  const tabletRegExp = /Tablet|iPad|\bSilk\/|Kindle|Xoom|GT-P/i;

  // Check if the user agent matches any of the device type patterns
  if (tabletRegExp.test(userAgent)) {
      return 'Tablet';
  } else if (mobileRegExp.test(userAgent)) {
      return 'Mobile';
  } else {
      return 'Desktop';
  }
}

function getOperatingSystem() {
  const userAgent = navigator.userAgent || '';

  // Define keywords to look for in the user agent string
  const platformKeywords = [
      'Windows NT 10.0',
      'Windows NT 6.3',
      'Windows NT 6.2',
      'Windows NT 6.1',
      'Windows NT 6.0',
      'Windows NT 5.1',
      'Windows NT 5.0',
      'iPhone',
      'iPad',
      'Macintosh',
      'Android',
      'Linux'
  ];

  // Check for each platform keyword in the user agent string
  for (let keyword of platformKeywords) {
    if (userAgent.indexOf(keyword) !== -1) {
      return keyword.split(' ')[0]; // Extract the platform name (Windows, iPhone, iPad, Macintosh, Android, Linux)
    }
  }

  return null;
}